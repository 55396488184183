export default class WebSocketClient {
    constructor(url, retryInterval = 5000, sendHeartbeat = false) {
        this.websocket = null;
        this.isConnected = false;
        this.isReconnecting = false;
        this.sendHeartbeat = false;
        this.messageQueue = [];
        this.messageHandlers = [];
        this.heartbeatInterval = null;
        this.heartbeatContext = {};
        this.reconnectTimeout = null;
        this.url = url;
        this.retryInterval = retryInterval;
        this.sendHeartbeat = sendHeartbeat;
    }
    static getInstance(url, retryInterval = 5000, sendHeartbeat = false) {
        if (!WebSocketClient.instances.has(url)) {
            const instance = new WebSocketClient(url, retryInterval, sendHeartbeat);
            WebSocketClient.instances.set(url, instance);
        }
        return WebSocketClient.instances.get(url);
    }
    connect() {
        if (this.websocket && (this.websocket.readyState === WebSocket.OPEN || this.websocket.readyState === WebSocket.CONNECTING)) {
            console.warn("WebSocket connection is already in progress or established.");
            return;
        }
        console.log("Attempting to connect to WebSocket...");
        this.websocket = new WebSocket(this.url);
        this.websocket.onopen = () => {
            console.log("WebSocket connected.");
            this.isConnected = true;
            this.isReconnecting = false;
            // Flush the message queue
            while (this.messageQueue.length > 0) {
                const message = this.messageQueue.shift();
                this.sendMessage(message);
            }
        };
        this.websocket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            this.messageHandlers.forEach((handler) => handler(message));
        };
        this.websocket.onerror = (error) => {
            console.error("WebSocket error:", error);
        };
        this.websocket.onclose = () => {
            console.warn("WebSocket closed. Attempting to reconnect...");
            this.isConnected = false;
            this.websocket = null; // Cleanup WebSocket instance
            this.scheduleReconnect();
        };
    }
    scheduleReconnect() {
        if (this.isReconnecting || this.isConnected)
            return;
        console.log(`Scheduling reconnect in ${this.retryInterval}ms...`);
        this.isReconnecting = true;
        this.reconnectTimeout = setTimeout(() => {
            console.log("Reconnecting to WebSocket...");
            this.connect();
        }, this.retryInterval);
    }
    sendMessage(message) {
        if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
            this.websocket.send(JSON.stringify(message));
        }
        else {
            console.warn("WebSocket not ready. Queuing message.");
            this.messageQueue.push(message);
        }
    }
    startHeartbeat(interval = 30000) {
        if (this.heartbeatInterval) {
            clearInterval(this.heartbeatInterval);
        }
        if (this.sendHeartbeat) {
            this.heartbeatInterval = setInterval(() => {
                var _a;
                if (this.isConnected && ((_a = this.websocket) === null || _a === void 0 ? void 0 : _a.readyState) === WebSocket.OPEN) {
                    this.heartbeatContext.timestamp = Date.now();
                    this.sendMessage(this.heartbeatContext);
                }
            }, interval);
        }
    }
    setHeartbeatContext(context) {
        this.heartbeatContext = context;
    }
    stopHeartbeat() {
        if (this.heartbeatInterval) {
            clearInterval(this.heartbeatInterval);
            this.heartbeatInterval = null;
        }
    }
    addMessageHandler(handler) {
        this.messageHandlers.push(handler);
    }
    removeMessageHandler(handler) {
        this.messageHandlers = this.messageHandlers.filter((h) => h !== handler);
    }
    disconnect() {
        if (!this.websocket || this.websocket.readyState === WebSocket.CLOSING || this.websocket.readyState === WebSocket.CLOSED) {
            console.warn("WebSocket is already closing or closed.");
            return;
        }
        console.log("Disconnecting WebSocket...");
        this.stopHeartbeat();
        if (this.reconnectTimeout) {
            clearTimeout(this.reconnectTimeout);
            this.reconnectTimeout = null;
        }
        this.websocket.close();
        this.websocket = null;
        this.isConnected = false;
        this.isReconnecting = false;
        WebSocketClient.instances.delete(this.url);
    }
    isWebSocketConnected() {
        return this.isConnected;
    }
}
WebSocketClient.instances = new Map(); // Static map to store instances
