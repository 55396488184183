import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
export function Banner({ title }) {
    const BannerText = styled.span `
    font-family: 'CiscoSans Thin';
    font-size: 2vw;
    line-height: 2vw;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: 3.2vw;
    align-self: center;
    align-items: center;
  `;
    return _jsx(BannerText, { children: title });
}
