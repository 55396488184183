import styled from 'styled-components';
const FloorMapButton = styled.div `
  position: absolute;
  bottom: 41px;
  z-index: 1;
  display: flex;
  gap: 20px;
  align-items: end;
  transform: translate(-50%);
  left: 50%;
  z-index: 0;
`;
export default FloorMapButton;
