import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import ArrowLeft from './arrow-left';
import { Flex } from '@magnetic/flex';
import { getBgColorByStatus, getBorderColorByStatus, STATUS_COLOR_MAP, POIS_MAP, POI_TYPES, POI_TYPES_ORIGINAL, } from '../../util/index';
import { SwitchFloorPopup } from '../switch-floor-popup';
import { useTranslation } from 'react-i18next';
import { store } from '../../../../../store/store';
import { setRoomDetailsPopUp, getAllRooms, getPOIS, } from '../../../../../store/uislice';
import { useSelector } from 'react-redux';
import { closeRoomDetailsPopup } from '../room-details-popup';
import { fillAnalyticalInfoToRooms } from '../room-finder';
import { getFloorAnalytics, getLocation } from '../../../redux/spaces-slice';
import { useLocation, useNavigate } from 'react-router-dom';
const Modal = styled.div `
  background-color: #000;
  height: 100vh;
  opacity: 1;
  position: fixed;
  z-index: 9999999999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.7);
  display: flex !important;
`;
const ModalContent = styled.div ``;
const CloseButton = styled(Flex) `
  background: #b92948;
  border-radius: 2vw;
  padding: 0.4vw 1vw;
  margin: 3.2vw;
  width: fit-content;
  cursor: pointer;
  height: fit-content;
`;
const Text = styled(Flex) `
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.color};
  background: ${(props) => props.background};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
`;
const SearchBarContainer = styled(Flex) `
  width: 99.4vw;
`;
const SearchBar = styled(Flex) `
  background: #1f2226;
  border: 2px solid #00bceb;
  height: 3.2vw;
  width: 64vw;
  border-radius: 2vw;
  padding: 2vw;
  margin-top: 2vw;
`;
const GoButton = styled(Flex) `
  background: linear-gradient(180deg, #1d7ff3 0%, #113d7d 100%);
  height: 2.4vw;
  font-size: 1.2vw;
  padding-top: 1vw;
  width: 5.2vw;
  border-radius: 0.4vw;
  cursor: pointer;
`;
const Search = styled.input `
  background: #1f2226;
  border: none;
  height: 4vw;
  color: white;
  font-size: 1.2vw;
  -webkit-appearance: none;
  &:focus {
    outline: none;
  }
`;
const Line = styled(Flex) `
  background: ${(props) => props.background};
  width: 58.6vw;
  height: 2px;
  margin-top: 0.4vw;
`;
const DropDownContainer = styled.div `
  width: 64vw;
  border: 1px solid #343434;
  background: #1f2226;
  margin: 0 auto;
  max-height: 60vh;
  overflow-y: scroll;
`;
const RoomItem = styled(Flex) `
  padding: 0.6vw 2vw;
`;
const AvailableCircle = styled(Flex) `
  width: 0.52vw;
  height: 0.52vw;
  margin-right: 0.375vw;
  background: ${(props) => getBgColorByStatus(props.availability)};
  border: 0.1vw solid ${(props) => getBorderColorByStatus(props.availability)};
  border-radius: 0.6875vw;
`;
export const RoomSearchPopup = ({ onClose, floorId, }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { id: floorLocationId } = location.state || floorId;
    let globalRoomsData = [...useSelector(getAllRooms)];
    const POIS = useSelector(getPOIS).POIS;
    const [rooms, setRooms] = useState(globalRoomsData);
    const [selectedName, setSelectedName] = useState('');
    const [selectedFloorNumber, setSelectedFloorNumber] = useState(null);
    const [selectedRichMapId, setSelectedRichMapId] = useState(null);
    const [selectedAvailability, setSelectedAvailability] = useState(null);
    const [searchInput, setSearchInput] = useState('');
    const [showSwitchFloorPopup, setShowSwitchFloorPopup] = useState(false);
    const [isPOISelected, setIsSelectedPOI] = useState(false);
    const floorAnalytics = useSelector(getFloorAnalytics);
    const locationData = useSelector(getLocation);
    // Provide availability, capacity info to the rooms for which the info is available
    globalRoomsData = fillAnalyticalInfoToRooms(globalRoomsData, floorAnalytics, locationData);
    const floorLocationData = locationData === null || locationData === void 0 ? void 0 : locationData.children.find((child) => child.id === floorLocationId);
    const currentFloor = floorLocationData === null || floorLocationData === void 0 ? void 0 : floorLocationData.floorNumber;
    const roomItemClickHandler = (room) => {
        if (room.isPOI) {
            setIsSelectedPOI(true);
        }
        setSelectedName(room.name);
        setSelectedFloorNumber(room.floorNumber);
        setSelectedRichMapId(room.key);
        setSelectedAvailability(room.availability);
        setSearchInput(room.name);
    };
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g;
        const filteredRooms = []; // This will including rooms (webex and non-webex) as well as POIs
        const flagMap = {}; // a mapping for POI_Type & Floor_number --> Flag value, for iterating through all combinations of POI_Type & Floor_number
        for (const room of globalRoomsData) {
            if (searchInput &&
                room &&
                (room === null || room === void 0 ? void 0 : room.name) &&
                room.name.toLowerCase().includes(searchInput.toLowerCase())) {
                filteredRooms.push(Object.assign(Object.assign({}, room), { isPOI: false }));
            }
        }
        for (const type of POI_TYPES) {
            for (const POI of POIS[type]) {
                const flagKey = `${type} ${Number((_a = POI === null || POI === void 0 ? void 0 : POI.properties) === null || _a === void 0 ? void 0 : _a.lvl)}`;
                if (searchInput &&
                    type &&
                    type.toLowerCase().includes(searchInput.toLowerCase()) &&
                    !flagMap[flagKey]) {
                    filteredRooms.push({
                        name: type,
                        floorNumber: Number((_b = POI === null || POI === void 0 ? void 0 : POI.properties) === null || _b === void 0 ? void 0 : _b.lvl),
                        availability: '',
                        key: '',
                        capacity: 0,
                        isPOI: true,
                    });
                    flagMap[flagKey] = true;
                }
                if (searchInput &&
                    POI &&
                    (POI === null || POI === void 0 ? void 0 : POI.properties) &&
                    ((_c = POI === null || POI === void 0 ? void 0 : POI.properties) === null || _c === void 0 ? void 0 : _c.name) &&
                    ((_d = POI === null || POI === void 0 ? void 0 : POI.properties) === null || _d === void 0 ? void 0 : _d.name.toLowerCase().includes(searchInput.toLowerCase())) &&
                    ((_e = POI === null || POI === void 0 ? void 0 : POI.properties) === null || _e === void 0 ? void 0 : _e.lvl)) {
                    filteredRooms.push({
                        name: (_f = POI === null || POI === void 0 ? void 0 : POI.properties) === null || _f === void 0 ? void 0 : _f.name,
                        floorNumber: Number((_g = POI === null || POI === void 0 ? void 0 : POI.properties) === null || _g === void 0 ? void 0 : _g.lvl),
                        availability: '',
                        key: POI === null || POI === void 0 ? void 0 : POI.id,
                        capacity: 0,
                        isPOI: true,
                    });
                }
            }
        }
        setRooms(() => filteredRooms);
    }, [searchInput]);
    const goClickHandler = (onClose) => {
        var _a, _b, _c, _d;
        let exactMatch = false;
        const setupSwitchFloorPopup = (name, floorNumber, richMapId) => {
            setShowSwitchFloorPopup(true);
            setSelectedName(name);
            setSelectedFloorNumber(floorNumber);
            setSelectedRichMapId(richMapId);
        };
        if (isPOISelected) {
            if (POI_TYPES.includes(searchInput)) {
                // If it's a group POI
                exactMatch = rooms.find((room) => room.name === searchInput &&
                    room.floorNumber === selectedFloorNumber);
                if (exactMatch) {
                    closeRoomDetailsPopup();
                    if (selectedFloorNumber === currentFloor) {
                        setTimeout(() => {
                            if (window.rMap) {
                                window.rMap.reset();
                                for (const type of POI_TYPES_ORIGINAL) {
                                    // Clear existing POI Group selections
                                    window.rMap.updatePoiSelection(type, true);
                                }
                                window.rMap.updatePoiSelection(POIS_MAP[selectedName], false);
                            }
                        }, 400);
                        onClose();
                    }
                    else {
                        // Different floor pop up
                        setupSwitchFloorPopup(exactMatch.name, exactMatch.floorNumber, exactMatch.key);
                    }
                }
                return;
            }
            // If it's an individual POI
            for (const type of POI_TYPES) {
                for (const POI of POIS[type]) {
                    if (searchInput &&
                        POI &&
                        (POI === null || POI === void 0 ? void 0 : POI.properties) &&
                        ((_a = POI === null || POI === void 0 ? void 0 : POI.properties) === null || _a === void 0 ? void 0 : _a.name) &&
                        ((_b = POI === null || POI === void 0 ? void 0 : POI.properties) === null || _b === void 0 ? void 0 : _b.name.toLowerCase().includes(searchInput.toLowerCase()))) {
                        exactMatch = POI;
                        break;
                    }
                }
            }
            if (exactMatch) {
                // In current floor
                if (selectedFloorNumber === currentFloor) {
                    store.dispatch(setRoomDetailsPopUp({ payload: exactMatch === null || exactMatch === void 0 ? void 0 : exactMatch.id }));
                    window.rMap.focusFeature(selectedRichMapId);
                    onClose();
                }
                else {
                    // Different floor pop up
                    setupSwitchFloorPopup((_c = exactMatch === null || exactMatch === void 0 ? void 0 : exactMatch.properties) === null || _c === void 0 ? void 0 : _c.name, (_d = exactMatch === null || exactMatch === void 0 ? void 0 : exactMatch.properties) === null || _d === void 0 ? void 0 : _d.lvl, exactMatch === null || exactMatch === void 0 ? void 0 : exactMatch.id);
                }
            }
            return;
        }
        // If it's a room (webex / non-webex)
        exactMatch = globalRoomsData.find((room) => room.name.toLowerCase() === searchInput.toLowerCase());
        if (exactMatch) {
            // In current floor
            if (selectedFloorNumber === currentFloor) {
                store.dispatch(setRoomDetailsPopUp({ payload: exactMatch.key }));
                window.rMap.focusFeature(selectedRichMapId);
                onClose();
            }
            // Not in current floor
            else {
                setupSwitchFloorPopup(exactMatch.name, exactMatch.floorNumber, exactMatch.key);
            }
        }
    };
    const closeSwitchFloorPopup = () => {
        var _a;
        setShowSwitchFloorPopup(false);
        setSelectedFloorNumber(null);
        setSelectedRichMapId(null);
        setIsSelectedPOI(false);
        closeRoomDetailsPopup();
        if (document.getElementById('quit-search')) {
            (_a = document.getElementById('quit-search')) === null || _a === void 0 ? void 0 : _a.click();
        }
        if (POI_TYPES.includes(searchInput)) {
            // If it's a group POI, no focusing on a single feature, no showing room details popup
            setTimeout(() => {
                if (window.rMap) {
                    window.rMap.reset();
                    for (const type of POI_TYPES_ORIGINAL) {
                        // Clear existing POI Group selections
                        window.rMap.updatePoiSelection(type, true);
                    }
                    window.rMap.updatePoiSelection(POIS_MAP[selectedName], false);
                }
            }, 400);
            return;
        }
        setTimeout(() => {
            window.rMap.focusFeature(selectedRichMapId);
            store.dispatch(setRoomDetailsPopUp({ payload: selectedRichMapId }));
        }, 1000);
    };
    return (_jsxs(Modal, { children: [showSwitchFloorPopup && (_jsx(SwitchFloorPopup, { name: selectedName, floorNumber: selectedFloorNumber, selectFloor: (selectedFloorId) => {
                    navigate('/floor', { state: { id: selectedFloorId } });
                    closeSwitchFloorPopup();
                }, onClose: closeSwitchFloorPopup, isPOI: isPOISelected })), _jsxs(ModalContent, { children: [_jsxs(CloseButton, Object.assign({ id: "quit-search", onClick: onClose, justify: "space-between" }, { children: [_jsx(ArrowLeft, {}), _jsxs(Text, Object.assign({ fontSize: "1vw", padding: window.innerWidth < 2500 && '0.4vw 0vw 0vw 0vw' }, { children: ["\u00A0", t('floor.room-search-popup.quit-search')] }))] })), _jsx(SearchBarContainer, Object.assign({ justify: "center" }, { children: _jsxs(SearchBar, Object.assign({ justify: "space-between" }, { children: [_jsxs(Flex, Object.assign({ direction: "vertical" }, { children: [_jsx(Search, { value: searchInput, onChange: (e) => setSearchInput(e.target.value), placeholder: "Enter a meeting room name", autoFocus: true }), _jsx(Line, { background: "#00bceb" })] })), _jsx(GoButton, Object.assign({ onClick: () => goClickHandler(onClose), justify: "center" }, { children: t('floor.room-search-popup.go') }))] })) })), _jsx(DropDownContainer, { children: rooms.map((room, i) => {
                            return (_jsxs(RoomItem, Object.assign({ direction: "vertical", onClick: () => roomItemClickHandler(room) }, { children: [_jsxs(Flex, Object.assign({ justify: "space-between" }, { children: [_jsx(Text, Object.assign({ fontSize: "1.2vw", style: { width: '24vw' } }, { children: room.name })), _jsxs(Flex, { children: [!room.isPOI && (_jsx(AvailableCircle, { availability: room.availability })), _jsx(Text, Object.assign({ fontSize: "0.8vw", color: STATUS_COLOR_MAP[room.availability], margin: "-0.2vw 0vw 0vw 0vw" }, { children: room.availability }))] }), _jsxs(Text, Object.assign({ fontSize: "0.8vw" }, { children: [t('floor.room-search-popup.floor'), " ", room.floorNumber] }))] })), _jsx(Line, { background: "#343434", width: "100%" })] }), i));
                        }) })] })] }));
};
