var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import { connect, JSONCodec, jwtAuthenticator, Events, } from 'nats.ws';
import { createUser, fromSeed } from 'nkeys.js';
import { store } from './store';
import { updateMessage } from './natsslice';
import axios from 'axios';
import { CacheService } from '../common/util/cacheservice';
import { DataService } from '../app/configure/service/deviceservice';
const app_module = 'signage-module';
const LOCAL_STORAGE_PRIVATE_KEY = 'nats_private_key';
const LOCAL_STORAGE_PUBLIC_KEY = 'nats_public_key';
const LOCAL_STORAGE_IS_REGISTERED = 'nats_is_registered';
const cacheService = CacheService.getInstance(app_module);
// Singleton NATS Client
class NatsClient {
    constructor() {
        this.nc = null;
        this.nkeyPair = null;
        this.jwtToken = null;
        this.jetStreamClient = null;
        this.codec = JSONCodec();
        this.streamName = null;
        this.lastReceivedSequence = new Map();
        this.currentSubscriptions = new Map();
        this.globalSubscriptions = new Map();
    }
    // Get singleton instance
    static getInstance() {
        if (!NatsClient.instance) {
            NatsClient.instance = new NatsClient();
        }
        return NatsClient.instance;
    }
    loadKeyFromLocalStorage() {
        const seed = cacheService.get(LOCAL_STORAGE_PRIVATE_KEY);
        //const seed = localStorage.getItem(LOCAL_STORAGE_PRIVATE_KEY);
        if (seed) {
            this.nkeyPair = fromSeed(Uint8Array.from(seed.split(',').map(Number)));
            console.log('Private key loaded from local storage/Cache service ');
        }
        else {
            console.log('No private key found in local storage/Cache service');
        }
    }
    saveKeyToLocalStorage() {
        if (this.nkeyPair) {
            const seed = this.nkeyPair.getSeed();
            const publicKey = this.nkeyPair.getPublicKey();
            cacheService.set(LOCAL_STORAGE_PRIVATE_KEY, seed.toString());
            cacheService.set(LOCAL_STORAGE_PUBLIC_KEY, publicKey);
            // localStorage.setItem(LOCAL_STORAGE_PRIVATE_KEY, seed.toString());
            // localStorage.setItem(LOCAL_STORAGE_PUBLIC_KEY, publicKey);
            console.log('Key pair saved to local storage/cahce service');
        }
    }
    generateNKeyPairIfNotExists() {
        this.loadKeyFromLocalStorage();
        if (!this.nkeyPair) {
            this.nkeyPair = createUser();
            console.log('Generated new NKeyPair:', this.nkeyPair.getPublicKey());
            this.saveKeyToLocalStorage();
        }
        else {
            console.log('Using existing key pair from local storage');
        }
    }
    // Check if the public key is already registered
    isRegistered() {
        return cacheService.get(LOCAL_STORAGE_IS_REGISTERED) === 'true';
        // return localStorage.getItem(LOCAL_STORAGE_IS_REGISTERED) === 'true';
    }
    // Mark public key as registered
    setRegistered() {
        cacheService.set(LOCAL_STORAGE_IS_REGISTERED, 'true');
        //localStorage.setItem(LOCAL_STORAGE_IS_REGISTERED, 'true');
    }
    // Register the public key with the server (only once)
    registerPublicKey() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isRegistered()) {
                console.log('registerPublicKey:: Public key already registered');
                //return true;
            }
            if (!this.nkeyPair) {
                console.error('registerPublicKey:: NKeyPair not generated.');
                this.generateNKeyPairIfNotExists();
            }
            const publicKey = this.nkeyPair.getPublicKey();
            while (true) {
                try {
                    //const signageToken = '2282e93f-f782-4958-aa09-0c33e6831aa3';
                    const signageToken = new DataService().getDeviceToken();
                    const jwtResponse = yield axios.post('/v1/register', {
                        publicKey: publicKey,
                        token: signageToken,
                    });
                    this.jwtToken = jwtResponse.data.jwt;
                    console.log('Successfully registered public key with the server' +
                        JSON.stringify(this.jwtToken));
                    this.setRegistered();
                    return true; // Registration was successful
                }
                catch (error) {
                    console.error('Error registering public key, retrying in 10 seconds...', error);
                    yield new Promise((resolve) => setTimeout(resolve, 10000)); // Wait for 10 seconds before retrying
                }
            }
        });
    }
    // Register the public key with the server (only once)
    getPublicKey() {
        if (!this.nkeyPair) {
            console.error('registerPublicKey:: NKeyPair not generated.');
            this.generateNKeyPairIfNotExists();
        }
        return this.nkeyPair.getPublicKey();
    }
    requestJWT(authToken) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.nkeyPair) {
                console.error('NKeyPair not generated.');
                return;
            }
            const publicKey = this.nkeyPair.getPublicKey();
            try {
                const response = yield axios.post('/api/get-jwt', {
                    publicKey,
                    authToken,
                });
                this.jwtToken = response.data.jwt;
            }
            catch (error) {
                console.error('Error obtaining JWT:', error);
            }
        });
    }
    processMessage(subject, msg, source) {
        const logPrefix = 'NatsClient :: processMessage ::';
        try {
            const decodedMessage = this.codec.decode(msg.data);
            console.log(logPrefix, `source: ${source} :: Received message on "${subject}":`, decodedMessage);
            store.dispatch(updateMessage({ subject, payload: decodedMessage }));
        }
        catch (error) {
            console.error(logPrefix, `source: ${source} :: Error processing messages for subject "${subject}":`, error);
        }
    }
    updateStoreSubscribe(msg) {
        var _a, _b, _c;
        const currentNatsSequence = parseInt((_b = (_a = msg.headers) === null || _a === void 0 ? void 0 : _a.get('Nats-Sequence')) !== null && _b !== void 0 ? _b : '0');
        const subject = msg.subject.replace('republish.', '');
        const lastSequenceForSubject = (_c = this.lastReceivedSequence.get(subject)) !== null && _c !== void 0 ? _c : 0;
        if (currentNatsSequence != 0 &&
            lastSequenceForSubject < currentNatsSequence) {
            this.lastReceivedSequence.set(subject, currentNatsSequence);
            // console.log(logPrefix, "Received new update, updated stores :: subject", subject, "msg:", this.codec.decode(msg.data), "lastSequenceForSubject Map", this.lastReceivedSequence)
            this.processMessage(subject, msg, 'subscribe');
        }
    }
    updateStoreDirectGet(msg) {
        var _a, _b, _c, _d, _e;
        const currentNatsSequence = parseInt((_b = (_a = msg.headers) === null || _a === void 0 ? void 0 : _a.get('Nats-Sequence')) !== null && _b !== void 0 ? _b : '0');
        const subject = (_d = (_c = msg.headers) === null || _c === void 0 ? void 0 : _c.get('Nats-Subject')) !== null && _d !== void 0 ? _d : '';
        const lastSequenceForSubject = (_e = this.lastReceivedSequence.get(subject)) !== null && _e !== void 0 ? _e : 0;
        console.log('updateStoreDirectGet:: currentNatsSequence :: lastSequenceForSubject :: subject', currentNatsSequence, lastSequenceForSubject, subject);
        if (currentNatsSequence != 0 &&
            lastSequenceForSubject < currentNatsSequence) {
            this.lastReceivedSequence.set(subject, currentNatsSequence);
            // console.log(logPrefix, "Received new update, updated stores :: subject", subject, "msg:", this.codec.decode(msg.data), "lastSequenceForSubject Map", this.lastReceivedSequence)
            this.processMessage(subject, msg, 'direct-get');
        }
    }
    getSubjectsWithFilter(nc, streamName, filterSubject) {
        return __awaiter(this, void 0, void 0, function* () {
            const jsm = yield nc.jetstreamManager();
            const streamInfo = yield jsm.streams.info(streamName, {
                subjects_filter: filterSubject,
            });
            const subjects = streamInfo.state.subjects;
            // const subjectsToPrint = [""]
            // for (let subject in subjects)
            //   subjectsToPrint.push(subject)
            // console.log(`NatsClient :: getSubjectsWithFilter :: ${subjectsToPrint}`)
            return subjects;
        });
    }
    getLatestMessageForSubject(nc, streamName, subject) {
        return __awaiter(this, void 0, void 0, function* () {
            const logPrefix = 'NatsClient :: getLatestMessageForSubject ::';
            try {
                const DIRECT_GET_SUBJECT_PREFIX = '$JS.API.DIRECT.GET.' + streamName + '.';
                const requestPayload = '';
                const directGetSubject = DIRECT_GET_SUBJECT_PREFIX + subject;
                const reply = yield nc.request(directGetSubject, requestPayload);
                this.updateStoreDirectGet(reply);
                // console.log(logPrefix, "recieved reply for direct get :: subject", subject);
            }
            catch (err) {
                console.error(logPrefix, 'error in getting latest message using direct get for subject', subject, err);
                return null;
            }
        });
    }
    getLatestMessagesForSubjectFilter(nc, streamName, filterSubject) {
        return __awaiter(this, void 0, void 0, function* () {
            const logPrefix = 'NatsClient :: getLatestMessagesForSubjectFilter ::';
            try {
                if (filterSubject === null || nc === null || streamName === null)
                    return;
                const subjectStartTime = Date.now();
                const subjects = yield this.getSubjectsWithFilter(nc, streamName, filterSubject);
                let count = 0;
                const latestMessagePromises = new Array();
                for (const subject in subjects) {
                    const latestMessagePromise = this.getLatestMessageForSubject(nc, streamName, subject);
                    latestMessagePromises.push(latestMessagePromise);
                    count = count + 1;
                }
                yield Promise.all(latestMessagePromises);
                const latestMessageEndTime = Date.now();
                console.log(logPrefix, 'total time taken to get latest message for filterSubject', filterSubject, 'with', count, 'subjects:', latestMessageEndTime - subjectStartTime, 'ms');
            }
            catch (err) {
                // console.error("NatsClient :: getLatestMessagesForSubjectFilter :: Error in getting the latest message for subject filter", filterSubject, "error:", err)
            }
        });
    }
    handleConnectionStatus(nc, streamName) {
        var _a, e_1, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            const logPrefix = 'NatsClient :: handleConnectionStatus ::';
            console.log(logPrefix, 'starting handleConnectionStatus handler');
            try {
                for (var _d = true, _e = __asyncValues(nc.status()), _f; _f = yield _e.next(), _a = _f.done, !_a;) {
                    _c = _f.value;
                    _d = false;
                    try {
                        const status = _c;
                        if (status.type === Events.Reconnect ||
                            status.type === Events.Disconnect) {
                            console.log(logPrefix, `Client ${status.type} - ${status.data}` +
                                `\ncurrent subscriptions: ${Array.from(this.currentSubscriptions.keys()).join(',')} ` +
                                `\nglobal subscriptions: ${Array.from(this.globalSubscriptions.keys()).join(',')} `);
                            // console.log(`lastReceivedSequence: ${[...this.lastReceivedSequence.entries()]}`);
                            this.globalSubscriptions.forEach((sub, filterSubject) => {
                                this.getLatestMessagesForSubjectFilter(nc, streamName, filterSubject);
                            });
                            this.currentSubscriptions.forEach((sub, filterSubject) => {
                                this.getLatestMessagesForSubjectFilter(nc, streamName, filterSubject);
                            });
                        }
                    }
                    finally {
                        _d = true;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (!_d && !_a && (_b = _e.return)) yield _b.call(_e);
                }
                finally { if (e_1) throw e_1.error; }
            }
        });
    }
    startSubscriber(nc, filterSubject) {
        const logPrefix = 'NatsClient :: startSubscriber ::';
        if (nc === null) {
            console.log(logPrefix, 'nats connection is null, not starting subscriber for filterSubject', filterSubject);
            return null;
        }
        console.log(logPrefix, 'starting subscriber for filter subject', filterSubject);
        const republishPrefix = 'republish.';
        const subject = republishPrefix + filterSubject;
        let count = 0;
        const sub = nc.subscribe(subject, {
            callback: (err, msg) => {
                if (err) {
                    console.error(err.message);
                    return null;
                }
                // console.log("NatsClient :: startSubscriber :: received message on subscribe :: count:", count, "msg:", this.codec.decode(msg.data), "subject:", msg.subject,":: err:", err)
                this.updateStoreSubscribe(msg);
                count = count + 1;
            },
        });
        console.log(logPrefix, 'started subscriber on', subject);
        return sub;
    }
    clearSubsriptions(subscriptions, type) {
        const logPrefix = 'NatsClient :: clearSubsriptions :: ' + type + ' ::';
        subscriptions.forEach((sub, subject) => {
            try {
                sub === null || sub === void 0 ? void 0 : sub.unsubscribe();
                console.log(logPrefix, 'unsubscribed subscription for subject', subject);
            }
            catch (err) {
                console.error(logPrefix, 'error in unsubscribing subscription for subject', subject, err);
            }
        });
        subscriptions.clear();
        console.log(logPrefix, 'cleared subscriptions');
    }
    clearAllGlobalSubscriptions() {
        this.clearSubsriptions(this.globalSubscriptions, 'global');
    }
    subscribe(subjects) {
        return __awaiter(this, void 0, void 0, function* () {
            const logPrefix = 'NatsClient :: subscribe ::';
            console.log(logPrefix, 'adding subscription for subjects', subjects);
            try {
                this.clearSubsriptions(this.currentSubscriptions, 'current');
                for (const subject of subjects) {
                    const sub = this.startSubscriber(this.nc, subject);
                    if (!sub) {
                        console.error(logPrefix, 'error while starting subscription for subject', subject);
                        throw Error(`error while starting subscription for subject ${subject}`);
                    }
                    console.log(logPrefix, 'started subscription, added subscription to current subscriptions for subject', subject, ':: current subscriptions:', Array.from(this.currentSubscriptions.keys()).join(','));
                    this.currentSubscriptions.set(subject, sub);
                    yield this.getLatestMessagesForSubjectFilter(this.nc, this.streamName, subject);
                }
            }
            catch (error) {
                console.error(logPrefix, `error subscribing to subjects "${subjects}":`, error);
            }
        });
    }
    addGlobalSubscriptions(subjects) {
        return __awaiter(this, void 0, void 0, function* () {
            const logPrefix = 'NatsClient :: subscribe :: global';
            console.log(logPrefix, 'adding subscription for subjects', subjects);
            try {
                for (const subject of subjects) {
                    const sub = this.startSubscriber(this.nc, subject);
                    if (!sub) {
                        console.error(logPrefix, 'error while starting subscription for subject', subject);
                        throw Error(`error while starting subscription for subject ${subject}`);
                    }
                    this.globalSubscriptions.set(subject, sub);
                    yield this.getLatestMessagesForSubjectFilter(this.nc, this.streamName, subject);
                    console.log(logPrefix, 'started subscription, adding subscription to global subscriptions for subject', subject, ':: global subscriptions:', Array.from(this.globalSubscriptions.keys()).join(','));
                }
            }
            catch (error) {
                console.error(logPrefix, `error subscribing to subjects "${subjects}":`, error);
            }
        });
    }
    publishToStream(subject, message) {
        return __awaiter(this, void 0, void 0, function* () {
            const logPrefix = 'NatsClient :: publishToStream ::';
            if (!this.jetStreamClient) {
                console.error(logPrefix, 'JetStream client not initialized.');
                return;
            }
            const codec = JSONCodec();
            try {
                yield this.jetStreamClient.publish(subject, codec.encode(message));
                console.log(logPrefix, `Message published on "${subject}":`, message);
            }
            catch (error) {
                console.error(logPrefix, 'error publishing to stream:', error);
            }
        });
    }
    // Connect to NATS WebSocket server
    connectToNatServer(servers, jwtToken, streamName) {
        return __awaiter(this, void 0, void 0, function* () {
            const logPrefix = 'NatsClient :: connectToNatServer ::';
            try {
                console.log(logPrefix, 'trying to connect to nats server:', servers);
                if (!jwtToken) {
                    console.error(logPrefix, 'JWT token is missing after requestJWT');
                    return false;
                }
                this.generateNKeyPairIfNotExists();
                const authenticator = jwtAuthenticator(jwtToken, () => {
                    if (!this.nkeyPair) {
                        throw new Error('NKeyPair is not available');
                    }
                    return this.nkeyPair.getSeed();
                });
                console.log(logPrefix, 'trying to connect to nats server:', servers);
                const options = {
                    servers: servers,
                    authenticator,
                    reconnect: true,
                    reconnectTimeWait: 2000, // 2 seconds reconnect wait time
                };
                this.nc = yield connect(options);
                console.log(logPrefix, 'Connected to NATS using JWT!');
                this.jetStreamClient = this.nc.jetstream();
                this.streamName = streamName;
                this.clearAllGlobalSubscriptions();
                this.handleConnectionStatus(this.nc, this.streamName);
                return true;
            }
            catch (error) {
                console.error(logPrefix, 'Failed to connect to NATS server:', error);
            }
            return false;
        });
    }
    getMessageForSubject(subject, streamName = this.streamName) {
        return __awaiter(this, void 0, void 0, function* () {
            const logPrefix = 'NatsClient :: getMessageForSubject ::';
            try {
                if (!this.nc)
                    throw new Error('Nats connection is not available to get message');
                const directGetSubject = `$JS.API.DIRECT.GET.${streamName}.${subject}`;
                console.log(logPrefix, 'getting latest message for subject', subject);
                const reply = yield this.nc.request(directGetSubject);
                this.updateStoreDirectGet(reply);
            }
            catch (err) {
                console.error(logPrefix, 'error in getting latest message using direct get for subject', subject, err);
            }
        });
    }
}
export default NatsClient.getInstance();
