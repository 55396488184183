import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Flex } from '@magnetic/flex';
import { Skeleton } from '@magnetic/skeleton';
import StyledFloorCircle from './FloorCircle/Styled-FloorCircle';
import DetailedFloorRow from './DetailedFloorRow';
import { SelectFloorSubTitle, FloorContainer } from './styled-floor-selector';
import useFloors from './hook/floor-selection';
import { setSelectedLocation, getContext } from '../../../redux/spaces-slice';
export default function FloorSelector() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { floors } = useFloors();
    const context = useSelector(getContext);
    const buildingConfig = context.appConfig.building;
    const floorOccupancy = buildingConfig.showFloorOccupancy;
    const setSelectedLocationId = ({ id = -1 }) => {
        if (id !== -1) {
            console.log('Floor selection id', id);
            dispatch(setSelectedLocation(id.toString()));
            navigate('/floor', { state: { id } });
        }
    };
    const floorRows = floors.length < 10
        ? floors.map((floor) => (_jsx(DetailedFloorRow, { percentage: floor.percentage, floorStatus: floor.floorStatus, floorLevel: floor.floorLevel, isSelected: floor.isSelected, selectLocation: setSelectedLocationId, floor: floor, showFloorOccupancy: floorOccupancy }, `floor-${floor.id}-${floor.floorLevel}`)))
        : _.chunk(floors, 3).map((floorChunk) => (_jsx(Flex, Object.assign({ className: "floor-row", gap: 14, style: { marginBottom: '14px' } }, { children: floorChunk.map((floor) => (_jsx(StyledFloorCircle, { className: "building_select_floor", percentage: floor.percentage, floorStatus: floor.floorStatus, floorLevel: floor.floorLevel, isSelected: floor.isSelected, selectLocation: setSelectedLocationId, floor: floor }, `floor-${floor.id}-${floor.floorLevel}`))) }), floorChunk[0].id)));
    return (_jsxs(FloorContainer, { children: [_jsx("div", Object.assign({ className: "action-prompt" }, { children: t('floor.select-floor') })), _jsx(SelectFloorSubTitle, { children: t('floor.select-floor-subtitle') }), floors.length > 0 ? (floorRows) : (_jsxs(Flex, Object.assign({ direction: "vertical", gap: 24, style: { width: '350px', marginTop: '24px' } }, { children: [_jsxs(Flex, Object.assign({ gap: 24 }, { children: [_jsx(Skeleton, { height: 60, style: {
                                    width: 60,
                                } }), _jsxs(Flex, Object.assign({ direction: "vertical", gap: 12, grow: true }, { children: [_jsx(Skeleton, { height: "sm", style: {
                                            width: '100%',
                                        } }), _jsx(Skeleton, { height: "sm", style: {
                                            width: '75%',
                                        } })] }))] })), _jsxs(Flex, Object.assign({ gap: 24 }, { children: [_jsx(Skeleton, { height: 60, style: {
                                    width: 60,
                                } }), _jsxs(Flex, Object.assign({ direction: "vertical", gap: 12, grow: true }, { children: [_jsx(Skeleton, { height: "sm", style: {
                                            width: '100%',
                                        } }), _jsx(Skeleton, { height: "sm", style: {
                                            width: '75%',
                                        } })] }))] })), _jsxs(Flex, Object.assign({ gap: 24 }, { children: [_jsx(Skeleton, { height: 60, style: {
                                    width: 60,
                                } }), _jsxs(Flex, Object.assign({ direction: "vertical", gap: 12, grow: true }, { children: [_jsx(Skeleton, { height: "sm", style: {
                                            width: '100%',
                                        } }), _jsx(Skeleton, { height: "sm", style: {
                                            width: '75%',
                                        } })] }))] }))] })))] }));
}
