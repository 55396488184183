import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from '@magnetic/skeleton';
import WorkSpacesLogoSVG from '../../../../assets/images/workspaces-logo.svg';
import { useTranslation } from 'react-i18next';
import Loader from '../../../../common/loader/loader';
import Error from '../../../../common/error/error';
import { PoweredBy, ScalableImage, } from '../../../../common/styles/commonstyles';
import { configureHeartbeatUpdateMessage, configureSendWebSocketMessage, updateDeviceToken, updateStatus, } from '../../../../store/configureslice';
import { DataService } from '../../service/deviceservice';
import { subscribeToWebSocketEvent } from './../../../../store/configuremiddleware';
import { DisplayCodeActivation, DisplayCodeDisplay, DisplayCodeSteps, Footer, ResponsiveContent, VerticalLine, } from './display-code-elements';
import { useNavigate } from 'react-router-dom';
const getDomain = (dataService) => {
    let domain = dataService.getDomain();
    if (!domain) {
        console.log('Domain not found in cache, fetching from location.hostname');
        domain = location.hostname;
        dataService.setDomain(domain);
    }
    return domain;
};
const DisplayCode = ({ tm, devciceId, orgId, isNav, }) => {
    const dataService = new DataService();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [codeToShow, setCodeToShow] = useState(null);
    const domain = getDomain(dataService);
    const serverUrl = `wss://${domain}/init`;
    console.log('Signage server URL:', serverUrl);
    const isConnected = useSelector((state) => state.configure.isConnected);
    const isWSInitialized = useRef(false); // Track if WebSocket was initialized
    const signageActivated = useSelector((state) => state.configure.isActivated);
    const deviceToken = useSelector((state) => state.configure.token);
    const navigate = useNavigate();
    const webGL = (() => {
        try {
            const canvas = document.createElement('canvas');
            return !!(canvas.getContext('webgl') || canvas.getContext('experimental-webgl'));
        }
        catch (e) {
            return false;
        }
    })();
    function extractTokenFromURL(url) {
        // Extract the part before the hash (#) if it exists
        const urlWithoutHash = url.split('#')[0];
        // Create a URL object for parsing the query parameters
        const urlParams = new URLSearchParams(new URL(urlWithoutHash).search);
        // Get the token from the query parameters
        const token = urlParams.get('token');
        return token;
    }
    const connectWebSocket = useCallback(() => {
        if (isWSInitialized.current || tm === '' || tm === null)
            return; // Avoid reinitializing
        isWSInitialized.current = true;
        dataService.setTenantIdMasked(tm);
        dataService.setDeviceId(devciceId);
        dataService.setOrgId(orgId);
        dataService.setIsNavigator(isNav);
        dispatch({
            type: 'CONFIGURE_APP_START',
            payload: { serverUrl: serverUrl, webGL: webGL },
        });
        let unsubscribeShowFrameUpdate;
        let unsubscribeSetTokenUpdate;
        let unsubscribeInitUpdate;
        if (!deviceToken) {
            unsubscribeInitUpdate = subscribeToWebSocketEvent('init', (payload) => {
                console.log('Init message received:', payload);
                if (payload.code)
                    dataService.setActivationCode(payload.code);
                dataService.setTenantIdMasked(tm);
                dataService.setDeviceId(payload.uid);
                dataService.setDeviceType(payload.deviceType);
                if (payload.code) {
                    setCodeToShow(dataService.getActivationCode());
                    // TODO codeToShow won't be avaulable immediately, so use payload.code
                    console.log('Activation code:', codeToShow + ' activatiom code received ' + payload.code);
                }
            });
            unsubscribeSetTokenUpdate = subscribeToWebSocketEvent('setToken', (payload) => {
                console.log('SetToken message received:', payload);
                if (payload.status === true ||
                    (dataService.getDeviceId() && dataService.getOrgId())) {
                    dataService.setRMSToken(payload.token);
                    dataService.setActivated(true);
                    dispatch(updateStatus(true));
                    const heartbeatContext = {
                        event: 'heartbeat',
                        payload: { token: dataService.getRMSToken() },
                    };
                    dispatch(configureHeartbeatUpdateMessage(heartbeatContext));
                    const fetchUrlEvent = {
                        event: 'fetchUrl',
                        payload: { token: payload.token },
                        timestamp: Date.now(),
                    };
                    dispatch(configureSendWebSocketMessage(fetchUrlEvent));
                    console.log('set Token update Received  and status recevied ' + payload.status);
                }
                else {
                    console.error('set Token updete Failed  and payload  recevied ' +
                        JSON.stringify(payload));
                }
            });
            unsubscribeShowFrameUpdate = subscribeToWebSocketEvent('showFrame', (payload) => {
                console.log('ShowFrame message received:', payload);
                if (payload.url) {
                    const deviceToken = extractTokenFromURL(payload.url);
                    if (deviceToken) {
                        dataService.setDeviceToken(deviceToken);
                        dispatch(updateDeviceToken(deviceToken));
                    }
                    else {
                        console.error('ShowFrame Event Failed due token extraction failure ' +
                            JSON.stringify(payload));
                    }
                }
                else {
                    console.error('ShowFrame Event Failed  since url part of the payload is null ' +
                        JSON.stringify(payload));
                }
            });
        }
        else {
            console.log('Device token already exists in the cache, so redirecting to workspaces');
        }
        return () => {
            unsubscribeShowFrameUpdate();
            unsubscribeSetTokenUpdate();
            unsubscribeInitUpdate();
        };
    }, [isWSInitialized, codeToShow]);
    useEffect(() => {
        connectWebSocket(); // Initialize WebSocket only once
    }, [dispatch]);
    useEffect(() => {
        console.log('Redux isConnected state updated:', isConnected);
    }, [isConnected]);
    if (tm === '')
        return _jsx(Error, {});
    if (!isConnected) {
        return _jsx(Loader, {});
    }
    function showActivationCode() {
        return (_jsxs(_Fragment, { children: [_jsxs(DisplayCodeSteps, { children: [_jsx("p", Object.assign({ className: "display-code-follow" }, { children: t('configure.display-code.follow-steps-text') })), _jsxs("ol", { children: [_jsx("li", { children: t('configure.display-code.follow-steps-login') }), _jsx("li", { children: t('configure.display-code.follow-steps-launch-exp-app') }), _jsxs("li", { children: [t('configure.display-code.follow-steps-add-non-webex'), _jsx("span", { children: t('configure.display-code.follow-steps-goto-devices') })] }), _jsx("li", { children: t('configure.display-code.follow-steps-enter-activation-code') })] })] }), _jsxs(DisplayCodeDisplay, Object.assign({ "$signage_activated": signageActivated.toString() }, { children: [_jsxs("div", Object.assign({ className: "display-code-header" }, { children: [' ', t('configure.display-code.activation-code-title')] })), codeToShow ? (_jsxs("div", Object.assign({ className: "display-code-code" }, { children: [" ", codeToShow] }))) : (_jsx(Skeleton, { shade: "regular", height: 180, style: {
                                width: 400,
                                marginTop: 40,
                                marginBottom: 40,
                            } })), _jsx("div", Object.assign({ className: "display-code-workspaces-logo" }, { children: _jsx(ScalableImage, { width: "100%", src: WorkSpacesLogoSVG }) })), _jsxs("div", Object.assign({ className: "display-code-logo-text" }, { children: [' ', t('common.smartworkspaces.moto')] }))] }))] }));
    }
    function showActivationSuccess() {
        return (_jsxs(_Fragment, { children: [_jsxs(DisplayCodeDisplay, Object.assign({ "$signage_activated": signageActivated.toString() }, { children: [_jsx("div", Object.assign({ className: "display-code-workspaces-logo" }, { children: _jsx(ScalableImage, { width: "100%", src: WorkSpacesLogoSVG }) })), _jsxs("div", Object.assign({ className: "display-code-logo-text" }, { children: [' ', t('common.smartworkspaces.moto')] }))] })), _jsx(VerticalLine, {}), _jsxs(DisplayCodeSteps, { children: [_jsx("p", Object.assign({ className: "display-code-follow" }, { children: t('configure.display-code.post-activate-steps-text') })), _jsxs("ol", { children: [_jsx("li", { children: t('configure.display-code.post-activate-steps-login') }), _jsx("li", { children: t('configure.display-code.post-activate-steps-launch-exp-app') }), _jsx("li", { children: t('configure.display-code.post-activate-steps-goto-devices') }), _jsx("li", { children: t('configure.display-code.post-activate-steps-provision-content') })] })] })] }));
    }
    if (deviceToken) {
        navigate('/home', { state: { token: deviceToken } });
        return null;
    }
    else
        return (_jsx(_Fragment, { children: _jsxs(ResponsiveContent, { children: [_jsx(DisplayCodeActivation, { children: signageActivated === true
                            ? showActivationSuccess()
                            : showActivationCode() }), _jsx(Footer, { children: _jsx(PoweredBy, { children: _jsx(ScalableImage, { src: require('../../../../assets/images/footer.png'), alt: "Powered by Cisco Spaces" }) }) })] }) }));
};
export default DisplayCode;
