import { createSlice } from '@reduxjs/toolkit';
import { DataService } from '../app/configure/service/deviceservice';
const dataService = new DataService();
const initialState = {
    isActivated: dataService.isActivated(),
    token: dataService.getDeviceToken(),
    criticalErrors: [],
    warnings: [],
    isConnected: false,
    heartbeatContext: {
        token: '',
        tm: '',
    },
};
const ConffigureWSSlice = createSlice({
    name: 'configure',
    initialState,
    reducers: {
        updateStatus: (state, action) => {
            state.isActivated = action.payload;
        },
        addCriticalError: (state, action) => {
            state.criticalErrors.push(action.payload);
        },
        addWarning: (state, action) => {
            state.warnings.push(action.payload);
        },
        setWebSocketStatus(state, action) {
            state.isConnected = action.payload;
            //console.log('Configure Slice:: setWebSocketStatus isConnected:', state.isConnected);
        },
        updateHeartbeatContext(state, action) {
            // state.heartbeatContext = { ...state.heartbeatContext, ...action.payload };
        },
        updateDeviceToken(state, action) {
            state.token = action.payload;
        },
    },
});
export const configureHeartbeatUpdateMessage = (message) => ({
    type: 'CONFIGURE_HEARTBEAT_UPDATE_MESSAGE',
    payload: { message },
});
export const configureSendWebSocketMessage = (message) => ({
    type: 'CONFIGURE_SEND_WS_MESSAGE',
    payload: { message },
});
//export const selectHeartbeatContext = (state) =>state.websocket.heartbeatContext;
export const { updateStatus, addCriticalError, addWarning, setWebSocketStatus, updateHeartbeatContext, updateDeviceToken, } = ConffigureWSSlice.actions;
export default ConffigureWSSlice.reducer;
