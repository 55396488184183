export const pxToVw = (width) => {
    return `${(width / 1920) * 100}vw`;
};
export const pxToVh = (height) => {
    return `${(height / 1080) * 100}vh`;
};
export const floorStatusByPercentage = (percentage) => {
    if (percentage < 20)
        return 'very-low';
    if (percentage < 40)
        return 'low';
    if (percentage < 60)
        return 'busy';
    if (percentage < 80)
        return 'very-busy';
    if (percentage >= 80)
        return 'crowded';
    return 'no-data';
};
function getExpiry(token) {
    const tokenPayloadBase64 = token.split('.')[1];
    let encodedString = tokenPayloadBase64.replace(/-/g, '+').replace(/_/g, '/');
    switch (encodedString.length % 4) {
        case 0:
            break;
        case 2:
            encodedString += '==';
            break;
        case 3:
            encodedString += '=';
            break;
        default:
    }
    const decodedPayload = atob(encodedString);
    const decodedToken = JSON.parse(decodedPayload);
    return decodedToken.exp;
}
export const isExpired = (token) => {
    if (!token) {
        console.error('Token is null or undefined');
        return true;
    }
    const expiry = getExpiry(token);
    return expiry < Date.now() / 1000;
};
export const expiresIn = (token, minutes) => {
    if (!token) {
        console.error('Token is null or undefined');
        return true;
    }
    const expiry = getExpiry(token);
    return expiry < Date.now() / 1000 + minutes * 60;
};
