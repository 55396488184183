import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
function TimeLine({ className = '', date = moment(), meetings = [], status, timeLineLimit = 12, timeLineGap = 2, timeLineUnits = 'hours', simpleTimeFormat = 'hA', currentTimeFormat = 'hh:mmA', minutesDivider, widgetCount, timeZoneId, }) {
    const { t } = useTranslation();
    const timeZone = timeZoneId;
    const [currentMoment, setCurrentMoment] = useState(moment().tz(timeZone).clone());
    const currentHour = currentMoment.hour();
    const adjustedHour = currentHour % 2 === 1 ? 1 : 2;
    const [startTime, setStartTime] = useState(currentMoment.clone().add(adjustedHour, timeLineUnits));
    const [startHour, setStartHour] = useState(currentHour + adjustedHour);
    const [endHour, setEndHour] = useState(startHour + timeLineLimit);
    const [hoursList, setHoursList] = useState(getHoursList(startHour, endHour));
    const [currentTimeFormatted, setCurrentTimeFormatted] = useState(currentMoment.format(currentTimeFormat));
    const [maxMeetingLineHeight, setMaxMeetingLineHeight] = useState(45 * hoursList.length + 10); //(45(time duration gap height) * 5 (hoursList entries) + 10 (margin))
    function getHoursList(start, end) {
        return new Array((end + 2 - start) / 2)
            .fill(0)
            .map((item, index) => start + index * timeLineGap);
    }
    useEffect(() => {
        const timeUpdateInterval = setInterval(() => {
            // Sets current time formatted for read purpose.
            const curMoment = moment().tz(timeZone).clone();
            setCurrentMoment(curMoment);
            setCurrentTimeFormatted(() => curMoment.format(currentTimeFormat));
            // Sets current hour Eg. Current Time is 11.53 then hour will be 11
            const currHour = curMoment.hour();
            // Sets adjust hour which is used to have one or two hours before the current time.
            const adjHour = currHour % 2 === 1 ? 1 : 2;
            // Sets the start hour of the time line.
            const strHour = currHour + adjHour;
            setStartHour(strHour);
            // Sets the end hour of the time line.
            const enHour = strHour + timeLineLimit;
            setEndHour(enHour);
            // Sets the timeline start time.
            const strTime = curMoment.clone().add(adjHour, timeLineUnits);
            setStartTime(strTime);
            // Sets the timeline end time.
            const hourList = getHoursList(strHour, enHour);
            setMaxMeetingLineHeight(45 * hourList.length + 10);
            // Sets list of hours in the time line.
            setHoursList(hourList);
        }, 1000);
        return () => clearInterval(timeUpdateInterval);
    });
    return (_jsx("div", Object.assign({ className: `${className} ${status}` }, { children: _jsxs("ul", { children: [_jsxs("li", Object.assign({ className: "current-time" }, { children: [_jsx("span", Object.assign({ className: `hour ${status}` }, { children: currentTimeFormatted })), _jsx("span", { className: `line ${status}` })] })), _jsx("p", { children: [t('floor.room-details-popup.later-today'), ':'] }), _jsxs("div", Object.assign({ className: "calendar" }, { children: [_jsx("div", Object.assign({ className: "time-slot" }, { children: hoursList &&
                                hoursList.map((selectedHour) => {
                                    var hourMoement = moment()
                                        .tz(timeZone)
                                        .clone()
                                        .hours(selectedHour)
                                        .startOf('hour');
                                    return (_jsxs("li", { children: [_jsx("span", Object.assign({ className: "hour" }, { children: hourMoement.format(simpleTimeFormat) })), _jsx("span", { className: "line", style: { height: `1px` } })] }));
                                }) })), _jsx("div", Object.assign({ className: "meeting" }, { children: hoursList &&
                                meetings.map((meeting) => {
                                    const { start, end } = meeting;
                                    const endHourMoment = moment()
                                        .tz(timeZone)
                                        .clone()
                                        .hours(endHour)
                                        .startOf('hour');
                                    if (end.isBefore(startTime) || start.isAfter(endHourMoment)) {
                                        return;
                                    }
                                    const startHourMoment = moment()
                                        .tz(timeZone)
                                        .clone()
                                        .hours(startHour)
                                        .startOf('hour');
                                    const minutes = end.diff(start, 'minutes');
                                    const startMeetingMinutesDiff = start.diff(startHourMoment, 'minutes');
                                    const meetingTimeHeight = startMeetingMinutesDiff > 0
                                        ? startMeetingMinutesDiff / minutesDivider + 10
                                        : 10;
                                    var height = Math.min(minutes / minutesDivider, maxMeetingLineHeight);
                                    height =
                                        height + meetingTimeHeight > maxMeetingLineHeight
                                            ? height - meetingTimeHeight
                                            : height;
                                    return (_jsx("span", Object.assign({ className: "line", style: {
                                            height: `${height}px`,
                                            top: `${meetingTimeHeight}px`,
                                            marginLeft: widgetCount !== 0 ? '0vw' : '3.2vw',
                                        } }, { children: t('floor.room-details-popup.booked') })));
                                }) }))] }))] }) })));
}
TimeLine.propTypes = {
    className: PropTypes.string.isRequired,
    date: PropTypes.object,
    meetings: PropTypes.array,
    timeLineLimit: PropTypes.number,
    timeLineGap: PropTypes.number,
    timeLineUnits: PropTypes.string,
    status: PropTypes.string,
    simpleTimeFormat: PropTypes.string,
    currentTimeFormat: PropTypes.string,
    minutesDivider: PropTypes.number,
};
TimeLine.defaultProps = {
    date: moment(),
    meetings: [],
    timeLineLimit: 12,
    timeLineGap: 2,
    timeLineUnits: 'hours',
    simpleTimeFormat: 'hA',
    currentTimeFormat: 'hh:mmA',
    status: 'Available',
    minutesDivider: 2.67,
};
export default TimeLine;
