import styled, { keyframes, createGlobalStyle } from 'styled-components';
export const GlobalStyle = createGlobalStyle `
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body {
    height: 100%; /* Ensure the body fills the full height */
    width: 100%;  /* Ensure the body fills the full width */
    background-color: black;
    color: white; /* Set text color to white for contrast */
    font-family: apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden; /* Prevent scrollbars */
  }

  #root {
    width: 100%;
    height: 100%;
  }
`;
// Full-page container to center content both horizontally and vertically
export const FullPageContainer = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%; /* Ensure the container fills the entire height */
  text-align: center;
  padding: 0 20px; /* Optional padding for small screens, remove excess padding */
  box-sizing: border-box;

  /* Ensure the container doesn't overflow */
  overflow: hidden;

  /* Ensuring responsiveness */
  @media (max-width: 768px) {
    padding: 0 15px;
  }

  @media (max-width: 480px) {
    padding: 0 10px;
  }

`;
export const CenteredDiv = styled.div `
  display: flex;
  flex-direction: column;    /* Stack items vertically */
  justify-content: center;   /* Center items vertically in the container */
  align-items: center;       /* Center items horizontally */
  height: 100vh;             /* Full viewport height */
  box-sizing: border-box;
  padding: 20px;             /* Optional padding around the container */
`;
// Simple content div (can be customized later)
export const Content = styled.div `
  font-size: 1.5rem;
  color: white;
  font-weight: 400;
  word-wrap: break-word;

  /* Adjust text size on smaller screens */
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }

  /* Adjust for very large screens (8K resolution and above) */
  @media (min-width: 7680px) {
    font-size: 2rem; /* Increase font size for extremely large screens */
  }
`;
// Spinner animation for rotation
export const spin = keyframes `
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
// Larger spinner circle, blue in color
export const Spinner = styled.div `
  border: 8px solid transparent;
  border-top: 8px solid #3498db; /* Blue color */
  border-radius: 50%;
  width: 120px; /* Increased size */
  height: 120px; /* Increased size */
  margin-bottom: 30px;
  animation: ${spin} 1s linear infinite;
`;
// Logo positioning and styling
export const LogoContainer = styled.div `
  margin-bottom: 20px;
`;
// Motto text below the logo
export const Motto = styled.p `
  font-size: 1.2rem;
  font-weight: 800;
  color: white;
  margin: 0;
  max-width: 600px;
  padding: 0 15px;
  line-height: 1.4;
  text-align: center;
  word-wrap: break-word;
  overflow: hidden;
  white-space: nowrap;

  /* Ensures motto stays on one line and adjusts on screen size */
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
`;
export const PoweredBy = styled.div `
  color: #fff;
  float: left;
  font-family: Sora;
  font-style: normal;
  font-weight: 200;
  line-height: 1.57vw;
  padding-right: 1vw;
`;
export const ScalableImage = styled.img `
  width: ${(props) => props.width || '50%'}; /* Default to 50% if no width is passed */
  height: auto; /* Maintain aspect ratio */
  max-width: 100%; /* Prevent the image from exceeding the container's width */
  
  /* Gradually reduce the image size based on screen size */
  @media (max-width: 1200px) {
    width: ${(props) => props.width || '40%'}; /* Reduce to 40% on medium screens */
  }

  @media (max-width: 992px) {
    width: ${(props) => props.width || '30%'}; /* Reduce to 30% on smaller screens */
  }

  @media (max-width: 768px) {
    width: ${(props) => props.width || '25%'}; /* Reduce further on smaller mobile screens */
  }

  @media (max-width: 480px) {
    width: ${(props) => props.width || '20%'}; /* Reduce to 20% on very small mobile screens */
  }
`;
