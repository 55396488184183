export class Constants {
}
Constants.HEART_BEAT_INTERVAL = 30000;
Constants.WATCH_TIMEOUT = 5000;
Constants.CACHE_MODULE = 'configure-module';
Constants.EVENTS = {
    READ: {
        STATUS: 'status',
        INIT: 'init',
        SHOW_FRAME: 'showFrame',
        SET_TOKEN: 'setToken',
        ACTIVATE_DEVICE: 'activateDevice',
        REFRESH: 'refresh',
    },
    WRITE: {
        INIT: 'init',
        FETCH_URL: 'fetchUrl',
        HEARTBEAT: 'heartbeat',
        REFRESH_TOKEN: 'refreshToken',
    },
    INTERNAL: {
        SHOW_FRAME: 'showFrame',
        SET_TOKEN: 'setToken',
    },
};
Constants.PAGES = {
    LOADER: '/loader',
    CONFIGURE: '/configure',
    DISPLAY_CODE: '/displayCode',
    SHOW_FRAME: '/showFrame',
    DELETE: '/delete',
};
Constants.IFRAME_MESSAGE_TYPE = {
    GET_TOKEN: 'getToken@dnas-sw',
    SET_TOKEN: 'setToken@dnas-sw',
};
Constants.CACHE = {
    DEVICE_TYPE: '/t/deviceType',
    DEVICE_NAME: 'deviceName',
    ACTIVATED: '/t/activated',
    TOKEN: 'token',
    RMS_TOKEN: 'rms_token',
    FRAME_URL: 'frameUrl',
    ORG_ID: '/t/orgId',
    DEVICE_ID: '/t/deviceId',
    IS_NAVIGATOR: 'isNavigator',
    NAVIGATOR_PARAMS_EXTRACT: 'navigatorParamsExtract',
    CODE: 'code',
    TENANT_ID_MASKED: '/t/tenantIdMasked',
    UID: '/t/uid',
    DOMAIN: 'domain',
};
Constants.DEVICE = {
    WEBEX: 'webex',
    NON_WEBEX: 'non-webex',
    NAVIGATOR: 'navigator',
};
