import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import styled from 'styled-components';
import { Flex } from '@magnetic/flex';
import { useTranslation } from 'react-i18next';
import RoomFinder from '../room-finder';
import { store } from '../../../../../store/store';
import { getRoomFinderStatus, setRoomFinderOpen, setShowOnMap, } from '../../../../../store/uislice';
import { useSelector } from 'react-redux';
import { selectFloorById } from '../../../redux/spaces-slice';
import { useLocation } from 'react-router-dom';
const Text = styled.div `
  font-size: ${(props) => props.fontSize}vw;
`;
const ArrowUp = styled.div `
  width: 0;
  height: 0;
  border-left: 0.8vw solid transparent;
  border-right: 0.8vw solid transparent;
  border-bottom: 0.8vw solid #61dc2a;
  margin-left: 2vw;
  margin-bottom: -1.4vw;
  margin-top: 1vw;
`;
const RoomCountsContainer = styled(Flex) `
  min-height: fit-content;
  padding: 0.6vw;
  width: 16.8vw;
  margin-top: 1vw;
  margin-left: 0.4vw;
  background: #1f2226;
  border-radius: 0.8vw;
`;
const CountBoxContainer = styled(Flex) `
  border: 1.6px solid ${(props) => props.borderColor};
  height: fit-content;
  padding: 0.2vw;
  width: 4vw;
  border-radius: 0.8vw;
  background: ${(props) => props.backgroundColor};
  text-align: center;
  margin-top: 0.4vw;
`;
const TotalCount = styled.div `
  font-size: 3.2vw;
  font-family: 'CiscoSans Thin';
`;
const Button = styled(Flex) `
  height: 2.2vw;
  background: ${(props) => props.background};
  border-radius: 0.6vw;
  text-align: center;
  font-size: 1.2vw;
  padding-top: 1vw;
  margin: 1.4vw 0vw;
  z-index: 9999;
  cursor: pointer;
`;
const CountBox = ({ t, status, count }) => {
    const backgroundColorMap = {
        Available: '#4D7E3A',
        Booked: '#8B390E',
        Occupied: '#A5314B',
    };
    const borderColorMap = {
        Available: '#71FF31',
        Booked: '#FF8F54',
        Occupied: '#FCA5B8',
    };
    const localizedStatus = {
        Available: t('floor.counts-widget.available'),
        Booked: t('floor.counts-widget.booked'),
        Occupied: t('floor.counts-widget.occupied'),
    };
    return (_jsxs(CountBoxContainer, Object.assign({ direction: "vertical", backgroundColor: backgroundColorMap[status], borderColor: borderColorMap[status] }, { children: [_jsx(Text, Object.assign({ fontSize: 1.6 }, { children: count })), _jsx(Text, Object.assign({ fontSize: 0.8 }, { children: localizedStatus[status] }))] })));
};
const TotalInfo = ({ t, forRooms, count }) => {
    const text1 = forRooms
        ? t('floor.counts-widget.meeting-rooms')
        : t('floor.counts-widget.desks-on-this');
    const text2 = forRooms
        ? t('floor.counts-widget.on-this-floor')
        : t('floor.counts-widget.floor');
    return (_jsxs(Flex, { children: [_jsx(TotalCount, { children: count }), _jsxs(Flex, Object.assign({ style: { margin: '0.72vw' }, direction: "vertical" }, { children: [_jsx(Text, Object.assign({ fontSize: 1 }, { children: text1 })), _jsx(Text, Object.assign({ fontSize: 1 }, { children: text2 }))] }))] }));
};
const RoomFinderButton = ({ t, roomFinderButtonHandler }) => {
    return (_jsx(Button, Object.assign({ background: "linear-gradient(180deg, #1D7FF3 0%, #113D7D 100%)", direction: "vertical", onClick: roomFinderButtonHandler }, { children: t('floor.counts-widget.room-finder') })));
};
const SeeAvailableDesksButton = ({ t }) => {
    const seeAvailableDesksHandler = () => {
        store.dispatch(setShowOnMap({ payload: 'Desks' }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(ArrowUp, {}), _jsx(Button, Object.assign({ onClick: () => seeAvailableDesksHandler(), background: "linear-gradient(180deg, #61DC2A 0%, #3D6D27 100%)", direction: "vertical" }, { children: t('floor.counts-widget.see-available-desks') }))] }));
};
const CountsWidget = ({ floorId, forRooms }) => {
    var _a, _b;
    const { t } = useTranslation();
    const location = useLocation();
    const floorLocationId = location.state.id || floorId;
    const showRoomFinder = useSelector(getRoomFinderStatus);
    const floorAnalytics = useSelector((state) => selectFloorById(state, floorLocationId));
    useEffect(() => {
        console.log('Floor view :: Floor analytics', floorAnalytics);
    }, [floorAnalytics]);
    const availabilityInfo = forRooms
        ? (_a = floorAnalytics === null || floorAnalytics === void 0 ? void 0 : floorAnalytics.availabilityInfo) === null || _a === void 0 ? void 0 : _a.roomInfo
        : (_b = floorAnalytics === null || floorAnalytics === void 0 ? void 0 : floorAnalytics.availabilityInfo) === null || _b === void 0 ? void 0 : _b.deskInfo;
    const availableCount = availabilityInfo === null || availabilityInfo === void 0 ? void 0 : availabilityInfo.available.length;
    const bookedCount = availabilityInfo === null || availabilityInfo === void 0 ? void 0 : availabilityInfo.booked.length;
    const occupiedCount = availabilityInfo === null || availabilityInfo === void 0 ? void 0 : availabilityInfo.occupied.length;
    const noDataCount = availabilityInfo === null || availabilityInfo === void 0 ? void 0 : availabilityInfo.noData.length;
    const roomFinderButtonHandler = () => {
        store.dispatch(setRoomFinderOpen({ payload: true }));
    };
    return (_jsxs(RoomCountsContainer, Object.assign({ direction: "vertical" }, { children: [_jsx(TotalInfo, { t: t, forRooms: forRooms, count: availableCount + bookedCount + occupiedCount + noDataCount }), _jsxs(Flex, Object.assign({ justify: "space-between" }, { children: [_jsx(CountBox, { t: t, status: "Available", count: availableCount }), _jsx(CountBox, { t: t, status: "Booked", count: bookedCount }), _jsx(CountBox, { t: t, status: "Occupied", count: occupiedCount })] })), forRooms ? (showRoomFinder ? (_jsx(RoomFinder, { floorId: floorLocationId, onClose: () => store.dispatch(setRoomFinderOpen({ payload: false })) })) : (_jsx(RoomFinderButton, { t: t, roomFinderButtonHandler: roomFinderButtonHandler }))) : (_jsx(SeeAvailableDesksButton, { t: t }))] })));
};
export default CountsWidget;
