var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { closeRoomDetailsPopup } from '../room-details-popup';
import { useSelector } from 'react-redux';
import { getContext, getLocation } from '../../../redux/spaces-slice';
import { useLocation } from 'react-router-dom';
import BuildingName from './building-name.styled';
import Container from './container.styled';
import Modal from './modal.styled';
import FloorName from './floor-name.styled';
import DropdownIcon from './dropdown-icon.styled';
import DropdownContent from './dropdown-content.styled';
const FloorDetails = styled.div `
  width: 22vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .dropdown-container {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-container:hover .dropdown-content {
    display: block;
  }
`;
const FloorInfo = ({ floorId, building, floor, selectFloor }) => {
    var _a, _b, _c;
    const location = useLocation();
    const { id: floorLocationId } = location.state || floorId;
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    // const [floorNumbers, setFloorNumbers] = useState([1, 2, 3, 4, 5].map(String));
    const locationData = useSelector(getLocation);
    const floorLocationData = (_a = locationData === null || locationData === void 0 ? void 0 : locationData.children) === null || _a === void 0 ? void 0 : _a.find((child) => child.id === floorLocationId);
    const floorNumber = floorLocationData === null || floorLocationData === void 0 ? void 0 : floorLocationData.floorNumber;
    const [selectedFloor, setSelectedFloor] = useState(String(floorNumber));
    const [floorNumberIdMap, setFloorNumberIdMap] = useState({});
    const deviceFloor = (_b = useSelector(getContext)) === null || _b === void 0 ? void 0 : _b.deviceLocationId;
    // const [deviceFloor, setDeviceFloor] = useState('4');
    const [floorNumbers, setFloorNumbers] = useState((_c = locationData === null || locationData === void 0 ? void 0 : locationData.children) === null || _c === void 0 ? void 0 : _c.map((child) => String(child.floorNumber)).sort());
    useEffect(() => {
        // setFloorNumberIdMap({});
        setSelectedFloor(String(floorNumber));
    }, [floorNumber]);
    useEffect(() => {
        const floorNumberIdMapTemp = {};
        for (const floorObj of locationData === null || locationData === void 0 ? void 0 : locationData.children) {
            floorNumberIdMapTemp[floorObj.floorNumber] = floorObj.id;
        }
        setFloorNumberIdMap(floorNumberIdMapTemp);
    }, [locationData]);
    const { t } = useTranslation();
    React.useEffect(() => {
        // Fetch floor numbers based on the selected floor
        const fetchFloorNumbers = () => __awaiter(void 0, void 0, void 0, function* () {
            // Simulate an API call to fetch floor numbers
            // const response: string[] = await new Promise((resolve) =>
            //   setTimeout(() => resolve([1, 2, 3, 4, 5, 6, 7].map(String)), 1000)
            // );
            // setFloorNumbers(response);
        });
        fetchFloorNumbers();
    }, [floor]);
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    const handleFloorClick = (floorNumber) => {
        setSelectedFloor(floorNumber);
        setIsDropdownOpen(false);
        selectFloor(floorNumber === 'All' ? floorNumber : floorNumberIdMap[floorNumber]);
    };
    const handleCloseClick = () => {
        setIsDropdownOpen(false);
    };
    return (_jsxs(FloorDetails, { children: [_jsxs(Container, { children: [_jsxs("div", Object.assign({ style: {
                            maxWidth: '18vw',
                        } }, { children: [_jsx(BuildingName, { children: building }), _jsx(FloorName, { children: floor })] })), _jsx("div", Object.assign({ className: "dropdown-container" }, { children: _jsx(DropdownIcon, Object.assign({ onClick: () => {
                                closeRoomDetailsPopup();
                                toggleDropdown();
                            } }, { children: isDropdownOpen ? (_jsx("img", { src: "static/images/floor-map-dropdown.svg", style: { height: '3.2vw', marginLeft: '1vw' } })) : (_jsx("img", { src: "static/images/floor-map-dropdown.svg", style: { height: '3.2vw', marginLeft: '1vw' } })) })) }))] }), isDropdownOpen && (_jsx(Modal, { children: _jsxs(DropdownContent, { children: [_jsxs("div", Object.assign({ className: "popup-header" }, { children: [_jsx("h2", { children: building }), _jsx("button", Object.assign({ className: "close-button", onClick: () => handleCloseClick() }, { children: "X" }))] })), _jsxs("div", Object.assign({ className: "popup-body" }, { children: [_jsx("h3", Object.assign({ style: { width: 'fit-content' } }, { children: [t('floor.switch.popup.select-a-floor'), ':'] })), _jsxs("div", Object.assign({ className: "floor-buttons" }, { children: [_jsx("div", Object.assign({ className: `floor-button ${selectedFloor === 'All' ? 'active' : ''}`, onClick: () => handleFloorClick('All') }, { children: _jsxs("div", Object.assign({ style: {
                                                    borderRadius: '50%',
                                                    width: '6vw',
                                                    height: '6vw',
                                                    border: '1px solid #383b3d',
                                                    lineHeight: '6vw',
                                                } }, { children: [_jsx("div", { children: "All" }), deviceFloor == null && (_jsx("div", Object.assign({ className: "floor-sel" }, { children: _jsx("div", { children: t('floor.switch.popup.you-are-here') }) })))] })) })), floorNumbers.map((floorNumber) => (_jsx("div", Object.assign({ className: `floor-button ${selectedFloor == floorNumber ? 'highlight' : ''}`, onClick: () => handleFloorClick(floorNumber) }, { children: _jsxs("div", Object.assign({ style: {
                                                    borderRadius: '50%',
                                                    width: '6vw',
                                                    height: '6vw',
                                                    border: '1px solid #383b3d',
                                                    lineHeight: '6vw',
                                                } }, { children: [_jsx("div", { children: floorNumber }), deviceFloor === floorNumberIdMap[floorNumber] && (_jsx("div", Object.assign({ className: "floor-sel" }, { children: _jsx("div", { children: t('floor.switch.popup.you-are-here') }) })))] })) }), floorNumber)))] }))] }))] }) }))] }));
};
export default FloorInfo;
