import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { Flex } from '@magnetic/flex';
import MapCompass from '../../../../../assets/svgs/map-compass';
import Close from '../../../../../assets/svgs/close-svg';
import { useTranslation } from 'react-i18next';
import { getLocation } from '../../../redux/spaces-slice';
import { useSelector } from 'react-redux';
import { getFloorNumberIdMap } from '../../util';
const Modal = styled.div `
  background-color: #000;
  height: 100vh;
  opacity: 1;
  position: fixed;
  z-index: 9999999999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.7);
  display: flex !important;
`;
const ModalContent = styled(Flex) `
  background: #1d2023;
  width: 44vw;
  height: 12vw;
  margin: auto;
  font-family: 'CiscoSans Thin';
  border-radius: 0.6vw;
  padding: 1vw;
  font-size: 2vw;
`;
const Text = styled(Flex) `
  font-family: ${(props) => props.fontFamily};
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.color};
  background: ${(props) => props.background};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
`;
const GoButton = styled.div `
  background: linear-gradient(180deg, #1d7ff3 0%, #113d7d 100%);
  height: 2.4vw;
  width: fit-content;
  padding: 0.4vw;
  border-radius: 0.4vw;
  cursor: pointer;
  font-size: 1.4vw;
  display: flex;
`;
export const WhereAmISwitchFloor = ({ currentFloorNumber, switchFloorNumber, selectFloor, onClose, }) => {
    const { t } = useTranslation();
    const locationData = useSelector(getLocation);
    const floorNumberIdMap = getFloorNumberIdMap(locationData);
    const floorLocationId = floorNumberIdMap[Number(switchFloorNumber)];
    return (_jsx(Modal, { children: _jsxs(ModalContent, Object.assign({ direction: "vertical" }, { children: [_jsxs(Flex, Object.assign({ justify: 'space-between' }, { children: [_jsxs(Text, Object.assign({ margin: "0.4vw 0vw" }, { children: [t('floor.where-am-i-switch-floor.label-1'), " ", currentFloorNumber, ' )'] })), _jsx(Close, { onClose: onClose })] })), _jsxs(Text, Object.assign({ fontFamily: "CiscoSansBold", margin: "1vw 0vw" }, { children: [t('floor.where-am-i-switch-floor.label-2'), " ", switchFloorNumber, " ?"] })), _jsx(Flex, Object.assign({ justify: "end" }, { children: _jsxs(GoButton, Object.assign({ onClick: () => {
                            selectFloor(floorLocationId);
                        } }, { children: [_jsx(Flex, Object.assign({ style: { marginRight: '0.2vw' } }, { children: _jsx(MapCompass, {}) })), t('floor.where-am-i-switch-floor.label-3'), " ", switchFloorNumber] })) }))] })) }));
};
