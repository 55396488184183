import { createSlice } from '@reduxjs/toolkit';
// Initial state
const initialState = {
    messages: {},
};
// Create a slice for handling NATS messages
export const natsSlice = createSlice({
    name: 'nats',
    initialState,
    reducers: {
        updateMessage: (state, action) => {
            const { subject, payload } = action.payload;
            state.messages[subject] = payload;
        },
    },
});
// Export actions
export const { updateMessage } = natsSlice.actions;
