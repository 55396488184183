import { Constants } from '../config';
export const getTimerTimeStamp = (fillingAngle) => {
    const secondsLeft = 180 - fillingAngle / 2;
    const seconds = secondsLeft % 60;
    const timeStamp = `${Math.floor(secondsLeft / 60)} : ${seconds < 10 ? '0' + seconds : seconds}`;
    return timeStamp;
};
export function getNextHourInSeconds(timeStamp, timeZone) {
    const timeStampInMs = timeStamp * 1000;
    const date = new Date(timeStampInMs);
    const options = { timeZone: timeZone };
    const timeZoneLocalTime = date.toLocaleString('en-US', options);
    const minutes = Number(timeZoneLocalTime.split(',')[1].split(':')[1]);
    const toAdd = (60 - minutes) * 60 * 1000;
    return (timeStampInMs + toAdd) / 1000;
}
export function getNextQuarterHourInSeconds(timeStamp, timeZone) {
    const timeStampInMs = timeStamp * 1000;
    const date = new Date(timeStampInMs);
    const options = { timeZone: timeZone };
    const timeZoneLocalTime = date.toLocaleString('en-US', options);
    const minutes = Number(timeZoneLocalTime.split(',')[1].split(':')[1]);
    let minutesToAdd = 0;
    if (minutes < 15) {
        minutesToAdd = 15 - minutes;
    }
    else if (minutes < 30) {
        minutesToAdd = 30 - minutes;
    }
    else if (minutes < 45) {
        minutesToAdd = 45 - minutes;
    }
    else {
        minutesToAdd = 60 - minutes;
    }
    return (timeStampInMs + minutesToAdd * 60 * 1000) / 1000;
}
export const getLocaleString = (timeInSeconds, timeZone) => {
    const dateObj = new Date(timeInSeconds * 1000);
    const localeString = dateObj.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: timeZone,
    });
    return localeString;
};
export function hasValue(value) {
    if (value === undefined ||
        value === null ||
        value === 'null' ||
        value === '' ||
        value == 'Unknown' ||
        value === '--' ||
        value === '---' ||
        value === '-' ||
        value === 'no-data') {
        return false;
    }
    return true;
}
export const getWidgetCount = (iaq, co2, temperature, humidity, ambientNoise) => {
    let count = 0;
    if (hasValue(iaq))
        count++;
    if (hasValue(co2))
        count++;
    if (hasValue(temperature))
        count++;
    if (hasValue(humidity))
        count++;
    if (hasValue(ambientNoise))
        count++;
    return count;
};
export const isStatusAvailable = (availabilityStatus) => {
    return availabilityStatus === Constants.AVAILABLE;
};
export const toggleNavigatorLedByAvailability = (availability) => {
    try {
        console.log('Change LED color for room status: ', availability);
        if (typeof getXAPI === 'undefined') {
            console.error('xAPI not defined for the device', null);
        }
        else {
            if (window.deviceXAPI === undefined || window.deviceXAPI === null) {
                getXAPI()
                    .then((deviceXApi) => {
                    console.log('Connected to XAPI');
                    window.deviceXAPI = deviceXApi;
                })
                    .catch((err) => {
                    console.error(`Exception while connecting to xapi for toggleNavigatorLed ::${err.message}`);
                });
            }
            else {
                if (availability == 'Occupied' || availability == 'Occupied_Booked') {
                    window.deviceXAPI.Config.UserInterface.LedControl.Mode.set('Manual');
                    window.deviceXAPI.Command.UserInterface.LedControl.Color.Set({
                        Color: 'Red',
                    });
                }
                else if (availability == 'Booked') {
                    window.deviceXAPI.Config.UserInterface.LedControl.Mode.set('Manual');
                    window.deviceXAPI.Command.UserInterface.LedControl.Color.Set({
                        Color: 'Yellow',
                    });
                }
                else if (availability == 'Hold') {
                    window.deviceXAPI.Config.UserInterface.LedControl.Mode.set('Manual');
                    window.deviceXAPI.Command.UserInterface.LedControl.Color.Set({
                        Color: 'Blue',
                    });
                }
                else if (availability == 'Available') {
                    window.deviceXAPI.Config.UserInterface.LedControl.Mode.set('Manual');
                    window.deviceXAPI.Command.UserInterface.LedControl.Color.Set({
                        Color: 'Green',
                    });
                }
                else {
                    window.deviceXAPI.Config.UserInterface.LedControl.Mode.set('Auto');
                }
            }
        }
    }
    catch (err) {
        console.log('Error trying to toggle navigator LED ');
    }
};
