import styled from 'styled-components';
const BuildingName = styled.div `
  height: fit-content;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: 'CiscoSans Thin';
  font-size: 1.2vw;
  font-weight: 300;
  line-height: 1.6vw;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0.4vw 0.2vw;
`;
export default BuildingName;
