import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getContext } from '../../redux/spaces-slice';
const BrandingContainer = styled.div `
  display: flex;
  align-items: center;
  height: 4.63vh;
  max-height: 4.63vh;
  width: fit-content;
  border-radius: 0.833vw;
  background-color: #1f2226;
  padding: 1.302vw;
`;
const Logo = styled.img `
  display: block;
  max-height: 4.444vh;
`;
export default function BrandLogo() {
    const context = useSelector(getContext);
    const brandingUrl = context.brandingUrl ||
        require('../../../../assets/images/cisco_brand_logo.svg');
    return (_jsx(BrandingContainer, { children: _jsx(Logo, { src: brandingUrl, alt: "Brand Logo" }) }));
}
