import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, memo } from 'react';
import styled from 'styled-components';
import { Flex } from '@magnetic/flex';
import { Constants } from '../../config';
import { getTimerTimeStamp } from '../../util';
import { useTranslation } from 'react-i18next';
const TimerContainer = styled.div `
  border: 4px solid #124167;
  height: fit-content;
  border-radius: 4vw;
  padding: 0.2vw;
`;
const Timer = styled.div `
  --s: 0deg;
  --p: ${(props) => 360 - props.fillingAngle}deg;
  height: 4vw;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #124167;
  mask: conic-gradient(
    from var(--s),
    #0000,
    #000 1deg var(--p),
    #0000 calc(var(--p) + 1deg)
  );
`;
const TimerTimeStamp = styled.div `
  font-size: 1.1vw;
  font-family: 'CiscoSansBold';
  position: fixed;
  margin-top: -2.6vw;
  margin-left: 0.62vw;
`;
const Line = styled.div `
  width: 0.24vw;
  height: 6vw;
  border-radius: 1vw;
  margin-left: 3.12vw;
  background-color: white;
  z-index: 999999999;
  margin-bottom: -5.2vw;
  margin-top: ${(props) => (props.widgetCount <= 1 ? '7vw' : '1vw')};
`;
const ArrowDown = styled.div `
  width: 0;
  height: 0;
  border-left: 1.6vw solid transparent;
  border-right: 1.6vw solid transparent;
  border-top: 2vw solid
    ${(props) => Constants.availabilityColorMap[props.availabilityStatus]};
  margin-left: 1.6vw;
`;
const AvailabilityContainer = styled(Flex) `
  min-width: ${(props) => props.widgetCount === 1
    ? '114%'
    : props.widgetCount === 0
        ? '130%'
        : '72%'};
  max-height: 44vh;
  border: 4px solid
    ${(props) => Constants.availabilityColorMapBorder[props.availabilityStatus]};
  padding: 2.4vw 1.2vw;
  border-radius: 1vw;
  background-color: ${(props) => Constants.availabilityColorMap[props.availabilityStatus]};
  z-index: 999;
`;
const AvailabilityStatusContainer = styled.div `
  height: 20vh;
  max-width: 50%;
  padding-left: 2vw;
`;
const RoomOccupancyContainer = styled.div `
  max-height: 18vh;
  max-width: 40%;
  border: 2px solid
    ${(props) => Constants.availabilityColorMapBorder[props.availabilityStatus]};
  background-color: ${(props) => Constants.occupancyColorMap[props.availabilityStatus]};
  border-radius: 1vw;
  margin-left: ${(props) => props.widgetCount === 1 ? '30%' : props.widgetCount === 0 ? '40%' : '18%'};
  padding: 2vw;
  text-align: center;
  margin-top: ${(props) => (props.widgetCount === 0 ? '-10vw' : '0vw')};
`;
const MeetingInformationContainer = styled.div `
  font-size: 1.8vw;
  padding: 3.2vw 2vw;
  min-width: 24vw;
  max-width: 24vw;
  max-height: 10vh;
`;
const For = styled.div `
  font-family: CiscoSans Thin;
  margin-top: 0.14vw;
`;
const MeetingTitle = styled.div `
  font-weight: 400;
`;
const HeadingText = styled.div `
  font-family: CiscoSans Thin;
  font-size: 2.8vw;
`;
const AvailabilityText = styled.div `
  font-size: 8vw;
`;
const OccupancyText = styled(Flex) `
  font-size: 5.4vw;
  font-family: CiscoSans Thin;
  padding-left: 4%;
`;
const OfText = styled.div `
  font-size: 3vw;
  margin: 2.2vw 3vw 3vw;
  font-family: CiscoSans Thin;
`;
const TimerWidget = ({ fillingAngle }) => {
    return (_jsxs(TimerContainer, Object.assign({ id: "hold-timer", style: { display: 'none' } }, { children: [_jsx(Timer, { fillingAngle: fillingAngle }), _jsx(TimerTimeStamp, { children: getTimerTimeStamp(fillingAngle) })] })));
};
const AvailabilityStatus = ({ t, availabilityStatus }) => {
    return (_jsxs(AvailabilityStatusContainer, { children: [_jsx(HeadingText, { children: t('navigator.this-meeting-room-is') }), _jsx(AvailabilityText, { children: t(Constants.availabilityMapLocalizedNames[availabilityStatus]) })] }));
};
const RoomOccupancy = ({ t, peopleCount, capacity, availabilityStatus, widgetCount, }) => {
    return (_jsxs(RoomOccupancyContainer, Object.assign({ widgetCount: widgetCount, availabilityStatus: availabilityStatus }, { children: [_jsx(HeadingText, { children: t('navigator.room-occupancy') }), _jsxs(OccupancyText, { children: [peopleCount, " ", _jsx(OfText, { children: t('navigator.of') }), " ", capacity] })] })));
};
const MeetingInformation = ({ t, title }) => {
    return (_jsx(MeetingInformationContainer, { children: title && (_jsxs(Flex, { children: [_jsxs(For, { children: [t('navigator.for'), "\u00A0"] }), _jsx(MeetingTitle, { children: title })] })) }));
};
const Availability = memo(({ availabilityStatus, peopleCount, capacity, title, widgetCount, isTimerActive, fillingAngle, setFillingAngle, setIsTimerActive, }) => {
    const { t } = useTranslation();
    useEffect(() => {
        if (!isTimerActive)
            return;
        const interval = setInterval(() => {
            setFillingAngle((prevAngle) => {
                return prevAngle + 2;
            });
        }, 1000);
        const timeout = setTimeout(() => {
            clearInterval(interval);
            setFillingAngle(0);
            setIsTimerActive(false);
            const timerElement = document.getElementById('hold-timer');
            if (timerElement && timerElement.style) {
                timerElement.style.display = 'none';
            }
        }, 180 * 1000);
        return () => {
            clearInterval(interval);
            clearTimeout(timeout);
        };
    }, [isTimerActive]);
    return (_jsxs(Flex, Object.assign({ direction: "vertical", width: "72%" }, { children: [_jsxs(AvailabilityContainer, Object.assign({ direction: "vertical", availabilityStatus: availabilityStatus, widgetCount: widgetCount }, { children: [_jsxs(Flex, Object.assign({ justify: "space-between", style: {
                            maxWidth: widgetCount === 1 ? '78vw' : '64.4vw',
                        } }, { children: [_jsx(AvailabilityStatus, { t: t, availabilityStatus: availabilityStatus }), _jsx(TimerWidget, { fillingAngle: fillingAngle })] })), _jsxs(Flex, { children: [_jsx(MeetingInformation, { t: t, title: title }), _jsx(RoomOccupancy, { t: t, peopleCount: peopleCount, capacity: capacity, availabilityStatus: availabilityStatus, widgetCount: widgetCount })] })] })), _jsx(ArrowDown, { availabilityStatus: availabilityStatus }), _jsx(Line, { widgetCount: widgetCount })] })));
});
export default Availability;
