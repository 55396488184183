var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class CacheService {
    constructor(moduleKey) {
        this.moduleKey = moduleKey;
        this.useLocalStorage = false;
        this.inMemoryCache = {};
        this.domain = '';
        this.CONSTANTS = {
            SIGNAGE_CACHE: 'signage-cache',
        };
        this.useLocalStorage = this.isLocalStorageAvailable(); // Explicitly disabling localStorage for fallback testing
        this.domain = this.getDomainFromURL();
        this.initialize();
    }
    /**
     * Singleton access method for module-specific instances.
     */
    static getInstance(moduleKey) {
        if (!CacheService.instances[moduleKey]) {
            CacheService.instances[moduleKey] = new CacheService(moduleKey);
        }
        return CacheService.instances[moduleKey];
    }
    /*
     * Initializes the cache and preloads all data into inMemoryCache.
     */
    initialize() {
        if (this.useLocalStorage) {
            this.cachePointer = localStorage;
            this.loadFromLocalStorage();
        }
        else {
            // Perform an initial load from the Cache API into inMemoryCache
            this.cachePointer = null; // Placeholder to indicate Cache API is used
            this.preloadCacheData();
        }
    }
    /**
     * Preloads data from localStorage into inMemoryCache.
     */
    loadFromLocalStorage() {
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key.startsWith(this.getNamespacePrefix())) {
                const value = localStorage.getItem(key);
                if (value)
                    this.inMemoryCache[key] = JSON.parse(value);
            }
        }
    }
    /**
     * Preloads data from the Cache API into inMemoryCache.
     * This is synchronous because we prefetch data during initialization.
     */
    preloadCacheData() {
        // Open the cache and preload data synchronously
        const cache = caches.open(this.CONSTANTS.SIGNAGE_CACHE).then((cache) => {
            cache.keys().then((cacheKeys) => {
                cacheKeys.forEach((request) => __awaiter(this, void 0, void 0, function* () {
                    const key = request.url.split('/').pop();
                    const response = yield cache.match(request);
                    const text = yield (response === null || response === void 0 ? void 0 : response.text());
                    this.inMemoryCache[this.buildNamespacedKey(key)] = text
                        ? JSON.parse(text)
                        : null;
                }));
            });
        });
    }
    /**
     * Sets a value in the in-memory cache and the underlying storage.
     */
    set(key, value) {
        const namespacedKey = this.buildNamespacedKey(key);
        this.inMemoryCache[namespacedKey] = value;
        if (this.useLocalStorage) {
            localStorage.setItem(namespacedKey, JSON.stringify(value));
        }
        else {
            // Update the Cache API asynchronously, but it's already in inMemoryCache
            const fakeUrl = `https://${this.domain}/${namespacedKey}`;
            const response = new Response(JSON.stringify(value));
            const request = new Request(fakeUrl);
            caches.open(this.CONSTANTS.SIGNAGE_CACHE).then((cache) => {
                cache
                    .put(request, response)
                    .catch((err) => console.warn('CacheService::Failed to update Cache API:', err));
            });
        }
    }
    /**
     * Gets a value from the in-memory cache synchronously.
     */
    get(key) {
        const namespacedKey = this.buildNamespacedKey(key);
        if (this.inMemoryCache[namespacedKey]) {
            return this.inMemoryCache[namespacedKey];
        }
        if (this.useLocalStorage) {
            const storedValue = localStorage.getItem(namespacedKey);
            if (storedValue) {
                const parsedValue = JSON.parse(storedValue);
                this.inMemoryCache[namespacedKey] = parsedValue;
                return parsedValue;
            }
        }
        return null; // Return null if not found
    }
    /**
     * Deletes a value from the in-memory cache and the underlying storage.
     */
    delete(key) {
        const namespacedKey = this.buildNamespacedKey(key);
        delete this.inMemoryCache[namespacedKey];
        if (this.useLocalStorage) {
            localStorage.removeItem(namespacedKey);
        }
        else {
            const fakeUrl = `https://${this.domain}/${namespacedKey}`;
            const request = new Request(fakeUrl);
            caches.open(this.CONSTANTS.SIGNAGE_CACHE).then((cache) => {
                cache
                    .delete(request)
                    .catch((err) => console.warn('CacheService::Failed to delete from Cache API:', err));
            });
        }
    }
    clearAllClientCache() {
        try {
            // Clear Local Storage
            localStorage.clear();
            console.log('Local Storage cleared.');
            // Clear Session Storage
            sessionStorage.clear();
            console.log('Session Storage cleared.');
            // Asynchronous background tasks for Query Cache and API Cache
            if (window.caches) {
                caches
                    .keys()
                    .then((cacheNames) => {
                    for (const cacheName of cacheNames) {
                        caches.delete(cacheName).then(() => {
                            console.log(`Query/Cache Storage '${cacheName}' cleared.`);
                        });
                    }
                })
                    .catch((err) => {
                    console.error('Error clearing Cache Storage:', err);
                });
            }
            else {
                console.warn('Cache API is not supported in this browser.');
            }
            // API Cache (Service Worker)
            if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
                navigator.serviceWorker.controller.postMessage({ type: 'CLEAR_CACHE' });
                console.log('Service Worker API cache clearing triggered.');
            }
            else {
                console.log('No active Service Worker found.');
            }
            console.log('Synchronous cache clearing complete. Asynchronous tasks running in background.');
        }
        catch (error) {
            console.error('Error clearing client caches:', error);
        }
    }
    /**
     * Checks if localStorage is available in the current environment.
     */
    isLocalStorageAvailable() {
        try {
            const testKey = '__storage_test__';
            localStorage.setItem(testKey, testKey);
            localStorage.removeItem(testKey);
            return true;
        }
        catch (e) {
            console.warn('CacheService::Local Storage is not available. Using Cache API instead.');
            return false;
        }
    }
    /**
     * Gets the domain from the current URL.
     */
    getDomainFromURL() {
        try {
            return new URL(window.location.href).hostname;
        }
        catch (e) {
            console.warn('CacheService::Failed to retrieve domain. Defaulting to "unknown-domain".');
            return 'unknown-domain';
        }
    }
    /**
     * Builds a namespaced key for the cache.
     */
    buildNamespacedKey(key) {
        return `${this.getNamespacePrefix()}:${key.trim()}`;
    }
    /**
     * Constructs the namespace prefix using the domain and module key.
     */
    getNamespacePrefix() {
        return `${this.domain}:${this.moduleKey}`;
    }
}
CacheService.instances = {};
