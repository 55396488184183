import styled from 'styled-components';
const FloorName = styled.div `
  height: fit-content;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: 'CiscoSansRegular';
  font-size: 1.6vw;
  font-weight: 400;
  line-height: 1.6vw;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;
export default FloorName;
