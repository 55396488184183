import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getLocation } from '../../redux/spaces-slice';
function useTime() {
    const locale = 'en-US';
    const [date, setDate] = useState(new Date()); // Save the current date to be able to trigger an update
    const location = useSelector(getLocation);
    const day = date.toLocaleDateString(locale, {
        timeZone: location.zoneId,
        weekday: 'long',
    });
    const currentDate = `${date.toLocaleDateString(locale, { timeZone: location.zoneId, month: 'long' })} ${date.toLocaleDateString(locale, { timeZone: location.zoneId, day: 'numeric' })}`;
    const time = date.toLocaleTimeString(locale, {
        timeZone: location.zoneId,
        hour: 'numeric',
        hour12: !location.use24HrClockStyle,
        minute: 'numeric',
    });
    useEffect(() => {
        const interval = setInterval(() => {
            setDate(new Date());
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    return { time, day, currentDate };
}
export default useTime;
