import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import styled from 'styled-components';
import Header from './components/header';
import Analytics from './components/analytics';
import Availability from './components/availability';
import MeetingsTimeLine from './components/meetings-timeline';
import Buttons from './components/buttons';
import Instructions from './components/instructions';
import NavigatorFooter from './components/footer';
import BookingSlotButtons from './components/booking-slot-buttons';
import BookingSlotTimestamps from './components/booking-slot-timestamps';
import CancelButton from './components/cancel-button';
import { getWidgetCount, toggleNavigatorLedByAvailability } from './util';
import { Flex } from '@magnetic/flex';
import { useSelector } from 'react-redux';
import { getContext } from '../redux/spaces-slice';
import { selectRoomById } from '../redux/spaces-slice';
import { STATUSES } from '../floor/util';
const NavigatorContainer = styled.div `
  width: 98vw;
  height: 98vh;
  font-family: 'CiscoSans', sans-serif;
  // background-color: #111214;
  background-color: black;
`;
export function NavigatorView() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const [bookingSlotActive, setBookingSlotActive] = useState(false);
    const [bookingSlotWidth, setBookingSlotWidth] = useState(0);
    const [bookingSlotEndTime, setBookingSlotEndTime] = useState(Date.now() / 1000);
    const [isTimerActive, setIsTimerActive] = useState(false);
    const [fillingAngle, setFillingAngle] = useState(0);
    const contextInfo = useSelector(getContext);
    const roomName = contextInfo === null || contextInfo === void 0 ? void 0 : contextInfo.roomName;
    const timeZone = contextInfo === null || contextInfo === void 0 ? void 0 : contextInfo.timeZoneId;
    const building = contextInfo === null || contextInfo === void 0 ? void 0 : contextInfo.building;
    const floor = contextInfo === null || contextInfo === void 0 ? void 0 : contextInfo.floor;
    const richMapId = contextInfo === null || contextInfo === void 0 ? void 0 : contextInfo.richMapId;
    const roomAnalytics = useSelector((state) => selectRoomById(state, richMapId));
    const iaq = (_a = roomAnalytics === null || roomAnalytics === void 0 ? void 0 : roomAnalytics.airQuality) !== null && _a !== void 0 ? _a : '';
    const co2 = (_b = roomAnalytics === null || roomAnalytics === void 0 ? void 0 : roomAnalytics.co2) !== null && _b !== void 0 ? _b : '';
    const temperature = (_c = roomAnalytics === null || roomAnalytics === void 0 ? void 0 : roomAnalytics.ambientTemp) !== null && _c !== void 0 ? _c : '';
    const humidity = (_d = roomAnalytics === null || roomAnalytics === void 0 ? void 0 : roomAnalytics.relativeHumidity) !== null && _d !== void 0 ? _d : '';
    const ambientNoise = (_e = roomAnalytics === null || roomAnalytics === void 0 ? void 0 : roomAnalytics.ambientNoise) !== null && _e !== void 0 ? _e : '';
    const availabilityStatus = (_f = roomAnalytics === null || roomAnalytics === void 0 ? void 0 : roomAnalytics.availability) !== null && _f !== void 0 ? _f : '';
    const meetings = (_g = roomAnalytics === null || roomAnalytics === void 0 ? void 0 : roomAnalytics.meetings) !== null && _g !== void 0 ? _g : [];
    // const isHoldable = roomAnalytics?.holdable ?? true;
    // const isBookable = roomAnalytics?.bookable ?? true;
    const isHoldable = true;
    const isBookable = true;
    const peopleCount = (_h = roomAnalytics === null || roomAnalytics === void 0 ? void 0 : roomAnalytics.peopleCount) !== null && _h !== void 0 ? _h : 0;
    const capacity = (_j = roomAnalytics === null || roomAnalytics === void 0 ? void 0 : roomAnalytics.capacity) !== null && _j !== void 0 ? _j : 0;
    const widgetCount = getWidgetCount(iaq, co2, temperature, humidity, ambientNoise);
    toggleNavigatorLedByAvailability(availabilityStatus);
    return (_jsxs(NavigatorContainer, { children: [_jsx(Header, { building: building, floor: floor, room: roomName }), _jsxs(Flex, Object.assign({ justify: "space-between", width: "100%" }, { children: [_jsx(Availability, { availabilityStatus: availabilityStatus, peopleCount: peopleCount, capacity: capacity, 
                        // title={'Daily Scrum Call'}
                        widgetCount: widgetCount, isTimerActive: isTimerActive, fillingAngle: fillingAngle, setFillingAngle: setFillingAngle, setIsTimerActive: setIsTimerActive }), _jsx(Analytics, { iaq: iaq, co2: co2, temperature: temperature, humidity: humidity, ambientNoise: ambientNoise })] })), _jsxs(Flex, { children: [_jsxs(Flex, Object.assign({ direction: "vertical" }, { children: [_jsx(MeetingsTimeLine, { meetings: meetings, availabilityStatus: availabilityStatus, bookingSlotWidth: bookingSlotWidth, timeZone: timeZone }), _jsxs(Flex, Object.assign({ justify: "space-between" }, { children: [_jsx(CancelButton, { show: bookingSlotActive && availabilityStatus === STATUSES.AVAILABLE, setShow: setBookingSlotActive, setBookingSlotWidth: setBookingSlotWidth, setBookingSlotEndTime: setBookingSlotEndTime, widgetCount: widgetCount, availabilityStatus: availabilityStatus }), availabilityStatus === STATUSES.AVAILABLE &&
                                        bookingSlotEndTime - Date.now() / 1000 >= 1 && (_jsx(BookingSlotTimestamps, { end: bookingSlotEndTime, timeZone: timeZone, widgetCount: widgetCount, availabilityStatus: availabilityStatus }))] })), _jsx(NavigatorFooter, {})] })), _jsxs(Flex, Object.assign({ direction: "vertical" }, { children: [_jsx(BookingSlotButtons, { bookingSlotWidth: bookingSlotWidth, setBookingSlotWidth: setBookingSlotWidth, setBookingSlotActive: setBookingSlotActive, meetings: meetings, timeZone: timeZone, bookingSlotEndTime: bookingSlotEndTime, setBookingSlotEndTime: setBookingSlotEndTime, widgetCount: widgetCount, availabilityStatus: availabilityStatus }), _jsx(Instructions, { widgetCount: widgetCount, availabilityStatus: availabilityStatus }), _jsx(Buttons, { isBookable: isBookable, isHoldable: isHoldable, availabilityStatus: availabilityStatus, setIsTimerActive: setIsTimerActive, end: bookingSlotEndTime })] }))] })] }));
}
