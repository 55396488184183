/* eslint-disable no-mixed-operators */
class LensNew {
  constructor(lens, digitalZoom = false) {
    this.lens = lens;
    this.digitalZoom = digitalZoom;
    const fovRad = Math.PI * this.lens.fov / 180.0;
    const hFovRad = fovRad * 0.5;
    this.halfOW = 0.5 * this.lens.width;
    this.halfOH = 0.5 * this.lens.height;
    const halfOD = Math.sqrt(this.halfOW * this.halfOW + this.halfOH * this.halfOH);
    this.focalLengthPixels = halfOD / Math.tan(hFovRad);
  }

  setDigitalZoom(enabled) {
    this.digitalZoom = enabled;
  }

  dewarp(x, y) {
    let nx = x - this.lens.cenX;
    let ny = y - this.lens.cenY;

    if (this.digitalZoom) {
      nx /= 2;
      ny /= 2;
    }

    const fR2 = nx * nx + ny * ny;
    const fR = Math.sqrt(fR2);

    if (fR > 0.001) {
      const invR = 1.0 / fR;
      nx *= invR;
      ny *= invR;
    }

    const ang = fR * (Math.PI * 0.5) / this.lens.pixelRadius90Degrees;
    const rectRadius = Math.tan(ang) * this.focalLengthPixels * this.lens.zoom;

    return [this.halfOW + nx * rectRadius, this.halfOH + ny * rectRadius];
  }

  warp(x, y) {
    let nx = x - this.halfOW;
    let ny = y - this.halfOH;
    const fR2 = nx * nx + ny * ny;
    const fR = Math.sqrt(fR2);

    if (fR > 0.001) {
      const invR = 1.0 / fR;
      nx *= invR;
      ny *= invR;
    }

    const rectAng = Math.atan(fR / (this.focalLengthPixels * this.lens.zoom));
    const fishRadius = rectAng * this.lens.pixelRadius90Degrees / (Math.PI * 0.5);

    if (this.digitalZoom) {
      nx *= 2;
      ny *= 2;
    }

    return [this.lens.cenX + nx * fishRadius, this.lens.cenY + ny * fishRadius];
  }

  // eslint-disable-next-line no-unused-vars
  getpixelheadsize(fishvgaradius, mountheight, avgheightoftrackedpoint, headsize) {
    return 10;
  }
}

export default LensNew;
