/* eslint-disable max-len */
/* eslint-disable no-mixed-operators */
class Lens {
  constructor(lens) {
    this.lens = lens;
    let i;
    let j;
    this.aiRadiusRectToFisheye = new Array(8192);
    this.aiRadiusFisheyeToRect = new Array(8192);

    const factor = ((Math.tan(lens.fov * 0.5) * (lens.fcx_init + lens.fcy_init)) /
      (Math.sqrt(lens.calibration_sizehx * lens.calibration_sizehx + lens.calibration_sizevy * lens.calibration_sizevy) * Math.sqrt(lens.fcx_init * lens.fcy_init)));

    for (j = 0; j < 8192; j += 1) {
      this.aiRadiusFisheyeToRect[j] = -1;
    }

    for (i = 0; i < 8192; i += 1) {
      let r = i * 400.0 / 4096.0;
      r *= factor;
      if (r > 0.0) {
        const theta = Math.atan(r);
        const theta2 = theta * theta;
        const theta4 = theta2 * theta2;
        const thetaD = theta / r * (1 + lens.kc[0] * theta2 + lens.kc[1] * theta4 + lens.kc[2] * theta2 * theta4 + lens.kc[3] * theta4 * theta4);
        r *= thetaD * Math.sqrt(lens.fcx_init * lens.fcy_init);
      }

      j = Math.floor(r * 4096.0 / 400.0 + 0.5);
      this.aiRadiusRectToFisheye[i] = j;
      this.aiRadiusFisheyeToRect[j] = i;
    }
    i = -1;
    for (j = 0; j < 8192; j += 1) {
      if (this.aiRadiusFisheyeToRect[j] > i) i = this.aiRadiusFisheyeToRect[j];
      this.aiRadiusFisheyeToRect[j] = i;
    }
  }

  dewarp(x, y) {
    let dx = x - 320;
    let dy = y - 240;

    const fishr = Math.sqrt(dx * dx + dy * dy);
    if (fishr > 0) {
      const fishrIndex = Math.floor(fishr * 4096.0 / 400.0 + 0.5);
      const rectrTndex = this.aiRadiusFisheyeToRect[fishrIndex];
      if (rectrTndex === 8191) return [null, null]; // If outside "180 degree circle"
      const rectr = rectrTndex * 400.0 / 4096.0;
      const factor = rectr / fishr;
      dx *= factor;
      dy *= factor;
    }
    return [dx + 320, dy + 240];
  }

  warp(x, y) {
    let dx = x - 320;
    let dy = y - 240;

    const rectr = Math.sqrt(dx * dx + dy * dy);
    if (rectr > 0) {
      const rectrTndex = Math.floor(rectr * 4096.0 / 400.0 + 0.5);
      const fishrIndex = this.aiRadiusRectToFisheye[rectrTndex];
      const fishr = fishrIndex * 400.0 / 4096.0;
      const factor = fishr / rectr;
      dx *= factor;
      dy *= factor;
    }
    return [dx + 320, dy + 240];
  }

  getpixelheadsize(fishvgaradius, mountheight, avgheightoftrackedpoint, headsize) {
    const rfish = fishvgaradius / 400.0;
      // Translate to normalized distance in fisheye image space
    const rrect = this.aiRadiusFisheyeToRect[(Math.floor(rfish * 4096.0))] / 4096.0;
      // Perpendicular distance in cm from ceiling plane to midpoint of head.
    const h = mountheight - avgheightoftrackedpoint;
      // Perpendicular distance in cm from optical axis to midpoint of head
    const x = h * Math.tan(this.lens.fov * 0.5) * rrect;

    const d = Math.sqrt(h * h + x * x);
    const alpha = Math.atan(x / h);
    const beta = Math.asin(headsize * 0.5 / d);

    const xmin = h * Math.tan(alpha - beta);
    const xmax = h * Math.tan(alpha + beta);
    const rrectmin = xmin / (h * Math.tan(this.lens.fov * 0.5));
    const rrectmax = xmax / (h * Math.tan(this.lens.fov * 0.5));
    const signrrectmin = rrectmin < 0.0 ? -1.0 : 1.0;
    const rfishmin = signrrectmin * this.aiRadiusRectToFisheye[(Math.floor(signrrectmin * rrectmin * 4096.0))] / 4096.0;
    const rfishmax = this.aiRadiusRectToFisheye[(Math.floor(rrectmax * 4096.0))] / 4096.0;
    let iSize = Math.floor((rfishmax - rfishmin) * 400.0);
    if (iSize < 10) iSize = 10;
    return iSize;
  }
  }

export default Lens;
