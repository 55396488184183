import styled from 'styled-components';
import { Flex } from '@magnetic/flex';
import { pxToVh, pxToVw } from '../../../../common/util';
/**
 * A styled component that serves as a container for widgets.
 *
 * @component
 * @example
 * // Usage example:
 * <WidgetContainer height={50} width={100}>
 *   { Child components }
 * </WidgetContainer>
 *
 * @param {Object} props - The properties object.
 * @param {number} props.height - The height of the container in viewport height units.
 * @param {number} props.width - The width of the container in viewport width units.
 *
 * @returns {JSX.Element} A styled container component.
 *
 * @styled
 * @property {string} background-color - The background color of the container.
 * @property {string} background - The background of the container.
 * @property {string} height - The height of the container.
 * @property {string} max-height - The maximum height of the container.
 * @property {string} width - The width of the container.
 * @property {string} max-width - The maximum width of the container.
 * @property {string} border-radius - The border radius of the container.
 * @property {string} padding - The padding inside the container.

 */
export const WidgetContainer = styled(Flex) `
  background-color: ${(props) => (props.color ? props.color : '#1d2023')};
  background: ${(props) => (props.color ? props.color : '#1d2023')};
  height: ${(props) => pxToVh(props.height)};
  max-height: ${(props) => pxToVh(props.height)};
  width: ${(props) => pxToVw(props.width)};
  max-width: ${(props) => pxToVw(props.width)};
  border-radius: 16px;
  padding: ${(props) => (props.padding ? pxToVw(props.padding) : '2px')};
  overflow-y: ${(props) => (props.overflowy ? 'auto' : 'intial')};
`;
export const CustomeMask = styled.div `
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 30px 0;

  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  background-position: center bottom;
  background-size: 100px 100px;
  background-repeat: repeat-x;
`;
