import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Flex } from '@magnetic/flex';
import FloorGoTo from '../../../../common/images/floor-goto';
import { WidgetContainer } from '../../common/styled-widget';
import { pxToVw, pxToVh } from '../../../../common/util';
import { getLocation } from '../../redux/spaces-slice';
import { setSelectedLocation } from '../../redux/spaces-slice';
export const Text = styled.div `
  font-family: 'CiscoSansRegular';
  font-size: 20px;
  line-height: 2vw;
  height: 2vw;
  word-wrap: break-word;
`;
const SingleFloor = ({ fullHeight }) => {
    var _a, _b;
    const locationData = useSelector(getLocation);
    const floorIď = (_b = (_a = locationData.children) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.id;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const navigateToFloor = (floorProps) => () => {
        const id = floorProps.floor;
        console.log('Navigate to floor', id);
        if (id !== null || id !== -1) {
            dispatch(setSelectedLocation(id.toString()));
            navigate('/floor', { state: { id } });
        }
    };
    const SingleFloorHorizontal = (floor) => {
        return (_jsxs(WidgetContainer, Object.assign({ width: 530, height: 223, Justify: "space-between" }, { children: [_jsx("img", { src: require('../../../../assets/images/floor-single.png'), alt: "Floor Rich Map Icon", style: { width: `'${pxToVw(223)}'`, height: `'${pxToVh(215)}'` } }), _jsxs(Flex, Object.assign({ direction: "verticle", align: "flex-end", justify: "space-between", gap: 24, grow: true, style: { padding: '24px' } }, { children: [_jsx(Text, Object.assign({ style: { width: '250px' } }, { children: "View interactive map, find available conference rooms and more..." })), _jsx(Flex, Object.assign({ onClick: navigateToFloor(floor) }, { children: _jsx(FloorGoTo, {}) }))] }))] })));
    };
    const SingleFloorVertical = (floor) => {
        return (_jsxs(WidgetContainer, Object.assign({ direction: "verticle", width: 530, height: 753, justify: "space-between" }, { children: [_jsxs(Flex, Object.assign({ direction: "verticle", gap: 24, style: { padding: '24px' } }, { children: [_jsx("img", { src: require('../../../../assets/images/floor-single.png'), alt: "Floor Rich Map Icon", style: {
                                width: `'${pxToVw(223)}'`,
                                height: `'${pxToVh(379)}'`,
                                maxHeight: '379px',
                            } }), _jsx(Text, Object.assign({ style: { width: '400px' } }, { children: "View interactive map, find available conference rooms and more..." }))] })), _jsx(Flex, Object.assign({ gap: 24, style: { padding: '24px' }, reverse: "true", onClick: navigateToFloor(floor) }, { children: _jsx(FloorGoTo, {}) }))] })));
    };
    return fullHeight ? (_jsx(SingleFloorVertical, { floor: floorIď })) : (_jsx(SingleFloorHorizontal, { floor: floorIď }));
};
export default SingleFloor;
