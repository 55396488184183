export const FLOOR_STATUS_COLOR = {
    'VERY-LOW': '#82CF5F',
    LOW: '#59A83D',
    BUSY: '#FFEC42',
    'VERY-BUSY': '#FEAF1D',
    CROWDED: '#FF5A36',
    FULL: '#6B32CA',
    'NO-DATA': '#445357',
};
export const YOU_ARE_HERE = 'You Are Here';
