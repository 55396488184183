import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import WorkSpacesLogoSVG from '../../assets/images/workspaces-logo.svg';
import { useTranslation } from 'react-i18next';
import { CenteredDiv, Motto, PoweredBy, Spinner, ScalableImage } from '../styles/commonstyles';
// Loader component
const Loader = () => {
    const { t } = useTranslation();
    useEffect(() => {
    }, []);
    return (_jsxs(CenteredDiv, { children: [_jsx(Spinner, {}), _jsx("img", { src: WorkSpacesLogoSVG }), _jsx("br", {}), _jsx(Motto, { children: t('common.smartworkspaces.moto') }), _jsx("br", {}), _jsx("br", {}), _jsx(PoweredBy, { children: _jsx(ScalableImage, { src: require('../../assets/images/footer.png'), alt: "Powered by Cisco Spaces" }) })] }));
};
export default Loader;
