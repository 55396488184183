var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Constants } from '../models/constants';
import { CacheService } from '../../../common/util/cacheservice';
export class DataService {
    constructor() {
        this.setCacheHandler = (cacheHandler) => __awaiter(this, void 0, void 0, function* () {
            this.cacheHandler = cacheHandler;
        });
        this.getCacheHandler = () => {
            return this.cacheHandler;
        };
        this.getActivationCode = () => {
            return this.getCacheHandler().get(Constants.CACHE.CODE);
        };
        this.setActivationCode = (code) => {
            this.getCacheHandler().set(Constants.CACHE.CODE, code);
        };
        this.setTenantIdMasked = (tenantIdMasked) => {
            this.getCacheHandler().set(Constants.CACHE.TENANT_ID_MASKED, tenantIdMasked);
        };
        this.getTenantIdMasked = () => {
            return this.getCacheHandler().get(Constants.CACHE.TENANT_ID_MASKED);
        };
        this.setDeviceId = (deviceId) => {
            this.getCacheHandler().set(Constants.CACHE.DEVICE_ID, deviceId);
        };
        this.getDeviceId = () => {
            return this.getCacheHandler().get(Constants.CACHE.DEVICE_ID);
        };
        this.setIsNavigator = (isNavigator) => {
            this.getCacheHandler().set(Constants.CACHE.IS_NAVIGATOR, isNavigator);
        };
        this.getIsNavigator = () => {
            return this.getCacheHandler().get(Constants.CACHE.IS_NAVIGATOR);
        };
        this.setNavigatorParamsExtract = (navigatorParamsExtract) => {
            this.getCacheHandler().set(Constants.CACHE.NAVIGATOR_PARAMS_EXTRACT, navigatorParamsExtract);
        };
        this.getNavigatorParamsExtract = () => {
            return this.getCacheHandler().get(Constants.CACHE.NAVIGATOR_PARAMS_EXTRACT);
        };
        this.setOrgId = (orgId) => {
            this.getCacheHandler().set(Constants.CACHE.ORG_ID, orgId);
        };
        this.getOrgId = () => {
            return this.getCacheHandler().get(Constants.CACHE.ORG_ID);
        };
        this.setActivated = (activationStatus) => {
            return this.getCacheHandler().set(Constants.CACHE.ACTIVATED, activationStatus.toString());
        };
        this.isActivated = () => {
            var _a;
            return (((_a = this.getCacheHandler().get(Constants.CACHE.ACTIVATED)) === null || _a === void 0 ? void 0 : _a.toLowerCase()) ===
                'true');
        };
        this.setFrameUrl = (frameUrl) => {
            // Setting in both '/t/frameUrl' and '/frameUrl'
            return this.getCacheHandler().set(Constants.CACHE.FRAME_URL, frameUrl);
        };
        this.getFrameUrl = () => {
            return this.getCacheHandler().get(Constants.CACHE.FRAME_URL);
        };
        this.setRMSToken = (token) => {
            return this.getCacheHandler().set(Constants.CACHE.RMS_TOKEN, token);
        };
        this.getRMSToken = () => {
            return this.getCacheHandler().get(Constants.CACHE.RMS_TOKEN);
        };
        this.setDeviceToken = (token) => {
            return this.getCacheHandler().set(Constants.CACHE.TOKEN, token);
        };
        this.getDeviceToken = () => {
            return this.getCacheHandler().get(Constants.CACHE.TOKEN);
        };
        this.setDeviceName = (name) => {
            return this.getCacheHandler().set(Constants.CACHE.DEVICE_NAME, name);
        };
        this.getDeviceName = () => {
            return this.getCacheHandler().get(Constants.CACHE.DEVICE_NAME);
        };
        this.setDeviceType = (type) => {
            return this.getCacheHandler().set(Constants.CACHE.DEVICE_TYPE, type);
        };
        this.getDeviceType = () => {
            return this.getCacheHandler().get(Constants.CACHE.DEVICE_TYPE);
        };
        this.setUid = (uid) => {
            return this.getCacheHandler().set(Constants.CACHE.UID, uid);
        };
        this.getUid = () => {
            return this.getCacheHandler().get(Constants.CACHE.UID);
        };
        this.clearAllClientCache = () => {
            return this.getCacheHandler().clearAllClientCache();
        };
        this.setDomain = (domain) => {
            return this.getCacheHandler().set(Constants.CACHE.DOMAIN, domain);
        };
        this.getDomain = () => {
            return this.getCacheHandler().get(Constants.CACHE.DOMAIN);
        };
        this.cacheHandler = CacheService.getInstance(Constants.CACHE_MODULE);
    }
}
