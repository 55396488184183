import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
export default function WebGLModal() {
    return (_jsx(Container, { children: _jsx(ModalContent, { children: _jsx(ModalMessage, { children: "Please enable WebGL plugin on this browser and refresh this screen." }) }) }));
}
const Container = styled.div `
  background-color: rgba(0, 0, 0, 0.7);
  height: 100vh;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  overflow: auto;
`;
const ModalContent = styled.div `
  background-color: #2b3746;
  margin: 5% auto;
  padding: 2vw;
  width: 80%;
  border: 4px solid #00bceb;
  box-sizing: border-box;
  box-shadow: 0px 0.5vw 1.5vw 1vw rgba(255, 255, 255, 0.1);
  border-radius: 1vw;
`;
const ModalMessage = styled.div `
  margin: 2vw;
  font-size: 3vw;
  display: inline-block !important;
`;
