var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import { store } from '../../../../store/store';
import { setSmartRooms } from '../../../../store/uislice';
export const STATUSES = {
    AVAILABLE: 'Available',
    BOOKED: 'Booked',
    OCCUPIED: 'Occupied',
    OCCUPIED_BOOKED: 'Occupied',
    HOLD: 'Hold',
};
export const STATUS_COLOR_MAP = {
    Available: '#71FF31',
    Booked: '#FF8F54',
    Occupied: '#FCA5B8',
    Occupied_booked: '#FCA5B8',
    Hold: '#2196f3',
};
export function vwToPixels(vw) {
    return (vw / 100) * window.innerWidth;
}
export function compareName(room1, room2) {
    const name1 = room1.name.toUpperCase();
    const name2 = room2.name.toUpperCase();
    let comparison = 0;
    if (name1 > name2) {
        comparison = 1;
    }
    else if (name1 < name2) {
        comparison = -1;
    }
    return comparison;
}
export const available = '#4D7E3A';
export const availableBorder = '#71FF31';
export const booked = '#8B390E';
export const bookedBorder = '#FF8F54';
export const occupied = '#A5314B';
export const occupiedBorder = '#FCA5B8';
export const noData = '#1f2226';
export const noDataBorder = '#678087';
export const hold = '#2196f3';
export const holdBorder = '#00bceb';
export const getBgColorByStatus = (status = '') => {
    if (status === STATUSES.AVAILABLE)
        return available;
    if (status === STATUSES.BOOKED)
        return booked;
    if (status === STATUSES.OCCUPIED)
        return occupied;
    if (status === STATUSES.OCCUPIED_BOOKED)
        return occupied;
    if (status === STATUSES.HOLD)
        return hold;
    return noData;
};
export const getBorderColorByStatus = (status = '') => {
    if (status === STATUSES.AVAILABLE)
        return availableBorder;
    if (status === STATUSES.BOOKED)
        return bookedBorder;
    if (status === STATUSES.OCCUPIED)
        return occupiedBorder;
    if (status === STATUSES.OCCUPIED_BOOKED)
        return occupiedBorder;
    if (status === STATUSES.HOLD)
        return holdBorder;
    return noDataBorder;
};
export const availabilityStatusColorMap = {
    Available: '#6abf4b',
    Occupied: '#a5314b',
    Booked: '#8f542a',
    Hold: '#2196f3',
    Unknown: '',
};
export const availabilityStatusBorderMap = {
    Available: '#71ff31',
    Occupied: '#fCa5b8',
    Booked: '#f08130',
    Hold: '#00bceb',
    Unknown: '#1d2023',
};
export const POI_TYPES = [
    'Rest Rooms',
    'Cafeteria',
    'Help Desk',
    'Child Care Area',
    'Stairs',
    'Elevators',
];
export const POIS_MAP = {
    'Rest Rooms': 'toilets',
    Cafeteria: 'restaurant',
    'Help Desk': 'help-desk',
    'Child Care Area': 'child-care',
    Stairs: 'stairs',
    Elevators: 'lift',
};
export const POI_TYPES_ORIGINAL = [
    'toilets',
    'restaurant',
    'help-desk',
    'child-care',
    'stairs',
    'lift',
];
export const smartRoomsCall = (tenantId) => __awaiter(void 0, void 0, void 0, function* () {
    const urlString = `https://ciscospaces-workspace.s3.amazonaws.com/smart_rooms/${tenantId}/${tenantId}.json`;
    yield axios({ url: urlString, method: 'GET' })
        .then((res) => {
        store.dispatch(setSmartRooms({ payload: res.data }));
    })
        .catch((error) => {
        store.dispatch(setSmartRooms({ payload: ['error'] }));
    });
});
export const getFloorNumberIdMap = (locationData) => {
    const floorNumberIdMap = {};
    for (const floorNumber of locationData === null || locationData === void 0 ? void 0 : locationData.children) {
        floorNumberIdMap[floorNumber.floorNumber] = floorNumber.id;
    }
    return floorNumberIdMap;
};
export const floorStatusByPercentage = (percentage) => {
    if (percentage < 20)
        return 'Very Low';
    if (percentage < 40)
        return 'Low';
    if (percentage < 60)
        return 'Busy';
    if (percentage < 80)
        return 'Very Busy';
    if (percentage >= 80)
        return 'Crowded';
    return 'no-data';
};
export const getOccupancyStatus = (peopleCount, capacity) => {
    const occupancyPercentage = (peopleCount * 100) / capacity;
    const percentage = peopleCount <= capacity ? occupancyPercentage : 100;
    const floorStatus = floorStatusByPercentage(occupancyPercentage);
    return floorStatus;
};
