import styled from 'styled-components';
const Modal = styled.div `
  background-color: #000;
  height: 100vh;
  opacity: 1;
  position: fixed;
  z-index: 9999999999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.7);
  display: flex !important;
`;
export default Modal;
