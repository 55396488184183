import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { workspaceApiSlice } from '../app/workspaces/service/api';
import { signageApiSlice } from '../app/configure/service/api';
import { natsSlice } from './natsslice';
import { UISlice } from './uislice';
import configureReducer from './configureslice';
import { spacesSlice } from '../app/workspaces/redux/spaces-slice';
import configureWebsocketMiddleware from './configuremiddleware';
export const store = configureStore({
    reducer: {
        // other reducers if any
        [signageApiSlice.reducerPath]: signageApiSlice.reducer,
        [workspaceApiSlice.reducerPath]: workspaceApiSlice.reducer,
        [natsSlice.reducerPath]: natsSlice.reducer,
        [UISlice.reducerPath]: UISlice.reducer,
        [spacesSlice.reducerPath]: spacesSlice.reducer,
        configure: configureReducer,
        spaces: spacesSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: ['API', 'OTHER_ACTION_TYPE_TO_IGNORE'],
            ignoredActionPaths: ['payload.onSuccess', 'payload.onError'],
            ignoredPaths: [],
        },
    }).concat([
        signageApiSlice.middleware,
        workspaceApiSlice.middleware,
        configureWebsocketMiddleware,
    ]),
});
setupListeners(store.dispatch);
