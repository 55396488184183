import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState, useEffect } from 'react';
import { DataService } from '../../../configure/service/deviceservice';
const generateMapUrl = (filename, type) => {
    const dataService = new DataService();
    const lang = navigator.language;
    console.log('Language:', lang);
    const domainParam = dataService.getDomain();
    console.log('Domain parameter:', domainParam);
    let domainToUse = 'dev-dnaspaces.io';
    if (domainParam !== 'localhost' && domainParam !== '127.0.0.1') {
        domainToUse = domainParam.split('.')[1] + '.' + domainParam.split('.')[2];
    }
    console.log('domainToUse for rms :: ', domainToUse);
    const hostname = window.location.hostname;
    console.log('Hostname:', hostname);
    const endpointUrl = domainParam || hostname;
    console.log('Endpoint URL:', endpointUrl);
    let domainName = 'dev-dnaspaces.io';
    const matches = endpointUrl.match(/^(?:.*?\.)?([a-zA-Z0-9\-_]{3,}\.(?:\w{2,8}|\w{2,4}\.\w{2,4}))$/);
    if (matches &&
        matches.length > 1 &&
        !endpointUrl.includes('webex-api-server-test')) {
        domainName = matches[1];
        console.log('Domain Name:', domainName);
    }
    const richMapUrl = `//rms.${domainToUse}/${type}/${filename}`;
    console.log('Rich Map URL:', richMapUrl);
    return richMapUrl;
};
const loadMapScript = (url) => {
    return new Promise((resolve, reject) => {
        const dyn_spt_ele = document.createElement('script');
        dyn_spt_ele.setAttribute('src', url);
        dyn_spt_ele.setAttribute('type', 'text/javascript');
        dyn_spt_ele.setAttribute('async', 'true');
        // Trying to capture more details for script error
        dyn_spt_ele.setAttribute('crossorigin', 'anonymous');
        document.body.appendChild(dyn_spt_ele);
        // success event
        dyn_spt_ele.addEventListener('load', () => {
            console.log('Mapbox GL JS script loaded.');
            resolve();
        });
        // error event
        dyn_spt_ele.addEventListener('error', (event) => {
            console.log('Error loading Mapbox GL JS script.', event);
            reject(event);
        });
    });
};
const loadCssFile = (url) => {
    return new Promise((resolve, reject) => {
        const linkElement = document.createElement('link');
        linkElement.setAttribute('href', url);
        linkElement.setAttribute('rel', 'stylesheet');
        linkElement.setAttribute('type', 'text/css');
        document.head.appendChild(linkElement);
        // success event
        linkElement.addEventListener('load', () => {
            console.log('CSS file loaded.');
            resolve();
        });
        // error event
        linkElement.addEventListener('error', (event) => {
            console.log('Error loading CSS file.', event);
            reject(event);
        });
    });
};
const MapContext = createContext(undefined);
export const MapProvider = ({ children }) => {
    const [mapjs, setMapjs] = useState(null);
    useEffect(() => {
        console.log('MapProvider mounted');
        loadCssFile(generateMapUrl('mapbox-gl.css', 'css'))
            .then(() => {
            console.log('Mapbox GL CSS file loaded.');
            return loadMapScript(generateMapUrl('mapbox-gl.js', 'js'));
        })
            .then(() => {
            console.log('Mapbox GL JS script loaded.');
            const mapUrl = generateMapUrl('rich-maps-0.1.3.2.js', 'js');
            return loadMapScript(mapUrl);
        })
            .then(() => {
            console.log('Loaded Rich Map JS script');
            setMapjs(new Date().toISOString());
            // const newMapInit = {
            //   mapContainer: 'map-v2',
            //   token: richMapAccessToken?.accessToken,
            //   tenantId: mapDetails.map.tenantId,
            //   locationId: mapDetails.map.buildingId,
            //   initialZoom: mapDetails.map.zoom,
            //   initialPos: [mapDetails.map.long, mapDetails.map.lat],
            //   initialPitch: mapDetails.map.pitch,
            //   initialBearing: mapDetails.map.bearing,
            //   defaultFloor: mapDetails.map.floorNumber,
            //   poiLegendHolder: 'poi-switch',
            //   hideNavigationControls: true,
            //   // theme: (window as any).rMap.Themes[theme],
            //   useTiles: false,
            //   ...richMapAccessToken,
            // };
            // initializeRichMap(newMapInit);
        })
            .catch((error) => {
            console.error('Failed to load scripts or CSS:', error);
        });
        // const loadMap = async () => {
        //   // const { RichMap } = await import('path/to/richmap');
        //   const mapInstance = new RichMap(mapInit);
        //   setMap(mapInstance);
        // };
        // loadMap();
    }, []);
    return (_jsx(MapContext.Provider, Object.assign({ value: { mapjs, setMapjs } }, { children: children })));
};
export const useMap = () => {
    const context = useContext(MapContext);
    if (!context) {
        throw new Error('useMap must be used within a MapProvider');
    }
    return context;
};
