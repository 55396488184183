import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import styled from 'styled-components';
import { Flex } from '@magnetic/flex';
import BrandLogo from '../../../common/brand-logo';
import TimeWeather from '../../../common/time-weather';
const HeaderContainer = styled(Flex) `
  height: 12vh;
`;
const TimeWeatherContainer = styled.div `
  height: fit-content;
  z-index: 999;
  margin-right: 1.4vw;
`;
const Name = ({ building, floor, room }) => {
    return (_jsxs(Flex, Object.assign({ direction: "vertical", style: { margin: '0vw 1vw' } }, { children: [_jsx("div", Object.assign({ style: { fontSize: '1.6vw', fontFamily: 'CiscoSans Thin' } }, { children: `${building} > ${floor}` })), _jsx("div", Object.assign({ style: { fontSize: '3vw', fontFamily: 'CiscoSans' } }, { children: room }))] })));
};
const Header = memo(({ building, floor, room }) => {
    return (_jsxs(HeaderContainer, Object.assign({ justify: "space-between" }, { children: [_jsxs(Flex, { children: [_jsx(BrandLogo, {}), _jsx(Name, { building: building, floor: floor, room: room })] }), _jsx(TimeWeatherContainer, { children: _jsx(TimeWeather, {}) })] })));
});
export default Header;
