import styled, { css } from 'styled-components';
import smartworkspacesBgImage from '../../../../assets/images/sample-meetings.svg';
export const DisplayCodeActivation = styled.div `
  width: 100%;
  height: 100vh;
  background: url(${smartworkspacesBgImage}) center center / cover no-repeat; 
  display: flex;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  justify-content: space-between; /* Space between the two columns */
  padding: 2rem; /* Adds padding for spacing */
  box-sizing: border-box;
  font-weight: 200;
  font-size: 1.4vw; /* Base font size that scales with viewport width */

  @media (max-width: 768px) {
    flex-direction: column; /* Stack columns vertically on smaller screens */
    align-items: center; /* Align items in the center horizontally */
  }
`;
// Left column containing the steps
export const DisplayCodeSteps = styled.div `
  width: 45%;
  margin-top: 6rem;
  margin-right: 2vw;
  text-align: left;

  .display-code-follow {
    font-size: 2rem;
    line-height: 2rem;
    color: #ffffff;
  }

  ol {
    list-style: none;
    font-size: 2rem;
    line-height: 4rem;
    color: #ffffff;
    padding: 0;
    margin: 0;
    counter-reset: li;
    position: relative;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    word-wrap: break-word; /* Ensures text wraps correctly */
    overflow-wrap: break-word; /* Prevents long words from overflowing */
    white-space: normal; /* Makes sure text breaks correctly in list items */
    max-width: 100%; /* Prevent text from going out of bounds */
    
    li {
      padding-top: 1rem;
      padding-left: 4rem;
      position: relative;
      overflow-wrap: break-word; /* Makes sure text wraps inside li */

      .alink {
        font-size: 1.5rem;
        line-height: 2.25rem;
        color: #00bceb;
        text-decoration: none;
      }

      &::before {
        content: counter(li);
        counter-increment: li;
        position: absolute;
        left: 0;
        height: 2rem;
        width: 2rem;
        margin-top: 1rem;
        line-height: 2rem;
        border: 0.15rem solid #fff;
        text-align: center;
        font-weight: 200;
        font-size: 1.4rem;
        border-radius: 50%;
        transition: all 0.3s ease-out;
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%; /* Full width for the left column on small screens */
    margin-top: 2rem; /* Adjust top margin on small screens */
    font-size: 1.5rem; /* Smaller font for the list items on small screens */
    line-height: 2.5rem; /* Adjust line height for small screens */
    padding-left: 1rem; /* Padding adjustment for better spacing */
  }
`;
export const DisplayCodeDisplay = styled.div `
  margin-top: 12rem;
  ${({ $signage_activated }) => $signage_activated === 'true'
    ? css `
          margin-top: 20%;
        `
    : css `
          padding-left: 10rem;
          text-align: left;
        `}

  .display-code-header {
    font-size: 2.45rem;
    line-height: 2.75rem;
    font-weight: 700;
    color: #ffffff;
  }

  .display-code-code {
    font-size: 15rem;
    font-family: Arial, sans-serif;
    color: #e43978;
    min-height: 1.2em;
  }

  .display-code-workspaces-logo {
    font-weight: 850;
    color: #00bceb;
    line-height: 5rem;
    font-size: 3.27rem;
    display: flex;
    justify-content: center;
    width: 100%; /* Ensure logo takes full width */
    max-width: 100%; /* Prevent it from overflowing the container */
    
    svg {
      width: 100%; /* Ensures the SVG scales with its container */
      height: auto; /* Keeps aspect ratio */
      max-width: 100%; /* Prevent SVG from exceeding the container */
    }
  }

  .display-code-logo-text {
    color: #ffffff;
    line-height: 2rem;
    font-size: 1.51rem;
    margin-top: 1rem;
    font-weight: 400;
  }

  @media (max-width: 768px) {
    margin-top: 2rem;
    padding-left: 0; /* Remove left padding on small screens */
    text-align: center; /* Center the text */
    order: -1; /* Moves this column to the top on smaller screens */
    width: 100%; /* Full width for the right column on small screens */
    font-size: 1.8rem; /* Smaller font size for responsiveness */
    
    .display-code-header {
      font-size: 2rem; /* Adjust header font size on small screens */
    }

    .display-code-code {
      font-size: 6rem; /* Adjust font size for smaller screens */
    }

    .display-code-workspaces-logo {
      margin-left: 0; /* Reset margin for scaling */
      font-size: 2rem; /* Adjust logo font size for smaller screens */
    }
  }
`;
export const Footer = styled.div `
  position: fixed;
  bottom: 0;
  left: 35%;
  padding: 10px;
  z-index: 1000; /* Ensure it's above other elements */
`;
// Media query for responsiveness
export const ResponsiveContent = styled.div `
  @media (max-width: 768px) {
    ${DisplayCodeActivation} {
      flex-direction: column; /* Stack columns vertically on smaller screens */
      align-items: center; /* Align items in the center horizontally */
    }

    ${DisplayCodeSteps}, ${DisplayCodeDisplay} {
      width: 100%; /* Full width for each column */
      margin: 0; /* Remove margins */
    }

    .display-code-header {
      font-size: 1.8rem; /* Adjust font size for smaller screens */
    }

    .display-code-code {
      font-size: 6rem; /* Adjust font size for smaller screens */
    }

    .display-code-follow {
      font-size: 1.25rem;
    }

    ol {
      font-size: 1.5rem; /* Smaller font size for the ordered list on small screens */
    }
  }
`;
export const VerticalLine = styled.div `
  width: 4px; /* Thickness of the line */
  background-color: #ede7e7; /* Color of the line */
  left: 50%; /* Centered horizontally in the viewport */
  transform: translateX(-50%); /* Align the center of the line */
  margin-top: 6%;
  margin-right: 10px;
  margin-left: 10px;
  height: 80%;    
`;
