import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { Flex } from '@magnetic/flex';
import TimeWeather from '../time-weather';
import BrandLogo from '../brand-logo';
import { Skeleton } from '@magnetic/skeleton';
const GreetingText = styled.span `
  font-family: 'CiscoSansRegular';
  font-size: 34px;
  font-size: 1.771vw;
  line-height: 48px;
  line-height: 4.444vh;
`;
export const LevelText = styled.span `
  font-family: 'CiscoSans Thin';
  font-size: 3vw;
  line-height: 3vw;
  width: 1000px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 3.2vw;
`;
/**
 * Header component that displays a greeting message, a logo, and a title.
 * It also includes a widget container that shows the current time and weather.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.title - The title to be displayed in the header.
 *
 * @returns {JSX.Element} The rendered Header component.
 */
export function Header({ title }) {
    return (_jsxs(Flex, Object.assign({ gap: 24 }, { children: [_jsxs(Flex, Object.assign({ direction: "verticle", grow: true, basis: 1250, justify: "center" }, { children: [_jsx(GreetingText, { children: "Welcome To:" }), _jsxs(Flex, Object.assign({ align: "center", gap: 24 }, { children: [_jsx(BrandLogo, {}), title.length > 0 ? (_jsx(LevelText, { children: title })) : (_jsx(Skeleton, { height: "sm", style: {
                                    width: '40%',
                                }, shade: "regular" }))] }))] })), _jsx(Flex, Object.assign({ grow: true, weight: 1, justify: "flex-end" }, { children: _jsx(TimeWeather, {}) }))] })));
}
