import { useSelector } from 'react-redux';
import { getContext, getFloorAnalytics, getLocation, } from '../../../../redux/spaces-slice';
import { floorStatusByPercentage } from '../../../../../../common/util';
export default function useFloors() {
    var _a, _b;
    const floorsLocations = useSelector(getLocation).children || [];
    const context = useSelector(getContext);
    const floorAnalytics = useSelector(getFloorAnalytics);
    const deviceLocationId = context.deviceLocationId;
    const showFloorOccupancy = (_b = (_a = context === null || context === void 0 ? void 0 : context.appConfig) === null || _a === void 0 ? void 0 : _a.floor) === null || _b === void 0 ? void 0 : _b.showFloorOccupancy;
    const sortedFloors = floorsLocations
        .slice()
        .sort((a, b) => a.floorNumber - b.floorNumber);
    const floors = sortedFloors.map((floor, i) => {
        const analytics = floorAnalytics[floor.id];
        const peopleCount = analytics ? analytics.peopleCount : 0;
        return Object.assign(Object.assign({}, floor), { floorLevel: floor.floorNumber !== null ? floor.floorNumber : '-', percentage: showFloorOccupancy && floor.capacity > 0
                ? ((peopleCount > floor.capacity ? floor.capacity : peopleCount) /
                    floor.capacity) *
                    100
                : 0, isSelected: floor.id === deviceLocationId, floorStatus: floorStatusByPercentage((peopleCount / floor.capacity) * 100) });
    });
    return { floors };
}
