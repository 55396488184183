import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { Flex } from '@magnetic/flex';
import { pxToVh, pxToVw } from '../../../../common/util';
const Container = styled(Flex) `
  background-color: #272a2f;
  background: #272a2f;
  height: ${(props) => pxToVh(props.height)};
  max-height: ${(props) => pxToVh(props.height)};
  width: ${(props) => pxToVw(props.width)};
  max-width: ${(props) => pxToVw(props.width)};
  border-radius: ${(props) => props.size === 'xs' || 'xsm' ? pxToVw(12) : pxToVw(22.4)};
  padding: 0.2vw;
`;
const Text = styled.span `
  font-family: 'CiscoSansRegular';
  font-size: ${(props) => FONT_SIZE[props.size]};
  font-size: ${(props) => pxToVw(FONT_SIZE[props.size])};
`;
const TitleText = styled(Text) `
  line-height: ${(props) => pxToVh(TITLE_FONT_LINE_HEIGHT[props.size])};
  margin-bottom: 0.1vh;
`;
const StatusText = styled(Text) `
  color: ${(props) => (props.color ? props.color : '#00BCEB')};
  margin-top: -10px;
`;
export const ImageContainer = styled.div `
  font-family: 'CiscoSansRegular';
  font-size: 20px;
  line-height: 2vw;
  height: 2vw;
  word-wrap: break-word;
`;
const TELEMETRY_WIDGETS = {
    OCCUPANCY: 'Floor Occupancy',
    IAQ: 'Indoor Air Quality',
    CO2: 'CO2 Level',
    TEMPERATURE: 'Avg. Temperature',
    HUMIDITY: 'Avg. Humidity',
    AMBIENT_NOISE: 'Ambient Noise',
};
const STATUS_COLOR_MAPPING = {
    excellent: '#82CF5F',
    'very good': '#82CF5F',
    good: '#59A83D',
    fair: '#FFEC42',
    poor: '#FEAF1D',
    inadequate: '#FF5A36',
    number: '#00BCEB',
};
const TELEMETRY_IMG_MAPPING = {
    excellent: require('../../../../assets/images/telemetry/excellent.png'),
    'very good': require('../../../../assets/images/telemetry/excellent.png'),
    good: require('../../../../assets/images/telemetry/good.png'),
    fair: require('../../../../assets/images/telemetry/fair.png'),
    poor: require('../../../../assets/images/telemetry/poor.png'),
    inadequate: require('../../../../assets/images/telemetry/inadequate.png'),
    10: require('../../../../assets/images/telemetry/10.png'),
    20: require('../../../../assets/images/telemetry/20.png'),
    30: require('../../../../assets/images/telemetry/30.png'),
    40: require('../../../../assets/images/telemetry/40.png'),
    50: require('../../../../assets/images/telemetry/50.png'),
    60: require('../../../../assets/images/telemetry/60.png'),
    70: require('../../../../assets/images/telemetry/70.png'),
    80: require('../../../../assets/images/telemetry/80.png'),
    90: require('../../../../assets/images/telemetry/90.png'),
    100: require('../../../../assets/images/telemetry/100.png'),
    'very low': require('../../../../assets/images/telemetry/20.png'),
    low: require('../../../../assets/images/telemetry/40.png'),
    busy: require('../../../../assets/images/telemetry/60.png'),
    'very busy': require('../../../../assets/images/telemetry/80.png'),
    crowded: require('../../../../assets/images/telemetry/100.png'),
    // added noise_prefix because 'good' already exists in this map
    noise_good: require('../../../../assets/images/telemetry/20.png'),
    noise_quiet: require('../../../../assets/images/telemetry/40.png'),
    noise_moderate: require('../../../../assets/images/telemetry/60.png'),
    noise_loud: require('../../../../assets/images/telemetry/80.png'),
    'noise_very loud': require('../../../../assets/images/telemetry/100.png'),
};
const TELEMETRY_ICON_MAPPING = {
    'Avg. Temperature': require('../../../../assets/images/telemetry/temperature_icon.svg'),
    'Avg. Humidity': require('../../../../assets/images/telemetry/humidity_icon.svg'),
    'Floor Occupancy': require('../../../../assets/images/telemetry/floor_occupancy_icon.svg'),
    'Ambient Noise': require('../../../../assets/images/telemetry/ambient_noise_icon.svg'),
};
const SIZE_MAP = {
    xs: 0.34,
    xsm: 0.42,
    sm: 0.7,
    md: 1,
    lg: 1.3,
    xl: 1.6,
};
const FONT_SIZE = {
    xs: 10,
    xsm: 12,
    sm: 19.6,
    md: 27.07,
    lg: 33,
    xl: 38,
};
const TITLE_FONT_LINE_HEIGHT = {
    xs: 20,
    xsm: 28,
    sm: 38.07,
    md: 52.58,
    lg: 52.58,
    xl: 52.58,
};
function getTelemetryImage(widgetType, statusValue, size) {
    if (widgetType === TELEMETRY_WIDGETS.TEMPERATURE ||
        widgetType === TELEMETRY_WIDGETS.HUMIDITY ||
        widgetType === TELEMETRY_WIDGETS.OCCUPANCY ||
        widgetType === TELEMETRY_WIDGETS.AMBIENT_NOISE) {
        return (_jsx("div", Object.assign({ style: {
                backgroundImage: `url(${TELEMETRY_IMG_MAPPING[statusValue]})`,
                backgroundSize: 'cover',
                width: `${pxToVw(100 * SIZE_MAP[size])}`,
                height: `${pxToVh(100 * SIZE_MAP[size])}`,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                marginBottom: `${pxToVh(10)}`,
            } }, { children: _jsx("img", { src: TELEMETRY_ICON_MAPPING[widgetType], style: {
                    width: `${pxToVw((100 * SIZE_MAP[size]) / 3)}`,
                    height: `${pxToVh((100 * SIZE_MAP[size]) / 3)}`,
                    maxHeight: `${pxToVw((100 * SIZE_MAP[size]) / 3)}`,
                    maxWidth: `${pxToVw((100 * SIZE_MAP[size]) / 3)}`,
                } }) })));
    }
    else {
        return (_jsx("div", { style: {
                backgroundImage: `url(${TELEMETRY_IMG_MAPPING[statusValue]})`,
                backgroundSize: 'cover',
                width: `${pxToVw(100 * SIZE_MAP[size])}`,
                height: `${pxToVh(100 * SIZE_MAP[size])}`,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                marginBottom: `${pxToVh(10)}`,
            } }));
    }
}
export function TelemetryWidget({ type, status, size = 'md', }) {
    function roundToNearestTen(num) {
        return Math.round(num / 10) * 10;
    }
    const widgetType = TELEMETRY_WIDGETS[type];
    let statusColor = STATUS_COLOR_MAPPING[status.toLowerCase()];
    let statusValue = status.toLowerCase();
    if (widgetType === TELEMETRY_WIDGETS.TEMPERATURE ||
        widgetType === TELEMETRY_WIDGETS.HUMIDITY) {
        const statusVCalc = status.substring(0, 2);
        statusValue = roundToNearestTen(parseInt(statusVCalc)).toString();
        statusColor = STATUS_COLOR_MAPPING['number'];
    }
    return (_jsxs(Container, Object.assign({ direction: "verticle", gap: 4, height: 200 * SIZE_MAP[size], width: 280 * SIZE_MAP[size], align: "center", justify: "center", size: size }, { children: [_jsx(TitleText, Object.assign({ size: size }, { children: widgetType })), getTelemetryImage(widgetType, statusValue, size), _jsx(StatusText, Object.assign({ size: size, color: statusColor }, { children: status.startsWith('noise_') ? status.substring(6) : status }))] })));
}
