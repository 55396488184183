import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import EllipseDot from '../../../../assets/images/ellipse-dot';
import { Flex } from '@magnetic/flex';
import styled from 'styled-components';
import useTime from '../hook/time';
const Text = styled.span `
  font-family: 'CiscoSansRegular';
`;
const DayDateText = styled(Text) `
  font-size: 0.729vw;
  letter-spacing: 0em;
  line-height: 2.176vh;
`;
const TimeText = styled(Text) `
  font-size: 1.042vw;
  line-height: 1.759vh;
  color: #00bceb;
`;
const Time = () => {
    const { time, day, currentDate } = useTime();
    return (_jsxs(Flex, Object.assign({ direction: "verticle", justify: "center", align: "center" }, { children: [_jsxs(DayDateText, { children: [day, ", ", currentDate] }), _jsxs(Flex, Object.assign({ direction: "horizontal", align: "center" }, { children: [_jsx(Flex, Object.assign({ style: { marginTop: '8px' } }, { children: _jsx(EllipseDot, {}) })), _jsx(TimeText, { children: time })] }))] })));
};
export default Time;
