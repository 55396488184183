// import mapboxgl from 'mapbox-gl';
import Lens from './lens';
import LensNew from './lens-new';
class DeskHelper {
    constructor() {
        this.data = {};
        this.map = {};
        this.tempObj = { layers: [] };
        this.highlightDesksOnMap = false;
        this.debugDeskHighlighting = false;
        this.selectedWorkspaces = [];
        this.Types = {};
        this.lens = {};
    }
    rotateLines(lines, angle) {
        const radians = (angle * Math.PI) / 180;
        for (const line of lines) {
            if (line.every((coord) => coord !== null)) {
                const x1 = line[0];
                const y1 = line[1];
                const x2 = line[2];
                const y2 = line[3];
                line[0] = x1 * Math.cos(radians) - y1 * Math.sin(radians);
                line[1] = x1 * Math.sin(radians) + y1 * Math.cos(radians);
                line[2] = x2 * Math.cos(radians) - y2 * Math.sin(radians);
                line[3] = x2 * Math.sin(radians) + y2 * Math.cos(radians);
            }
        }
    }
    init(data, map, highlightDesksOnMap, debugDeskHighlighting) {
        this.data = data;
        this.map = map;
        this.highlightDesksOnMap = highlightDesksOnMap;
        this.debugDeskHighlighting = debugDeskHighlighting;
        this.selectedWorkspaces = [];
        this.Types = {
            LENS_UW_135: {
                fcx_init: 237.7,
                fcy_init: 238.3,
                ccx_init: 320.0,
                ccy_init: 240.0,
                kc: [0.0670953, -0.0063064, -0.0319042, 0.0068219],
                calibration_sizehx: 640,
                calibration_sizevy: 480,
                fov: (135 * Math.PI) / 180.0,
            },
            LENS_MOD02r1: {
                width: 640,
                height: 480,
                cenX: 640.0 * 0.5,
                cenY: 480.0 * 0.5,
                pixelRadius90Degrees: 322.0,
                zoom: 1.2021,
                kc: [0.0, 0.0, 0.0, 0.0],
                fov: 165,
            },
            LENS_MOD03: {
                width: 640,
                height: 480,
                cenX: 640.0 * 0.5,
                cenY: 480.0 * 0.5,
                pixelRadius90Degrees: 317.0,
                zoom: 1.077,
                kc: [0.0, 0.0, 0.0, 0.0],
                fov: 165,
            },
            LENS_MV32: {
                width: 720,
                height: 720,
                cenX: 720.0 * 0.5,
                cenY: 720.0 * 0.5,
                pixelRadius90Degrees: 353.15719,
                zoom: 1.0,
                kc: [0.0, -0.11378, 0.20528, -0.16514],
                fov: 165,
            },
        };
        this.lens = this.createLens(this.Types.LENS_MV32);
    }
    createLens(type, digitalZoom = false) {
        if (type === this.Types.LENS_MOD02r1 ||
            type === this.Types.LENS_MOD03 ||
            type === this.Types.LENS_MV32) {
            return new LensNew(type, digitalZoom);
        }
        return new Lens(type);
    }
    displayCamerasAndDesk() {
        for (const key of Object.keys(this.data)) {
            this.displayEachCamerasAndDesk(this.data[key]);
        }
    }
    displayEachCamerasAndDesk(dataPerCamera) {
        let cameraPosition = {
            lng: dataPerCamera.latlng[1],
            lat: dataPerCamera.latlng[0],
        };
        const { angle, offsetX, offsetY, desks, cameraSerial, deskWidth } = dataPerCamera;
        cameraPosition = this.offset(cameraPosition, offsetX, offsetY);
        console.log('camera position', cameraPosition, 'deskWidth', deskWidth);
        const geojson = {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [cameraPosition.lng, cameraPosition.lat],
                    },
                    properties: {
                        title: cameraSerial,
                        description: cameraSerial,
                    },
                },
            ],
        };
        for (const { geometry, properties } of geojson.features) {
            const el = document.createElement('div');
            el.className = 'marker';
            new mapboxgl.Marker(el)
                .setLngLat(geometry.coordinates)
                .setPopup(new mapboxgl.Popup({ offset: 25 }).setText(properties.title))
                .addTo(this.map);
            console.log('Added marker at', geometry.coordinates, 'with title', properties.title);
        }
        const out = [];
        const occupancy = [];
        for (const desk of desks) {
            const leftCoordinate = this.lens.dewarp(desk[0], desk[1]);
            const rightCoordinate = this.lens.dewarp(desk[4], desk[5]);
            out.push([
                leftCoordinate[0],
                leftCoordinate[1],
                rightCoordinate[0],
                rightCoordinate[1],
            ]);
            occupancy.push(desk[6]);
        }
        const filteredOut = out.filter((line) => line.every((coord) => coord !== null));
        this.moveCenterTo(filteredOut);
        this.rotateLines(out, angle);
        const validLines = out.filter((line) => line.every((coord) => coord !== null));
        console.log(`average distance ${this.averageDistance(validLines)}`);
        for (let i = 0; i < out.length; i += 1) {
            const cDesk = out[i];
            if (cDesk.every((coord) => coord !== null)) {
                this.drawDesk(cameraPosition, cDesk, occupancy[i], cameraSerial, i);
            }
        }
    }
    scaleLines(lines, deskWidth) {
        const ratio = deskWidth / this.averageDistance(lines);
        for (const line of lines) {
            line[0] *= ratio;
            line[1] *= ratio;
            line[2] *= ratio;
            line[3] *= ratio;
        }
    }
    moveCenterTo(lines) {
        const meanX = 360;
        const meanY = 360;
        for (const line of lines) {
            line[0] -= meanX;
            line[1] -= meanY;
            line[2] -= meanX;
            line[3] -= meanY;
        }
    }
    averageDistance(lines) {
        let totalDist = 0;
        for (const line of lines) {
            totalDist += this.distXY(line[0], line[1], line[2], line[3]);
        }
        return totalDist / lines.length;
    }
    distXY(x1, y1, x2, y2) {
        const deltaX = x1 - x2;
        const deltaY = y1 - y2;
        return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
    }
    offset(latlng, x, y) {
        const rEarth = 6378;
        const pi = Math.PI;
        const xKms = x / 39370.07874;
        const yKms = y / 39370.07874;
        const newLongitude = latlng.lng +
            ((xKms / rEarth) * (180 / pi)) / Math.cos((latlng.lat * pi) / 180);
        const newLatitude = latlng.lat - (yKms / rEarth) * (180 / pi);
        return { lat: newLatitude, lng: newLongitude };
    }
    drawDesk(cameraPosition, desk, occupancy, cameraSerial, deskNo) {
        const start = this.offset(cameraPosition, desk[0], desk[1]);
        const end = this.offset(cameraPosition, desk[2], desk[3]);
        console.log('occupancy', occupancy);
        this.changeDeskStatus(start, end, deskNo, occupancy);
        const source = `${cameraSerial}_${deskNo}`;
        console.log('cameraSerial source', source);
        this.tempObj.layers.push(source);
        if (!this.map.getSource(source) && this.debugDeskHighlighting) {
            this.map.addSource(source, {
                type: 'geojson',
                data: {
                    type: 'Feature',
                    properties: { title: `${deskNo}` },
                    geometry: {
                        type: 'LineString',
                        coordinates: [
                            [start.lng, start.lat],
                            [end.lng, end.lat],
                        ],
                    },
                },
            });
        }
        if (!this.map.getLayer(source) && this.debugDeskHighlighting) {
            this.map.addLayer({
                id: source,
                type: 'line',
                source,
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round',
                },
                paint: {
                    'line-color': '#32a',
                    'line-width': 8,
                },
            });
        }
        if (!this.map.getLayer(`${source}_text`) && this.debugDeskHighlighting) {
            this.map.addLayer({
                id: `${source}_text`,
                type: 'symbol',
                source,
                layout: {
                    'text-font': ['Open Sans Regular'],
                    'text-field': '{title}',
                    'text-size': 16,
                },
            });
        }
    }
    removeCameraPosition() {
        if (this.tempObj.layers.length === 0)
            return;
        for (const source of this.tempObj.layers) {
            if (this.map.getLayer(source)) {
                this.map.removeLayer(source);
            }
            if (this.map.getLayer(`${source}_text`)) {
                this.map.removeLayer(`${source}_text`);
            }
            if (this.map.getSource(source)) {
                this.map.removeSource(source);
            }
        }
        this.tempObj.layers = [];
    }
    changeDeskStatus(start, end, deskNo, occupancyState) {
        const startPoint = this.map.project([start.lng, start.lat]);
        const endPoint = this.map.project([end.lng, end.lat]);
        const diffx = (endPoint.x - startPoint.x) / 2;
        const diffy = (endPoint.y - startPoint.y) / 2;
        const bbox = [
            [startPoint.x + diffx, startPoint.y + diffy],
            [startPoint.x + diffx, startPoint.y + diffy],
        ];
        const selectedRooms = this.map.queryRenderedFeatures(bbox, {
            layers: ['units-work-space'],
        });
        console.log('Desk No', deskNo, 'selectedRooms', selectedRooms);
        if (selectedRooms && selectedRooms.length > 0 && selectedRooms[0]) {
            console.log(selectedRooms[0]);
            if (!selectedRooms[0].id) {
                console.log('There is no id for the selected room');
                return;
            }
            const feature = {
                source: 'selected-floor',
                sourceLayer: 'maps-layer',
                id: selectedRooms[0].id,
            };
            const state = this.map.getFeatureState(feature);
            this.selectedWorkspaces.push(String(feature.id));
            let newState;
            switch (occupancyState) {
                case 0:
                    newState = 'vacant';
                    break;
                case 1:
                    newState = 'occupied';
                    break;
                default:
                    newState = 'unknown';
            }
            console.log('status of deskNo', deskNo, 'selectedRooms', selectedRooms, newState, 'current state', occupancyState);
            state.status = newState;
            this.map.setFeatureState(feature, state);
        }
    }
}
export default DeskHelper;
