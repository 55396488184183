var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { useEffect, useState, useRef } from 'react';
import dottedRectangleImage from '../../../../../assets/images/dotted-rectangle.svg';
import { QRCodeSVG } from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { vwToPixels } from '../../util';
import { closeRoomDetailsPopup } from '../room-details-popup';
import { useSelector } from 'react-redux';
import { getUIState } from '../../../../../store/uislice';
const Container = styled.div `
  width: fit-content;
  text-align: center;
  z-index: 0;
  position: fixed;
  left: 87.4%;
  margin-top: 8.2vw;
  height: fit-content;
`;
const BackgroundImage = styled.img `
  width: 12vw;
  position: fixed;
`;
const DottedRectangleImage = styled.img `
  left: 88.4%;
  width: 11vw;
  height: 16vw;
  margin-top: 8.2vw;
  position: fixed;
`;
const Modal = styled.div `
  background-color: #000;
  height: 100vh;
  opacity: 1;
  position: fixed;
  z-index: 9;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.7);
  display: flex !important;
`;
const ModalContent = styled.div `
  transform: scale(2);
  margin: auto;
`;
const getTLD = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const websocketUrl = urlParams.get('domain');
    if (websocketUrl) {
        return websocketUrl.split('.')[2];
    }
    const url = window.location.href;
    const cleanUrl = url.split('?')[0];
    const parts = cleanUrl.split('.');
    return parts[parts.length - 1];
};
export const getQRCode = ({ tenantId, locationId, poiId, setter }) => __awaiter(void 0, void 0, void 0, function* () {
    const currentTime = Date.now();
    const oneWeekFromNow = currentTime + 7 * 24 * 60 * 60 * 1000;
    const unixTimestamp = Math.floor(oneWeekFromNow / 1000);
    const accessTokenResponse = yield fetch('https://rms.dnaspaces.io/oauth/v2/token', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            tenant: `${tenantId}`,
            location: locationId,
            grantType: 'access_token',
            role: 'cisco',
        }),
    });
    const accessTokenData = yield accessTokenResponse.json();
    const tld = getTLD();
    let QR_Code_URL = 'https://dnaspaces.io/wf-api/api/short-uri';
    if (tld.includes('eu')) {
        QR_Code_URL = 'https://dnaspaces.eu/wf-api/api/short-uri';
    }
    else if (tld.includes('sg')) {
        QR_Code_URL = 'https://ciscospaces.sg/wf-api/api/short-uri';
    }
    const urlResponse = yield fetch(QR_Code_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessTokenData === null || accessTokenData === void 0 ? void 0 : accessTokenData.accessToken}`,
        },
        body: JSON.stringify({
            tenantId: `${tenantId}`,
            locationId,
            poiId,
        }),
    });
    const urlData = yield urlResponse.json();
    setter(urlData === null || urlData === void 0 ? void 0 : urlData.short_uri);
    console.log('urlData :: ', urlData === null || urlData === void 0 ? void 0 : urlData.short_uri);
});
const WidgetContent = ({ t, floorQrCode, QRCodeSize, tapToExpand, setTapToExpand, }) => {
    return (_jsxs(_Fragment, { children: [_jsx(BackgroundImage, { src: tapToExpand
                    ? require('../../../../../assets/images/qr-code1.png')
                    : require('../../../../../assets/images/qr-code2.png'), onClick: () => {
                    tapToExpand && setTapToExpand(false);
                } }), _jsx(QRCodeSVG, { value: floorQrCode, size: QRCodeSize, style: {
                    border: '0.125vw solid #000000',
                    margin: '6.6vw 0vw 0vw 3.4vw',
                    position: 'relative',
                    zIndex: '999999999999',
                }, bgColor: "#ffffff", fgColor: "#000000", level: "L" })] }));
};
export const NavigateOnPhone = () => {
    const { t } = useTranslation();
    const [floorQrCode, setFloorQrCode] = useState('');
    const [QRCodeSize, SetQRCodeSize] = useState(vwToPixels(5.4));
    const [tapToExpand, setTapToExpand] = useState(false);
    const QRCodeIntervalRef = useRef(null);
    const popupRichMapID = useSelector(getUIState).popupRichMapID;
    const [graphId, setGraphId] = useState('98aef119-42df-4e75-b22e-a0b366a44a7c');
    const tenantId = 20482;
    useEffect(() => {
        const handleResize = () => {
            SetQRCodeSize(vwToPixels(5.4));
        };
        window.addEventListener('resize', handleResize);
        getQRCode({
            tenantId: tenantId,
            locationId: graphId,
            poiId: undefined,
            setter: setFloorQrCode,
        });
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        if (QRCodeIntervalRef.current) {
            clearInterval(QRCodeIntervalRef.current);
        }
        getQRCode({
            tenantId: 20482,
            locationId: '98aef119-42df-4e75-b22e-a0b366a44a7c',
            poiId: undefined,
            setter: setFloorQrCode,
        });
        const interval = window.setInterval(() => {
            getQRCode({
                tenantId: 20482,
                locationId: '98aef119-42df-4e75-b22e-a0b366a44a7c',
                poiId: undefined,
                setter: setFloorQrCode,
            });
        }, 86400000); // 86400000 milliseconds = 1 day
        QRCodeIntervalRef.current = interval;
        if (QRCodeIntervalRef.current) {
            return () => clearInterval(QRCodeIntervalRef.current);
        }
    }, [graphId]);
    const shouldShow = () => {
        // If popupRichMapID is not null, room-details-popup is open, hence this widget is removed
        return popupRichMapID === null;
    };
    return (
    // {roomSelectedId ? null : isInteractive && isWayFindingEnabled && isQrExpanded ? (
    _jsx(_Fragment, { children: shouldShow() && (_jsxs(Container, { children: [tapToExpand && (_jsxs(Modal, { children: [_jsx(ModalContent, { children: _jsx(WidgetContent, { t: t, floorQrCode: floorQrCode, QRCodeSize: QRCodeSize, tapToExpand: tapToExpand, setTapToExpand: setTapToExpand }) }), _jsx(DottedRectangleImage, { src: dottedRectangleImage })] })), !tapToExpand && (_jsx("div", Object.assign({ onClick: () => {
                        closeRoomDetailsPopup();
                        setTapToExpand(true);
                    } }, { children: _jsx(WidgetContent, { t: t, floorQrCode: floorQrCode, QRCodeSize: QRCodeSize, tapToExpand: tapToExpand, setTapToExpand: setTapToExpand }) })))] })) }));
};
