import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import Time from '../time';
import Weather from '../weather';
import { WidgetContainer } from '../styled-widget';
const Divider = styled.div `
  background: #ffffff;
  opacity: 0.5;
  width: 1px;
  height: 60%;
  margin-left: 26px;
  margin-right: 20px;
`;
export default function TimeWeather() {
    const showWeather = false;
    return (_jsxs(WidgetContainer, Object.assign({ align: "center", justify: "center", width: showWeather ? 530 : 200, height: 124, color: '#1F2226' }, { children: [_jsx(Time, {}), showWeather && (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsx(Weather, {})] }))] })));
}
