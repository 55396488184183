import { createSlice } from '@reduxjs/toolkit';
import { workspaceApiSlice } from '../service/api';
import { natsSlice } from '../../../store/natsslice';
// Initial state
const initialState = {
    context: {},
    building: {},
    floor: [],
    room: [],
    location: {
        zoneId: 'America/Los_Angeles',
    },
    selectedLocationId: '',
};
// TODO - this is a temporary solution to process the messages from the NATS server
const processMessage = (state, subject, payload) => {
    if (subject.startsWith('signageAnalytical')) {
        const level = payload.level.toLowerCase();
        const id = payload.id;
        if (level === 'building') {
            state.building = payload;
        }
        else if (level === 'floor') {
            state.floor = Object.assign(Object.assign({}, state.floor), { [id]: payload });
        }
        else if (level === 'room') {
            const richMapId = payload.richMapId;
            state.room = Object.assign(Object.assign({}, state.room), { [richMapId]: payload });
        }
    }
    if (subject.startsWith('lh')) {
        state.location = payload;
    }
};
export const spacesSlice = createSlice({
    name: 'spacesData',
    initialState,
    reducers: {
        setSelectedLocation: (state, action) => {
            state.selectedLocationId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(natsSlice.actions.updateMessage, (state, action) => {
            const { subject, payload } = action.payload;
            processMessage(state, subject, payload);
        });
        builder.addMatcher(workspaceApiSlice.endpoints.context.matchFulfilled, (state, action) => {
            const payload = action.payload;
            state.context = payload;
            state.selectedLocationId = payload.currentLocationGraphId;
        });
    },
    selectors: {
        getContext: (state) => state.context,
        getBuildingAnalytics: (state) => state.building,
        getFloorAnalytics: (state) => state.floor,
        getRoomAnalytics: (state) => state.room,
        getLocation: (state) => state.location,
        selectFloorById: (state, id) => state.floor[id],
        selectRoomById: (state, id) => state.room[id],
        getSelectedLocation: (state) => state.selectedLocationId,
    },
});
// Export selectors
export const { getContext, getBuildingAnalytics, getFloorAnalytics, getRoomAnalytics, getLocation, selectFloorById, selectRoomById, getSelectedLocation, } = spacesSlice.selectors;
// Export actions
export const { setSelectedLocation } = spacesSlice.actions;
