import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
const FullScreenContainer = styled.div `
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  height: -webkit-fill-available;
  height: -moz-available;
  height: fill-available;
  display: flex;
  flex-direction: column;
  font-family: 'CiscoSans', sans-serif;
`;
const FloorLayout = ({ children }) => (_jsx(FullScreenContainer, { children: children }));
export default FloorLayout;
