var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createApi } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import { DataService } from '../app/configure/service/deviceservice';
import NatsClient from './natsclient';
// Define an Axios instance
const axiosInstance = axios.create();
const dataService = new DataService();
// Add request interceptor
axiosInstance.interceptors.request.use((config) => {
    const publicKey = NatsClient.getPublicKey();
    if (publicKey) {
        config.headers['Authorization'] = `publicKey=${publicKey}`;
    }
    // Log request
    console.log('Request:', {
        url: config.url,
        method: config.method,
        headers: config.headers,
        params: config.params,
        data: config.data,
    });
    return config; // Return the modified config
}, (error) => {
    // Log request error
    console.error('Request Error:', error);
    return Promise.reject(error);
});
// Add response interceptor
axiosInstance.interceptors.response.use((response) => {
    // Log response
    console.log('Response:', {
        url: response.config.url,
        status: response.status,
        data: response.data,
    });
    return response; // Return the response data
}, (error) => {
    var _a;
    // Log response error
    console.error('Response Error:', (_a = error.response) === null || _a === void 0 ? void 0 : _a.status, error.message);
    return Promise.reject(error); // Forward the error
});
const axiosMiddleware = (config) => {
    // Pass the request through the axios instance (with interceptors)
    return axiosInstance(config);
};
// Custom base query function for RTK Query
const axiosBaseQuery = ({ baseUrl } = { baseUrl: '' }) => ({ url, method = 'GET', data, params, headers, }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    try {
        const domain = dataService.getDomain() || '';
        const currUrl = domain !== ''
            ? `https://${domain}/${baseUrl}${url}`
            : `${baseUrl}${url}`;
        console.log('currUrl', currUrl);
        // Call the axios middleware with the request config
        const result = yield axiosMiddleware({
            url: currUrl,
            method,
            data,
            params,
            headers,
        });
        return { data: result.data };
    }
    catch (axiosError) {
        const err = axiosError;
        return {
            error: {
                status: (_a = err.response) === null || _a === void 0 ? void 0 : _a.status,
                data: ((_b = err.response) === null || _b === void 0 ? void 0 : _b.data) || err.message,
            },
        };
    }
});
export const singangeApi = createApi({
    reducerPath: 'singangeApi',
    baseQuery: axiosBaseQuery({
        baseUrl: 'api/v1/',
    }),
    endpoints: () => ({}),
});
export const workspaceApi = createApi({
    reducerPath: 'workspaceApi',
    baseQuery: axiosBaseQuery({
        baseUrl: 'v1/',
    }),
    endpoints: () => ({}),
});
