import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MaleHoldingMagnifingGlass from '../../../../assets/svgs/male-holding-magnifying-glass';
import Compass from '../../../../assets/svgs/compass';
import { Flex } from '@magnetic/flex';
const StyledResetView = styled(Flex) `
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;

  .reset-modal {
    background-color: #000;
    opacity: 1;

    position: absolute;
    z-index: 9;
    left: -50vw;
    top: -50vh;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.7);

    .reset-modal-content {
      background-color: #fefefe;
      margin: 15% auto;
      padding: 2vw;
      width: 56%;
      background: #2b3746;
      box-sizing: border-box;
      box-shadow: 0px 0.5vw 1.5vw 1vw rgba(255, 255, 255, 0.1);
      border-radius: 3vw;
      transform: scale(0.72);
      .icon {
      }
      .header {
        color: #ffffff;
        font-size: 2.5vw;
        line-height: 5vw;
      }
      .subtext {
        color: #ffffff;
        font-size: 1.2vw;
        padding-top: 1vw;
        line-height: 1.9vw;
      }
      .circle {
        width: 100%;
        height: 100%;

        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
      }
      .circle.one {
        --color: ${(props) => (props.counter > 0 ? '#00bceb' : '#26282b')}
          --p: ${(props) => props.counter > 0 ? (props.counter / props.maxTime) * 100 : 0};
        --v: calc(((18 / 5) * var(--p) - 90) * 1deg);
        width: 10vw;
        height: 10vw;
        background:
          linear-gradient(#26282b, #26282b) content-box,
          linear-gradient(var(--v), #26282b 50%, transparent 0) 0 /
            min(100%, (50 - var(--p)) * 100%),
          linear-gradient(var(--v), transparent 50%, var(--color) 0) 0 /
            min(100%, (var(--p) - 50) * 100%),
          linear-gradient(to right, #26282b 50%, var(--color) 0);
        padding: 0.3125vw;
        border: 1px solid #383b3d;
      }
      .countdown {
        padding-top: 1.15vw;
      }
      .circle.two {
        border: 1px solid #383b3d;
        text-align: center;
      }
      .counter {
        font-family: 'CiscoSans';
        padding-top: 3.7vw;
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 39px;
        text-align: center;
        color: #ffffff;
      }
      .reset-in {
        font-size: 18px;
        line-height: 1vw;
        font-size: 1vw;
        margin-top: -1vw;
        color: #ffffff;
        margin-bottom: 1vw;
        padding-left: 1vw;
      }

      button {
        font-family: 'CiscoSansTT Heavy';
        text-align: center;
        width: 12vw;
        font-size: 1.5vw;
        border: none;
        margin: 0 2vw;
        padding: 0.6vw 0;
      }

      .button-continue {
        background: linear-gradient(180deg, #6abf4b 0%, #34711e 100%);
        border-radius: 0.5vw;
        font-size: 1.25vw;
        color: #ffffff;
      }
      .button-reset {
        background: linear-gradient(180deg, #1d7ff3 0%, #113d7d 100%);
        border-radius: 0.5vw;
        font-size: 1.25vw;
        color: #ffffff;
      }
      .reset-text {
        padding-left: 1vw;
        font-family: 'CiscoSans';
      }
      .reset-icon {
        vertical-align: sub;
      }
      .continue-text {
        vertical-align: sub;
        font-family: 'CiscoSans';
      }
    }

    /* The Close Button */
    .close {
      float: right;
      font-weight: bold;
    }

    .close:hover,
    .close:focus {
      color: #aaa;
      text-decoration: none;
      cursor: pointer;
    }
  }
`;
export default function ResetView({ inactiveDevice = false, setAction, maxTime = 30, counter = maxTime, shouldReset = false, setIsSignageActive, addEventListeners, }) {
    if (!inactiveDevice) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(StyledResetView, Object.assign({ counter: counter, maxTime: maxTime }, { children: _jsx("div", Object.assign({ className: "reset-modal" }, { children: _jsxs("div", Object.assign({ className: "reset-modal-content" }, { children: [_jsxs(Flex, { children: [_jsx("div", Object.assign({ className: "columns medium-2" }, { children: _jsx(MaleHoldingMagnifingGlass, {}) })), _jsxs("div", Object.assign({ className: "columns medium-6", style: { marginLeft: '4vw' } }, { children: [_jsx("div", Object.assign({ className: "header" }, { children: "Hi, Are you still around?" })), _jsx("div", Object.assign({ className: "subtext" }, { children: "We haven't detected any activity in a while please touch below buttons to continue" }))] })), _jsxs("div", Object.assign({ className: "columns medium-2 countdown", style: { margin: '1vw' } }, { children: [_jsx("div", Object.assign({ className: "reset-in" }, { children: "Resetting view in" })), _jsx("div", Object.assign({ className: "circle one" }, { children: _jsx("div", Object.assign({ className: "circle two" }, { children: _jsx("div", Object.assign({ className: "counter" }, { children: counter > 0 ? counter : shouldReset ? 'now' : '-' })) })) }))] }))] }), _jsxs(Flex, Object.assign({ justify: "center", style: {
                            marginTop: '4vw',
                            position: 'absolute',
                            width: '31vw',
                            marginLeft: '18%',
                            padding: '1vw 0',
                            borderRadius: '1vw',
                            background: '#1f2226',
                        } }, { children: [_jsx("div", Object.assign({ className: "columns medium-3" }, { children: _jsx("button", Object.assign({ className: "button-continue", onClick: () => {
                                        setAction({ action: 'continue' });
                                        setIsSignageActive(true);
                                    } }, { children: _jsx("span", Object.assign({ className: "continue-text" }, { children: "Continue" })) })) })), _jsx("div", Object.assign({ className: "columns medium-3 margin-right" }, { children: _jsxs("button", Object.assign({ className: "button-reset", onClick: () => {
                                        setAction({ action: 'reset' });
                                        setIsSignageActive(true);
                                    } }, { children: [_jsx("span", Object.assign({ className: "reset-icon" }, { children: _jsx(Compass, {}) })), _jsx("span", Object.assign({ className: "reset-text" }, { children: "Reset View" }))] })) }))] }))] })) })) })));
}
ResetView.propTypes = {
    inactiveDevice: PropTypes.bool,
    setAction: PropTypes.func,
};
ResetView.defaultProps = {
    inactiveDevice: false,
};
