import { jsx as _jsx } from "react/jsx-runtime";
import { Welcome } from '../app/configure';
import { Workspaces } from '../app/workspaces';
import FloorView from '../app/workspaces/floor/layout';
import { NavigatorView } from '../app/workspaces/navigator/index';
export const ROUTES = {
    WELCOME: '/',
    TENANT_PATH: '/t/:tm',
    WORKSPACES: {
        HOME: 'home',
        BUILDING: 'building',
        FLOOR: 'floor',
        NAVIGATOR: 'navigator',
    },
};
export const mainRoutes = [
    {
        index: true,
        path: ROUTES.WELCOME,
        element: _jsx(Welcome, {}),
    },
    {
        path: ROUTES.TENANT_PATH,
        element: _jsx(Welcome, {}),
    },
    {
        path: ROUTES.WORKSPACES.HOME,
        element: _jsx(Workspaces, {}),
    },
    {
        path: ROUTES.WORKSPACES.FLOOR,
        element: _jsx(FloorView, {}),
    },
    {
        path: ROUTES.WORKSPACES.NAVIGATOR,
        element: _jsx(NavigatorView, {}),
    },
];
