import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as C from '../../../../../../constants';
export default function FloorCircle({ className, floorLevel, floorStatus, selectLocation, floor, isSelected, }) {
    const { t } = useTranslation();
    return (_jsx("div", Object.assign({ className: className, onClick: () => selectLocation(Object.assign({}, floor)) }, { children: _jsx("div", Object.assign({ className: "circle one" }, { children: _jsxs("div", Object.assign({ className: "circle two" }, { children: [_jsx("div", Object.assign({ className: "floor-level" }, { children: floorLevel })), floorStatus && (_jsx("div", Object.assign({ className: "floor-status" }, { children: t(`current-occupancy.${floorStatus}`) }))), isSelected && _jsx(YouAreHere, {})] })) })) })));
}
function YouAreHere() {
    return (_jsx("div", Object.assign({ className: "you-are-here" }, { children: _jsx("div", { children: C.YOU_ARE_HERE }) })));
}
FloorCircle.propTypes = {
    className: PropTypes.string.isRequired,
    floorLevel: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    floorStatus: PropTypes.string,
    isSelected: PropTypes.bool,
};
FloorCircle.defaultProps = {
    floorStatus: '',
    floorLevel: '',
    isSelected: false,
};
