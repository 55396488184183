import { jsx as _jsx } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getSelectedLocation } from '../workspaces/redux/spaces-slice';
import useWorkspaceEffects from '../workspaces/common/hook/use-workspace-effect';
import { BuildingView } from './building';
import FloorView from './floor/layout';
import { NavigatorView } from './navigator';
export function Workspaces() {
    const { state } = useLocation();
    const { viewType } = useWorkspaceEffects(state);
    const selectedLocation = useSelector(getSelectedLocation);
    switch (viewType) {
        case 'building':
            return _jsx(BuildingView, {});
            break;
        case 'floor':
            return _jsx(FloorView, { id: selectedLocation });
            break;
        case 'navigator':
            return _jsx(NavigatorView, {});
            break;
        default:
            return _jsx("h1", { children: "Loading..." });
            break;
    }
}
