const adjustMapboxControlPosition = () => {
    const controlElement = document.querySelector('.mapboxgl-ctrl-top-right');
    if (controlElement) {
        controlElement.style.top = '130px';
        controlElement.style.right = '5px';
    }
};
const initializeRichMap = (mapInit) => {
    //   const updatedConfig = updateMapConfig({
    //     locationId,
    //     tenantId: Number(tenantId) || mapConfig.tenantId,
    //   });
    window.rMap = new RichMap(mapInit);
};
export { adjustMapboxControlPosition, initializeRichMap };
