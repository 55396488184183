import styled from 'styled-components';
const MapContainer = styled.div `
  canvas {
    background: linear-gradient(to right, red, yellow),
      linear-gradient(to left, red, yellow),
      linear-gradient(to bottom, red, yellow),
      linear-gradient(to top, red, yellow);
    background-blend-mode: screen;
  }
`;
export default MapContainer;
