import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DataService } from '../../app/configure/service/deviceservice';
function useDomainParam() {
    const effectRan = useRef(false);
    const [searchParams] = useSearchParams();
    const dataService = new DataService();
    const domain = searchParams.get('domain') || location.hostname;
    const [isDomainAvailable, setIsDomainAvailable] = useState(false);
    // const rootDomain = domain.match(
    //   /^(?:.*?\.)?([a-zA-Z0-9\-_]{3,}\.(?:\w{2,8}|\w{2,4}\.\w{2,4}))$/
    // )[1];
    useEffect(() => {
        if (!effectRan.current) {
            console.log('useDomainParam:: Domain:', domain);
            dataService.setDomain(domain);
            setIsDomainAvailable(true);
        }
        return () => {
            effectRan.current = true;
        };
    }, [domain]);
    return isDomainAvailable;
}
export default useDomainParam;
