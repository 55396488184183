import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex } from '@magnetic/flex';
import styled from 'styled-components';
const Text = styled.span `
  font-family: 'CiscoSansRegular';
`;
const LocationText = styled(Text) `
  font-size: 1.042vw;
  letter-spacing: 0em;
  line-height: 2.176vh;
`;
const ConditionText = styled(Text) `
  font-size: 0.677vw;
  line-height: 3.566vh;
`;
const weather = {
    location: 'San Francisco, CA',
    temperature: '58',
    temperatureUnit: 'C',
    condition: 'Partly cloudy',
    icon: require('../../../../assets/images/weather.svg'),
};
const Weather = () => {
    return (_jsxs(Flex, Object.assign({ gap: 12 }, { children: [_jsx("img", { src: weather.icon }), _jsxs(Flex, Object.assign({ direction: "verticle" }, { children: [_jsx(LocationText, { children: weather.location }), _jsx(Flex, Object.assign({ gap: 4 }, { children: _jsxs(LocationText, { children: [weather.temperature, " \u00B0", weather.temperatureUnit] }) })), _jsx(ConditionText, { children: weather.condition })] }))] })));
};
export default Weather;
