import styled, { css } from 'styled-components';
const Line = styled.div `
  background-color: #fff;

  ${({ orientation }) => orientation === 'vertical'
    ? css `
          width: 2px;
          height: 76px;
          opacity: 0.5;
        `
    : css `
          width: 76px;
          height: 2px;
          margin: 0px 20px;
        `}
`;
export default Line;
