import WebSocketClient from './websocketclient'; // Your WebSocket client implementation
import { setWebSocketStatus } from './configureslice'; // Redux slice
import { DataService } from '../app/configure/service/deviceservice';
// Pub-sub registry for component subscriptions
const eventSubscribers = new Map();
const subscribeToEvent = (event, callback) => {
    if (!eventSubscribers.has(event)) {
        eventSubscribers.set(event, []);
    }
    eventSubscribers.get(event).push(callback);
    // Unsubscribe function
    return () => {
        const subscribers = eventSubscribers
            .get(event)
            .filter((cb) => cb !== callback);
        eventSubscribers.set(event, subscribers);
    };
};
const configureWebsocketMiddleware = (store) => {
    let wsClient = null;
    const dataService = new DataService();
    // Helper to notify subscribers
    const notifySubscribers = (event, payload) => {
        if (eventSubscribers.has(event)) {
            eventSubscribers.get(event).forEach((callback) => callback(payload));
        }
    };
    return (next) => (action) => {
        var _a;
        switch (action.type) {
            case 'CONFIGURE_APP_START': {
                const { serverUrl, webGL } = action.payload;
                const tenantMask = dataService.getTenantIdMasked();
                const deviceId = dataService.getDeviceId();
                const orgId = dataService.getOrgId();
                const isNavigator = ((_a = dataService.getIsNavigator()) === null || _a === void 0 ? void 0 : _a.toLowerCase().trim()) === 'true';
                // Initialize WebSocket client if not already connected
                if (!wsClient) {
                    wsClient = WebSocketClient.getInstance(serverUrl, 5000, true);
                    wsClient.connect();
                    console.log('dataService.isActivated ' + dataService.isActivated());
                    if (!dataService.isActivated()) {
                        const initMessage = {
                            event: 'init',
                            payload: {
                                tenantMask: tenantMask,
                                deviceParam: {
                                    'user-agent': navigator.userAgent,
                                    width: window.innerWidth,
                                    height: window.innerHeight,
                                    'web-gl': webGL, // Example flag
                                },
                                timestamp: Date.now(),
                            },
                        };
                        if (deviceId) {
                            initMessage.payload['deviceId'] = deviceId;
                        }
                        if (orgId) {
                            initMessage.payload['orgId'] = orgId;
                        }
                        if (isNavigator) {
                            initMessage.payload['isNav'] = isNavigator;
                        }
                        wsClient.sendMessage(initMessage);
                    }
                    const rms_token = dataService.getRMSToken();
                    const heartbeatContext = {
                        event: 'heartbeat',
                        payload: { token: rms_token },
                    };
                    wsClient.setHeartbeatContext(heartbeatContext);
                    wsClient.startHeartbeat(10000);
                    // Attach message handler
                    wsClient.addMessageHandler((message) => {
                        switch (message.event) {
                            case 'init':
                                notifySubscribers('init', message.payload);
                                break;
                            case 'setToken':
                                //store.dispatch(setBuildingCount(message.payload.count));
                                notifySubscribers('setToken', message.payload);
                                break;
                            case 'showFrame':
                                /* store.dispatch(
                                  setFloorDetails({
                                      name: message.payload.name,
                                      details: message.payload.details,
                                    })
                                  );*/
                                notifySubscribers('showFrame', message.payload);
                                break;
                            case 'refresh':
                                window.location.reload();
                                break;
                            case 'delete':
                                dataService.clearAllClientCache();
                                window.location.href = 'about:blank';
                                break;
                            default:
                                console.warn('Unknown WebSocket event:', message.event);
                        }
                    });
                    // Update WebSocket connection status in Redux
                    const updateConnectionStatus = () => {
                        const isConnected = wsClient
                            ? wsClient.isWebSocketConnected()
                            : false;
                        store.dispatch(setWebSocketStatus(isConnected));
                    };
                    setInterval(updateConnectionStatus, 1000);
                }
                break;
            }
            case 'CONFIGURE_HEARTBEAT_UPDATE_MESSAGE':
                {
                    const { message } = action.payload;
                    if (wsClient) {
                        wsClient.setHeartbeatContext(action.payload);
                        break;
                    }
                    // Update WebSocket connection status in Redux
                    const updateConnectionStatus = () => {
                        const isConnected = wsClient
                            ? wsClient.isWebSocketConnected()
                            : false;
                        store.dispatch(setWebSocketStatus(isConnected));
                    };
                    setInterval(updateConnectionStatus, 1000);
                }
                break;
            case 'CONFIGURE_SEND_WS_MESSAGE': {
                const { message } = action.payload;
                if (wsClient) {
                    wsClient.sendMessage(message);
                }
                break;
            }
            case 'CONFIGURE_APP_STOP': {
                if (wsClient) {
                    wsClient.stopHeartbeat();
                    wsClient.disconnect();
                    wsClient = null;
                }
                break;
            }
            default:
                return next(action);
        }
    };
};
// Export subscription function
export const subscribeToWebSocketEvent = (event, callback) => {
    return subscribeToEvent(event, callback);
};
export default configureWebsocketMiddleware;
