import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Flex } from '@magnetic/flex';
import { getLocaleString, isStatusAvailable } from '../../util';
import { useTranslation } from 'react-i18next';
const BookingSlotTimestampsContainer = styled(Flex) `
  font-family: 'CiscoSans Thin';
  font-size: 1.4vw;
  padding-top: 0.6vw;
  margin-top: ${(props) => (props.widgetCount === 0 ? '8vw' : '3vw')};
`;
const BookingSlotTimestamps = memo(({ end, timeZone, widgetCount, availabilityStatus }) => {
    const { t } = useTranslation();
    // const start = Date.now() / 1000;
    const [start, setStart] = useState(Date.now() / 1000);
    const startTimeStamp = getLocaleString(start, timeZone);
    const endTimeStamp = getLocaleString(Math.max(end, start), timeZone);
    useEffect(() => {
        const timer = setInterval(() => {
            setStart(Date.now() / 1000);
        }, 10000);
        return () => {
            clearInterval(timer);
        };
    }, []);
    // useEffect(() => {
    //   const timer = setInterval(() => {
    //     const timestampsContainer = document.getElementById(
    //       'timestamps-container'
    //     );
    //     if (timestampsContainer) {
    //       timestampsContainer.innerText = `${getLocaleString(Date.now() / 1000, timeZone)} ${t('navigator.to')} ${endTimeStamp}`;
    //     }
    //   }, 1000);
    //   return () => {
    //     clearInterval(timer);
    //   };
    // }, []);
    return (_jsx(BookingSlotTimestampsContainer, Object.assign({ widgetCount: widgetCount, justify: "flex-end", id: "timestamps-container" }, { children: isStatusAvailable(availabilityStatus) &&
            `${startTimeStamp} ${t('navigator.to')} ${endTimeStamp}` })));
});
export default BookingSlotTimestamps;
