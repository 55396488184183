import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CenteredDiv } from '../styles/commonstyles';
const ErrorMessage = styled.div `
    font-weight: 400;
    font-size: 2vw;
    opacity: 0.8;
`;
const ErrorSubMessage = styled.div `
    padding-top: 0.5vw;
    font-size: 1vw;
    opacity: 0.5;
`;
const Error = () => {
    const { t } = useTranslation();
    return (_jsxs(CenteredDiv, { children: [_jsx("img", { src: require('../../assets/images/cisco_spaces_logo.png'), alt: "Cisco Spaces logo" }), _jsx("br", {}), _jsxs(ErrorMessage, { children: [" ", t('common.error.try-again-text')] }), _jsx(ErrorSubMessage, { children: t('common.error.try-again-support-text') })] }));
};
export default Error;
