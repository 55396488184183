import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Flex } from '@magnetic/flex';
import FluidGrid from '@allpro/react-fluid-grid';
import { TelemetryWidget } from '../../common/telemetry-widget';
import { getContext, getBuildingAnalytics } from '../../redux/spaces-slice';
const VerticleView = ({ config, analytics }) => (_jsx(Flex, Object.assign({ basis: 440, gap: 12, direction: "verticle", align: "flex-end" }, { children: _jsxs(FluidGrid, Object.assign({ container: true, spacing: 12, columnSpacing: "24px", flexBasis: "150px", minWidth: "50%" }, { children: [config.showAirQuality && (_jsx(FluidGrid, Object.assign({ item: true }, { children: _jsx(TelemetryWidget, { size: "sm", type: 'IAQ', status: analytics.airQuality }) }))), config.showCO2 && (_jsx(FluidGrid, Object.assign({ item: true }, { children: _jsx(TelemetryWidget, { size: "sm", type: 'CO2', status: analytics.co2 }) }))), config.showTemperature && (_jsx(FluidGrid, Object.assign({ item: true }, { children: _jsx(TelemetryWidget, { size: "sm", type: 'TEMPERATURE', status: analytics.ambientTemp }) }))), config.showHumidity && (_jsx(FluidGrid, Object.assign({ item: true }, { children: _jsx(TelemetryWidget, { size: "sm", type: 'HUMIDITY', status: analytics.relativeHumidity }) }))), true && (_jsx(FluidGrid, Object.assign({ item: true }, { children: _jsx(TelemetryWidget, { size: "sm", type: 'AMBIENT_NOISE', status: `noise_${analytics.ambientNoise}` }) })))] })) })));
const HorizontalView = ({ config, analytics }) => (_jsx(Flex, Object.assign({ weight: 1, grow: true, gap: 12, direction: "verticle" }, { children: _jsx(Flex, Object.assign({ justify: "space-between" }, { children: _jsx(WidgetsGroup, { config: config, analytics: analytics }) })) })));
const WidgetsGroup = ({ config, analytics }) => {
    const widgets = [];
    if (config.showAirQuality) {
        widgets.push(_jsx(TelemetryWidget, { type: 'IAQ', status: analytics.airQuality }));
    }
    if (config.showCO2) {
        widgets.push(_jsx(TelemetryWidget, { type: 'CO2', status: analytics.co2 }));
    }
    if (config.showTemperature) {
        widgets.push(_jsx(TelemetryWidget, { type: 'TEMPERATURE', status: analytics.ambientTemp }));
    }
    if (config.showHumidity) {
        widgets.push(_jsx(TelemetryWidget, { type: 'HUMIDITY', status: analytics.relativeHumidity }));
    }
    if (config.showAmbientNoise) {
        widgets.push(_jsx(TelemetryWidget, { type: 'AMBIENT_NOISE', status: `noise_${analytics.ambientNoise}` }));
    }
    return widgets;
};
export function BuildingViewAnalytics({ occupancy }) {
    const context = useSelector(getContext);
    const buildingConfig = context.appConfig.building;
    const analytics = useSelector(getBuildingAnalytics);
    const [telemetryAnalytics, setTelemetryAnalytics] = useState(null);
    useEffect(() => {
        console.log('BuildingViewAnalytics', analytics);
        if (analytics.lastUpdate) {
            setTelemetryAnalytics(analytics);
        }
    }, [analytics.lastUpdate]);
    return occupancy
        ? telemetryAnalytics && (_jsx(VerticleView, { config: buildingConfig, analytics: telemetryAnalytics }))
        : telemetryAnalytics && (_jsx(HorizontalView, { config: buildingConfig, analytics: telemetryAnalytics }));
}
