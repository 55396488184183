import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { DataService } from '../../app/configure/service/deviceservice';
function useTokenNavigation(isDomainAvailable) {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const dataService = new DataService();
    const signageToken = searchParams.get('token');
    const [token, setToken] = useState('');
    useEffect(() => {
        console.log('useTokenNavigation', signageToken);
        const viewToken = signageToken
            ? signageToken
            : dataService.getDeviceToken();
        dataService.setDeviceToken(viewToken);
        setToken(viewToken);
    }, [signageToken]);
    useEffect(() => {
        console.log('useTokenNavigation', token, isDomainAvailable);
        if (token && isDomainAvailable) {
            console.log('navigating to home');
            navigate('/home', { state: { token } });
        }
    }, [token, isDomainAvailable]);
    return null;
}
export default useTokenNavigation;
