import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex } from '@magnetic/flex';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import NatsClient from '../../../../store/natsclient';
import BrandLogo from '../../common/brand-logo';
import { TelemetryWidget } from '../../common/telemetry-widget';
import CountsWidget from '../components/counts-widget';
import FloorInfo from '../components/floor-info';
import Header from '../components/header';
import Footer from '../components/footer';
import Line from '../components/line/index.styled';
import MapComponent from '../components/map/index';
import FloorLayout from './floor-layout';
import { MapProvider } from './MapContext';
import WebGLModal from './web-gl-modal';
import { NavigateOnPhone } from '../components/navigate-on-phone';
import RoomDetailsPopup, { closeRoomDetailsPopup, } from '../components/room-details-popup';
import { useNavigate, useLocation } from 'react-router-dom';
import { getSmartRooms, getUIState, setRoomDetailsPopUp, } from '../../../../store/uislice';
import { store } from '../../../../store/store';
import { getOccupancyStatus, smartRoomsCall } from '../util';
import { getLocation, selectFloorById, getRoomAnalytics, } from '../../redux/spaces-slice';
import HowToUseThis from '../components/how-to-use-this';
import { getContext } from '../../redux/spaces-slice';
import { hasValue } from '../../navigator/util';
import './index.scss';
import ResetView from '../../common/reset-view';
const FloorView = ({ id }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const navigate = useNavigate();
    const location = useLocation();
    const floorLocationId = location.state.id || id;
    const locationData = useSelector(getLocation);
    const contextInfo = useSelector(getContext);
    if (Object.keys(contextInfo).length === 0) {
        navigate('/home');
        return _jsx(_Fragment, {});
    }
    const floorAnalyticsCurrentFloor = useSelector((state) => selectFloorById(state, floorLocationId));
    const airQuality = (_a = floorAnalyticsCurrentFloor === null || floorAnalyticsCurrentFloor === void 0 ? void 0 : floorAnalyticsCurrentFloor.airQuality) !== null && _a !== void 0 ? _a : null;
    const ambientTemp = (_b = floorAnalyticsCurrentFloor === null || floorAnalyticsCurrentFloor === void 0 ? void 0 : floorAnalyticsCurrentFloor.ambientTemp) !== null && _b !== void 0 ? _b : null;
    const co2 = (_c = floorAnalyticsCurrentFloor === null || floorAnalyticsCurrentFloor === void 0 ? void 0 : floorAnalyticsCurrentFloor.co2) !== null && _c !== void 0 ? _c : null;
    const peopleCount = (_d = floorAnalyticsCurrentFloor === null || floorAnalyticsCurrentFloor === void 0 ? void 0 : floorAnalyticsCurrentFloor.peopleCount) !== null && _d !== void 0 ? _d : null;
    const capacity = (_e = floorAnalyticsCurrentFloor === null || floorAnalyticsCurrentFloor === void 0 ? void 0 : floorAnalyticsCurrentFloor.capacity) !== null && _e !== void 0 ? _e : null;
    const relativeHumidity = (_f = floorAnalyticsCurrentFloor === null || floorAnalyticsCurrentFloor === void 0 ? void 0 : floorAnalyticsCurrentFloor.relativeHumidity) !== null && _f !== void 0 ? _f : null;
    const occupancyStatus = getOccupancyStatus(peopleCount, capacity);
    const appConfigFloor = (_g = contextInfo === null || contextInfo === void 0 ? void 0 : contextInfo.appConfig) === null || _g === void 0 ? void 0 : _g.floor;
    const showAirQuality = appConfigFloor === null || appConfigFloor === void 0 ? void 0 : appConfigFloor.showAirQuality;
    const showCO2 = appConfigFloor === null || appConfigFloor === void 0 ? void 0 : appConfigFloor.showCO2;
    const showFloorOccupancy = appConfigFloor === null || appConfigFloor === void 0 ? void 0 : appConfigFloor.showFloorOccupancy;
    const showHumidity = appConfigFloor === null || appConfigFloor === void 0 ? void 0 : appConfigFloor.showHumidity;
    const showTemperature = appConfigFloor === null || appConfigFloor === void 0 ? void 0 : appConfigFloor.showTemperature;
    const isWayFindingEnabled = contextInfo === null || contextInfo === void 0 ? void 0 : contextInfo.isWayFindingEnabled;
    const { tenantId, buildingLocationId, zoom, mapCenterLatLng, pitch, bearing, modelWithScreen = true, deviceLatLng, rotateAngle, deviceLocationId, } = contextInfo;
    const isSmartRoomsCallMade = useSelector(getSmartRooms).length != 0;
    if (!isSmartRoomsCallMade)
        smartRoomsCall(tenantId);
    const buildingName = locationData === null || locationData === void 0 ? void 0 : locationData.name;
    const floorLocationData = (_h = locationData === null || locationData === void 0 ? void 0 : locationData.children) === null || _h === void 0 ? void 0 : _h.find((child) => child.id === floorLocationId);
    const floorNumber = floorLocationData === null || floorLocationData === void 0 ? void 0 : floorLocationData.floorNumber;
    const floorName = floorLocationData === null || floorLocationData === void 0 ? void 0 : floorLocationData.name;
    const deviceLocationFloorNumber = (_k = (_j = locationData === null || locationData === void 0 ? void 0 : locationData.children) === null || _j === void 0 ? void 0 : _j.find((child) => child.id === deviceLocationId)) === null || _k === void 0 ? void 0 : _k.floorNumber;
    const floorNumberIdMap = {};
    // TODO Optimize below subscription
    useEffect(() => {
        if ((locationData === null || locationData === void 0 ? void 0 : locationData.id) && floorLocationId) {
            const subject = `signageAnalytical.${locationData.id}.${floorLocationId}.*`;
            console.log('Floor view subject :: ', subject);
            NatsClient.subscribe([subject]);
            for (const floorNumber of locationData === null || locationData === void 0 ? void 0 : locationData.children) {
                floorNumberIdMap[floorNumber.floorNumber] = floorNumber.id;
            }
        }
    }, [floorLocationId, locationData.id]);
    const BrandLogoContainer = styled.div `
    width: 10.3vw;

    img {
      width: 100%;
      height: 100%;
    }
  `;
    const content = () => (_jsxs(Flex, Object.assign({ style: {
            gap: '1vw',
            width: '99.2vw',
        } }, { children: [_jsx(Flex, Object.assign({ direction: "horizontal", grow: true, basis: 1250, justify: "center", style: {
                    backgroundColor: '#1F2226',
                    width: 'fit-content',
                    maxWidth: '34vw',
                    height: '9vh',
                    borderRadius: '1.2vw',
                    margin: '1vw 0.4vw 1.2vw 1.2vw',
                    gap: '1vw',
                    display: 'inline-block',
                    flex: '1 1 100vw',
                } }, { children: _jsxs(Flex, Object.assign({ align: "center", style: {
                        alignItems: 'center',
                        display: 'flex',
                        flex: '0 1 auto',
                        flexFlow: 'row',
                        gap: '1vw',
                        justifyContent: 'flex-start',
                        margin: '1vw 0',
                        width: 'fit-content',
                    } }, { children: [_jsx(BrandLogoContainer, { children: _jsx(BrandLogo, {}) }), _jsx(Line, { orientation: "vertical" }), _jsx(FloorInfo, { floorId: floorLocationId, building: buildingName, floor: floorName, selectFloor: (id) => {
                                if (id.toLowerCase() === 'all') {
                                    navigate('/home', { state: { id: buildingLocationId } });
                                }
                                else {
                                    // setShowFloorSelectorModal(false);
                                    // setShowView(false);
                                    // setSelectedLocation({ id });
                                    // setSearchInput(null);
                                    navigate('/floor', { state: { id } });
                                }
                            } })] })) })), _jsxs(Flex, Object.assign({ direction: "horizontal", justify: "space-between" }, { children: [showFloorOccupancy &&
                        hasValue(getOccupancyStatus(peopleCount, capacity)) && (_jsx(Flex, Object.assign({ align: "center", style: { margin: '0vw 0.2vw' } }, { children: _jsx(TelemetryWidget, { type: 'OCCUPANCY', status: occupancyStatus, size: 'xsm' }) }))), showAirQuality && hasValue(airQuality) && (_jsx(Flex, Object.assign({ align: "center", style: { margin: '0.4vw' } }, { children: _jsx(TelemetryWidget, { type: 'IAQ', status: airQuality, size: 'xsm' }) }))), showCO2 && hasValue(co2) && (_jsx(Flex, Object.assign({ align: "center", style: { margin: '0.4vw' } }, { children: _jsx(TelemetryWidget, { type: 'CO2', status: co2, size: 'xsm' }) }))), showTemperature && hasValue(ambientTemp) && (_jsx(Flex, Object.assign({ align: "center", style: { margin: '0.4vw' } }, { children: _jsx(TelemetryWidget, { type: 'TEMPERATURE', status: ambientTemp, size: 'xsm' }) }))), showHumidity && hasValue(relativeHumidity) && (_jsx(Flex, Object.assign({ align: "center", style: { margin: '0.4vw' } }, { children: _jsx(TelemetryWidget, { type: 'HUMIDITY', status: relativeHumidity, size: 'xsm' }) })))] })), _jsx(HowToUseThis, {})] })));
    const mapDetails = {
        map: {
            tenantId: tenantId,
            buildingId: buildingLocationId,
            zoom: zoom,
            long: mapCenterLatLng[1],
            lat: mapCenterLatLng[0],
            pitch: pitch,
            bearing: bearing,
            floorNumber: Number(floorNumber),
            floorLocation: floorNumber,
        },
        device: {
            modelWithScreen: modelWithScreen,
            deviceLocation: deviceLocationFloorNumber,
            long: deviceLatLng && deviceLatLng[1],
            lat: deviceLatLng && deviceLatLng[0],
            xAngle: rotateAngle && rotateAngle[0],
            yAngle: rotateAngle && rotateAngle[1],
            zAngle: rotateAngle && rotateAngle[2],
        },
    };
    const [hasWebGLSupport, setHasWebGLSupport] = useState(useSelector((state) => { var _a, _b; return ((_b = (_a = state === null || state === void 0 ? void 0 : state.location) === null || _a === void 0 ? void 0 : _a.contextInfo) === null || _b === void 0 ? void 0 : _b.hasWebGLSupport) || false; }));
    const showRoomDetailsPopup = (richMapId) => {
        store.dispatch(setRoomDetailsPopUp({ payload: richMapId }));
    };
    useEffect(() => {
        const timer = setTimeout(() => {
            setHasWebGLSupport(true);
            console.log('set webgl popup to true');
        }, 50);
        return () => clearTimeout(timer);
    }, []);
    const getRoomAvailabilityForHighlighting = (roomsData) => {
        var _a, _b, _c, _d;
        const roomAvailability = {};
        for (const room in roomsData) {
            if (!roomsData[room] || !((_a = roomsData[room]) === null || _a === void 0 ? void 0 : _a.availability))
                continue;
            const availabilityStatus = ((_b = roomsData[room]) === null || _b === void 0 ? void 0 : _b.availability) === 'noData'
                ? 'Unknown'
                : (_c = roomsData[room]) === null || _c === void 0 ? void 0 : _c.availability;
            roomAvailability[room] = {
                availability: availabilityStatus,
                richMapId: (_d = roomsData[room]) === null || _d === void 0 ? void 0 : _d.richMapId,
            };
        }
        return roomAvailability;
    };
    const roomsData = useSelector(getRoomAnalytics);
    const roomAvailability = getRoomAvailabilityForHighlighting(roomsData);
    const deskAvailability = {};
    /* -------------------- */
    const [inactiveDevice, setInactiveDevice] = useState(false);
    const [shouldReset, setShouldReset] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const resetTime = 30;
    const [resetCounter, setResetCounter] = useState(resetTime);
    const [isSignageActive, setIsSignageActive] = useState(true);
    const [startInactiveTimeout, setStartInactiveTimeout] = useState(false);
    const [inactiveTimeoutId, setInactiveTimoutId] = useState(null);
    const [isTimeoutPopupOpen, setIsTimeoutPopupOpen] = useState(false);
    const INACTIVITY_TIMEOUT = 240; // seconds
    const viewType = contextInfo === null || contextInfo === void 0 ? void 0 : contextInfo.viewType;
    const isBuilding = viewType === 'building';
    const proximity = { presence: true };
    useEffect(() => {
        setIsDirty(false);
        setShouldReset(false);
    }, []);
    const loopResetCounter = () => {
        if (resetCounter > 0) {
            const count = resetCounter - 1;
            if (isBuilding) {
                setShouldReset(count === 0);
            }
            setResetCounter(count);
        }
        else {
            // reset view
            setResetViewAction({ action: 'reset' });
            setIsSignageActive(true);
            setInactiveDevice(false);
        }
    };
    const initiateInactiveTimeout = () => {
        const timeout = setTimeout(() => {
            setIsTimeoutPopupOpen(true);
            setIsSignageActive(false);
        }, 1000 * INACTIVITY_TIMEOUT);
        setInactiveTimoutId(() => timeout);
        return timeout;
    };
    const resetTimeout = () => {
        if (!isTimeoutPopupOpen) {
            setStartInactiveTimeout((startInactiveTimeout) => !startInactiveTimeout);
        }
    };
    useEffect(() => {
        if (isTimeoutPopupOpen) {
            return;
        }
        setIsSignageActive(true);
        clearTimeout(inactiveTimeoutId);
        setResetCounter(() => resetTime);
        initiateInactiveTimeout();
        setInactiveDevice(false);
    }, [startInactiveTimeout]);
    useEffect(() => {
        window.addEventListener('mousedown', resetTimeout);
        window.addEventListener('mousemove', resetTimeout);
        window.addEventListener('touchstart', resetTimeout);
        window.addEventListener('touchmove', resetTimeout);
    }, []);
    useEffect(() => {
        if (!isSignageActive) {
            setInactiveDevice(true);
            setTimeout(() => {
                loopResetCounter();
            }, 1500);
        }
        else {
            setIsTimeoutPopupOpen(false);
            setResetCounter(() => resetTime);
            setInactiveDevice(false);
        }
    }, [isSignageActive]);
    useEffect(() => {
        setTimeout(() => {
            if (inactiveDevice) {
                loopResetCounter();
            }
        }, 1050);
    }, [resetCounter]);
    useEffect(() => {
        if (isDirty) {
            setTimeout(() => {
                const presence = proximity.presence;
                if (!presence) {
                    setInactiveDevice(true);
                    setTimeout(() => {
                        loopResetCounter();
                    }, 1500);
                }
                else {
                    setResetCounter(resetTime);
                    setInactiveDevice(false);
                }
            }, 1000); // Wait for 1 secs to show idle timeout popup
        }
    }, [proximity.presence]);
    const setResetViewAction = ({ action }) => {
        console.log('setResetViewAction', action);
        if (action) {
            setInactiveDevice(false);
            switch (action) {
                case 'continue':
                    console.log('Continue as it is');
                    setShouldReset(false);
                    break;
                case 'reset':
                    console.log('reset event called');
                    if (!(contextInfo === null || contextInfo === void 0 ? void 0 : contextInfo.deviceLocationId)) {
                        // Where-Am-I Disabled
                        if (isBuilding) {
                            closeRoomDetailsPopup();
                            navigate('/home', { state: { id: buildingLocationId } });
                        }
                        return;
                    }
                    setTimeout(() => {
                        closeRoomDetailsPopup();
                    }, 100); // This will also re-center map
                    setShouldReset(true);
                    setIsDirty(false);
                    setTimeout(() => {
                        setShouldReset(false);
                    }, 2000);
                    break;
                default:
                    console.log('>>> Not able to detect action type, going to reload the page in 3 secs ...');
                    window.location.reload();
                    break;
            }
        }
    };
    const richMapId = (_l = useSelector(getUIState)) === null || _l === void 0 ? void 0 : _l.popupRichMapID;
    return (_jsxs(FloorLayout, { children: [_jsx(ResetView, { inactiveDevice: inactiveDevice, counter: resetCounter, maxTime: resetTime, setAction: setResetViewAction, shouldReset: shouldReset, setIsSignageActive: setIsSignageActive, addEventListeners: {} }), !hasWebGLSupport && _jsx(WebGLModal, {}), hasWebGLSupport && (_jsx(MapProvider, { children: _jsx(MapComponent, { floorId: floorLocationId, mapDetails: mapDetails, showRoomDetailsPopup: showRoomDetailsPopup, roomAvailability: roomAvailability, deskStatus: deskAvailability }) })), _jsx(Header, { children: content() }), isWayFindingEnabled && _jsx(NavigateOnPhone, {}), _jsx("div", Object.assign({ className: "left-panel" }, { children: _jsx(CountsWidget, { floorId: floorLocationId, forRooms: true }) })), _jsx(Footer, {}), richMapId && _jsx(RoomDetailsPopup, { floorId: floorLocationId })] }));
};
export default FloorView;
