import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StyledFloorCircle from '../FloorCircle/Styled-FloorCircle';
import * as C from '../../../../../../constants';
import { useTranslation } from 'react-i18next';
const StyledDetailedFloorRow = styled.div `
  display: flex;
  align-items: center;
  padding-bottom: 2vw;
  cursor: pointer;
  .floor-details {
    flex-grow: 1;
    padding-left: 1.25vw;
    padding-right: 1.25vw;
  }
  .floor {
    font-family: CiscoSansRegular;
    font-weight: 400;
    font-size: 1.6875vw;
    line-height: 2.4375vw;
  }
  .floor-occupancy {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.125vw;
    font-weight: 400;
  }
  .floor-status {
    padding-top: 0.25vw;
    font-size: 1.125vw;
    font-weight: 700;
    color: ${(props) => { var _a; return C.FLOOR_STATUS_COLOR[(_a = props === null || props === void 0 ? void 0 : props.floorStatus) === null || _a === void 0 ? void 0 : _a.toUpperCase()]; }};
  }
  
  .animated-progress { 
    margin-top: 0.25vw
    max-width: 100%;
    height: 0.875vw;
    border-radius: 0.4vw;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    background-color: #2d3134;
    overflow: hidden;
    position: relative;
  }
  
  .animated-progress span {
    height: 100%;
    display: block;
    width: 0;
    color: rgb(255, 251, 251);
    border-radius: 0.4vw;
    position: absolute;
    text-align: end;
    padding-right: ${(props) => props.percentage}%;
  }

  .progress-blue span {
    background-color: #00bceb;
  }
  
`;
export default function DetailedFloorRow({ floorLevel, floorStatus, isSelected, percentage, selectLocation, floor, showFloorOccupancy, }) {
    const { t } = useTranslation();
    return (_jsxs(StyledDetailedFloorRow, Object.assign({ floorStatus: floorStatus, percentage: percentage, className: "building_select_floor" }, { children: [_jsx(StyledFloorCircle, { floorLevel: floorLevel, isSelected: isSelected, selectLocation: selectLocation, floor: floor, isDetailed: true }), _jsxs("div", Object.assign({ className: "floor-details", onClick: () => selectLocation(Object.assign({}, floor)) }, { children: [_jsx("div", Object.assign({ className: "floor" }, { children: `Floor ${floorLevel}` })), showFloorOccupancy && (_jsxs("div", Object.assign({ className: "floor-occupancy" }, { children: [_jsx("div", { children: [t('occupancy'), ':'] }), _jsx("div", Object.assign({ className: "floor-status" }, { children: t(`current-occupancy.${floorStatus}`) }))] }))), showFloorOccupancy && (_jsx("div", Object.assign({ className: "animated-progress progress-blue" }, { children: _jsx("span", {}) })))] }))] })));
}
DetailedFloorRow.propTypes = {
    floorLevel: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    floorStatus: PropTypes.string,
    isSelected: PropTypes.bool,
    percentage: PropTypes.number,
};
DetailedFloorRow.defaultProps = {
    floorLevel: '',
    floorStatus: '',
    isSelected: false,
    percentage: 0,
};
