import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Suspense } from 'react';
import useTokenNavigation from '../hook/token-navigation';
import useDomainParam from '../hook/domain-param';
function WelcomeLayout() {
    console.log('WelcomeLayout::');
    const isDomainAvailable = useDomainParam();
    useTokenNavigation(isDomainAvailable);
    useEffect(() => {
        console.log('WelcomeLayout::isDomainAvailable:', isDomainAvailable);
    }, [isDomainAvailable]);
    return (_jsx("main", { children: _jsx(Suspense, Object.assign({ fallback: _jsx("div", { children: "Loading..." }) }, { children: _jsx(Outlet, {}) })) }));
}
export default WelcomeLayout;
