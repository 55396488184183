var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useRef, useState } from 'react';
import { useRegisterMutation, useContextQuery, useRefreshMutation, } from '../../service/api';
import NatsClient from '../../../../store/natsclient';
import { expiresIn, isExpired } from '../../../../common/util';
import { DataService } from '../../../configure/service/deviceservice';
import { CacheService } from '../../../../common/util/cacheservice';
const useWorkspaceEffects = (state) => {
    const app_module = 'signage-module';
    const LOCAL_STORAGE_PUBLIC_KEY = 'nats_public_key';
    const dataService = new DataService();
    const effectRan = useRef(false);
    const [skip, setSkip] = useState(true);
    const [useRegister, { isSuccess }] = useRegisterMutation();
    const [useRefresh, { data: refreshData }] = useRefreshMutation();
    const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwt_token'));
    const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refresh_token'));
    const stateToken = state ? state.token : null;
    const viewToken = stateToken ? state.token : dataService.getDeviceToken();
    const [viewType, setViewType] = useState('');
    const { data: contextData } = useContextQuery(viewToken, { skip });
    const cacheService = CacheService.getInstance(app_module);
    const publicKey = cacheService.get(LOCAL_STORAGE_PUBLIC_KEY);
    useEffect(() => {
        console.log('useWorkspaceEffects::jwtToken:', jwtToken);
        if (refreshToken === null || refreshToken === '') {
            console.error('No refresh token found, cannot refresh jwt token');
            return;
        }
        setInterval(() => {
            console.log('Checking for token expiry');
            if (refreshToken === null) {
                console.error('refreshToken is null, cannot refresh jwt token');
                return;
            }
            if (isExpired(refreshToken)) {
                console.error('refresh token is expired, cannot refresh jwt token');
                return;
            }
            if (jwtToken === '' || jwtToken === null || expiresIn(jwtToken, 60)) {
                console.log('jwt token is expired or not available, going to refresh');
                useRefresh({
                    refreshToken: refreshToken,
                });
            }
            else {
                console.debug('jwt token not expired, no need to refresh');
            }
        }, 5 * 60 * 1000);
    }, [state, refreshToken]);
    useEffect(() => {
        if (!effectRan.current) {
            if (refreshToken === null) {
                console.log('No refresh token found');
                if (!publicKey ||
                    publicKey === '' ||
                    dataService.getDomain() !== location.hostname) {
                    console.log('No public key found. Going to register public key');
                    const newPublicKey = NatsClient.getPublicKey();
                    useRegister({
                        publicKey: newPublicKey,
                        tenantMask: dataService.getTenantIdMasked(),
                        deviceId: dataService.getDeviceId(),
                    });
                }
            }
            else {
                console.log('Public key already registered. Getting context data');
                setSkip(false);
            }
        }
        return () => {
            effectRan.current = true;
        };
    }, [state, useRegister]);
    useEffect(() => {
        if (isSuccess) {
            console.log('Successfully registered publickey');
            setSkip(false);
        }
    }, [isSuccess]);
    useEffect(() => {
        if (refreshData) {
            console.log('received refresh response', refreshData, 'going to set jwt token and refreshToken');
            const response = refreshData;
            console.log('received refresh response', response);
            localStorage.setItem('jwt_token', response.jwt);
            localStorage.setItem('refresh_token', response.refreshToken);
            setJwtToken(response.jwt);
            setRefreshToken(response.refreshToken);
        }
    }, [refreshData]);
    useEffect(() => {
        if (contextData) {
            const { host, streamName, port, subscriptionInfo, viewType, jwt, refreshToken, } = contextData;
            localStorage.setItem('jwt_token', jwt);
            localStorage.setItem('refresh_token', refreshToken);
            setJwtToken(jwt);
            setRefreshToken(refreshToken);
            const connectToServer = () => __awaiter(void 0, void 0, void 0, function* () {
                console.log('Going to connect to server with', host, port, jwt, streamName);
                yield NatsClient.connectToNatServer(`${host}:${port}`, jwt, streamName);
                yield NatsClient.getMessageForSubject(subscriptionInfo.locationHierarchy);
                const subjects = subscriptionInfo.analytics.concat(subscriptionInfo.locationHierarchy);
                NatsClient.addGlobalSubscriptions(subjects);
                setViewType(viewType);
            });
            connectToServer();
        }
    }, [contextData]);
    return { viewType };
};
export default useWorkspaceEffects;
