import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import DisplayCode from './components/display-code/display-code';
import { GlobalStyle, FullPageContainer, Content, } from '../../common/styles/commonstyles';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import useDomainParam from '../../common/hook/domain-param';
export function Welcome() {
    var _a, _b, _c, _d;
    const isDomainAvailable = useDomainParam();
    const { tm } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const resolvedTm = tm || ((_a = location.state) === null || _a === void 0 ? void 0 : _a.tm) || '';
    const urlParams = new URLSearchParams(window.location.search);
    const deviceId = urlParams.get('deviceId') || ((_b = location.state) === null || _b === void 0 ? void 0 : _b.deviceId) || null;
    const orgId = urlParams.get('orgId') || ((_c = location.state) === null || _c === void 0 ? void 0 : _c.orgId) || null;
    const isNav = urlParams.get('isNav') || ((_d = location.state) === null || _d === void 0 ? void 0 : _d.isNav) || null;
    useEffect(() => {
        // Automatically navigate to / with tm in state
        if (tm) {
            navigate('/', {
                replace: true,
                state: { tm, deviceId, orgId, isNav }, // Pass 'tm' as state
            });
        }
    }, [navigate, tm]);
    return isDomainAvailable ? (_jsxs(_Fragment, { children: [_jsx(GlobalStyle, {}), _jsx(FullPageContainer, { children: _jsx(Content, { children: _jsx(DisplayCode, { tm: resolvedTm, devciceId: deviceId, orgId: orgId, isNav: isNav }) }) })] })) : (_jsx("h1", { children: "Loading..." }));
}
