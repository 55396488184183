import { jsx as _jsx } from "react/jsx-runtime";
import { Flex } from '@magnetic/flex';
import styled from 'styled-components';
const FooterImage = require('../../../../../assets/images/floor-footer.png');
const FooterContainer = styled(Flex) `
  height: 2vh;
  z-index: 0;
  margin-top: 92.6vh;
  position: fixed;

  bottom: 3.2vw;
  right: 1vw;
`;
const FooterImg = styled.img `
  height: 4vw;
`;
const Footer = () => {
    return (_jsx(FooterContainer, Object.assign({ justify: "end" }, { children: _jsx(FooterImg, { src: FooterImage }) })));
};
export default Footer;
