import styled from 'styled-components';
const DropdownContent = styled.div `
  margin: auto;
  padding: 1vw;
  width: 70%;
  max-height: 70%;
  background: #1d2023;
  box-sizing: border-box;
  border-radius: 1vw;
  font-family: CiscoSansTT;
  font-style: normal;
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(40 42 47);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px;
  padding: 20px;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);

  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.4vw;
    margin-bottom: 1vw;
    font-family: 'CiscoSansRegular';
  }

  .popup-body {
    text-align: center;
    font-family: 'CiscoSansRegular';
    font-size: 1vw;
  }

  .floor-buttons {
    margin: 1vw;
    font-size: 2vw;
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .floor-button {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    margin: 0.75vw;
    cursor: pointer;
  }

  .floor-button.active {
    background-color: #078cae;
    color: #fff;
  }
  .floor-button.active .floor-out-cir {
    border: 1px solid #00bceb;
    background: rgba(0, 188, 235, 0.53);
  }
  .floor-button.active .floor-inn-cir {
    background: rgba(0, 188, 235, 0.28);
    box-shadow: 0px 5px 20px #00bceb;
  }
  .floor-out-cir {
    -webkit-border-radius: 50%;
    border-radius: 50%;
    box-shadow: 0;
    --color: #26282b;
    width: 5.21vw;
    height: 5.21vw;
    padding: 0.21vw;
    border: 1px solid #383b3d;
    background: rgba(0, 0, 0, 0);
  }
  .floor-inn-cir {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    box-shadow: 0;
    border: 1px solid #383b3d;

    width: -webkit-fill-available;
    height: -webkit-fill-available;
    font-size: 1.7vw;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    text-align: center;
    cursor: allowed;
    opacity: 1;
    background: #2d3134;
  }
  .floor-sel {
    margin-top: -0.875vw;
    position: relative;
    display: flex;
    font-family: CiscoSansTT Regular;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-width: 5.125vw;
    background: #000000;
    border-radius: 1vw;
    color: #ffffff;
    font-weight: 400;
    font-size: 0.6375vw;
    line-height: 1.375vw;
  }
  .floor-button.active .floor-sel {
    border-bottom: 1px solid #00bceb;
  }

  .highlight {
    box-shadow: rgb(0, 188, 235) 0px 1.2px 10px;
    background: rgba(0, 188, 235, 0.9);
  }

  .close-button {
    border: none;
    background-color: transparent;
    color: #ffffff;
    cursor: pointer;
    font-family: 'CiscoSansRegular';
    font-size: 1.4vw;
    margin-top: -4vw;
  }
`;
export default DropdownContent;
