import Styled from 'styled-components';
import TimeLine from './index';
const statusMap = {
    available: {
        border: '2px solid #71FF31',
        'background-color': '#6ABF4B',
        color: '#71FF31',
    },
    occupied: {
        border: '2px solid #FCA5B8',
        'background-color': '#A5314B',
        color: '#FCA5B8',
    },
    occupied_booked: {
        border: '2px solid #FCA5B8',
        'background-color': '#A5314B',
        color: '#FCA5B8',
    },
    hold: {
        border: '2px solid #00BCEB',
        'background-color': '#2196F3',
        color: '#00BCEB',
    },
    'on-hold': {
        border: '2px solid #00BCEB',
        'background-color': '#2196F3',
        color: '#00BCEB',
    },
    booked: {
        border: '2px solid #F08130',
        'background-color': '#f081308a',
        color: '#F08130',
    },
};
const StyledTimeLine = Styled(TimeLine) `
    max-height: 28vh;
  && {
    border-radius: 0 0 0.5vw 0.5vw;
    width: ${(props) => (props.widgetCount !== 0 ? '100%' : '211%')};
    background-color: #2A2F33;
    overflow: hidden;
    p {
      font-family: 'CiscoSansRegular';
      font-style: normal;
      font-weight: 300;
      font-size: 0.8vw;
      line-height: 1vw;
      margin-top: 0.25vw;
      margin-bottom: 1.5vw;
    }
    ul {
      margin-left: 0rem;
      padding: 0.4vw 0.6vw 0.4vw 0.4vw;
      margin-top:0em;
      .calendar {
        position: relative;
        width: -webkit-fill-available;
        height: 18.0740vh;
        .time-slot {
          position: absolute;
          width: inherit;
        }
        .meeting {
          position: relative;
          width: 86%;
          display: grid;
          height: inherit;
          font-size: 0.4vw;
          .line {
            position: absolute;
            border-color: ${statusMap.booked.color};
            background-color: ${statusMap.booked['background-color']};
            border-radius: 0.3vw;
            opacity: 0.9;
            width: inherit;
            max-height: 100%;
            left: 2.7vw;
          }
        }
      }
      li {
        list-style: none;
        height: 45px;
        font-size: 0.6vw;
        display: grid;
        grid-template-columns: 2fr 5.5fr;
        span.line {
          border: 1px solid #fff;
          height: 1px;
          opacity: 0.3;
          position: relative;
          top: 9px;
          margin-left : 0.2vw;
        }
        &.current-time {
          grid-template-columns: 2fr 5fr;
          .hour {
            font-weight: 700;
            color: ${(props) => { var _a; return `${(_a = statusMap[props.status]) === null || _a === void 0 ? void 0 : _a.color}`; }};
            &.booked {
              color: ${statusMap.booked.color}
            }
          }
          .line {
            opacity: 1.0;
            border-color: ${(props) => { var _a; return `${(_a = statusMap[props.status]) === null || _a === void 0 ? void 0 : _a.color}`; }};
            z-index: 1999;
              &.booked {
              border-color: ${statusMap.booked.color};
            }
          }
        }
        &.meeting-time {
          grid-template-columns: 2fr 5.5fr;
          .hour {
            font-weight: 700;
            color: #fff;
          }
          .line {
            border-color: ${statusMap.booked.color};
            background-color: ${statusMap.booked['background-color']};
            border-radius: 0.3vw;
            z-index: 999;
            opacity: 0.9;
            width: -moz-available;
          }
        }
       }
      }
    }
    h1 {
      font-family: 'CiscoSansTT Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 2vw;
      line-height: 4vw;
    }
  }
`;
StyledTimeLine.defaultProps = TimeLine.defaultProps;
export default StyledTimeLine;
