import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
export default function Close({ color, onClose }) {
    return (_jsxs("svg", Object.assign({ onClick: onClose, style: { cursor: 'pointer' }, width: "30", height: "31", viewBox: "0 0 30 31", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16.7678 15.1589L22.1341 9.73578C22.6228 9.24185 22.6228 8.44348 22.1341 7.94955C21.6453 7.45562 20.8553 7.45562 20.3666 7.94955L15.0003 13.3727L9.63406 7.94955C9.14531 7.45562 8.35531 7.45562 7.86656 7.94955C7.37781 8.44348 7.37781 9.24185 7.86656 9.73578L13.2328 15.1589L7.86656 20.582C7.37781 21.0759 7.37781 21.8743 7.86656 22.3682C8.11031 22.6146 8.43031 22.7384 8.75031 22.7384C9.07031 22.7384 9.39031 22.6146 9.63406 22.3682L15.0003 16.9451L20.3666 22.3682C20.6103 22.6146 20.9303 22.7384 21.2503 22.7384C21.5703 22.7384 21.8903 22.6146 22.1341 22.3682C22.6228 21.8743 22.6228 21.0759 22.1341 20.582L16.7678 15.1589Z", fill: "#182C4A" }), _jsx("mask", Object.assign({ id: "mask0_3769_41017", style: { maskType: 'alpha' }, maskUnits: "userSpaceOnUse", x: "7", y: "7", width: "16", height: "16" }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16.7678 15.1589L22.1341 9.73578C22.6228 9.24185 22.6228 8.44348 22.1341 7.94955C21.6453 7.45562 20.8553 7.45562 20.3666 7.94955L15.0003 13.3727L9.63406 7.94955C9.14531 7.45562 8.35531 7.45562 7.86656 7.94955C7.37781 8.44348 7.37781 9.24185 7.86656 9.73578L13.2328 15.1589L7.86656 20.582C7.37781 21.0759 7.37781 21.8743 7.86656 22.3682C8.11031 22.6146 8.43031 22.7384 8.75031 22.7384C9.07031 22.7384 9.39031 22.6146 9.63406 22.3682L15.0003 16.9451L20.3666 22.3682C20.6103 22.6146 20.9303 22.7384 21.2503 22.7384C21.5703 22.7384 21.8903 22.6146 22.1341 22.3682C22.6228 21.8743 22.6228 21.0759 22.1341 20.582L16.7678 15.1589Z", fill: "white" }) })), _jsx("g", Object.assign({ mask: "url(#mask0_3769_41017)" }, { children: _jsx("rect", { width: "30", height: "30.3179", fill: color }) }))] })));
}
Close.propTypes = {
    color: PropTypes.string,
    onClose: PropTypes.func,
};
Close.defaultProps = {
    color: 'white',
    onClose: () => { },
};
