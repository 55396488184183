import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Flex } from '@magnetic/flex';
import styled from 'styled-components';
import { QRCodeSVG } from 'qrcode.react';
import { getQRCode } from '../navigate-on-phone';
import Close from '../../../../../assets/svgs/close-svg';
import { vwToPixels, STATUSES } from '../../util';
import StopWatch from '../../../navigator/util/stopwatch';
import { getSmartRooms, setRoomDetailsPopUp, } from '../../../../../store/uislice';
import { store } from '../../../../../store/store';
import { useSelector } from 'react-redux';
import { getPOIS, getUIState } from '../../../../../store/uislice';
import { TelemetryWidget } from '../../../common/telemetry-widget';
import { getWidgetCount, hasValue } from '../../../navigator/util';
import { availabilityStatusBorderMap, availabilityStatusColorMap, } from '../../util';
import moment from 'moment';
import TimeLine from './time-line/Styled-Time-Line';
import SmartRoomSVG from '../../../../../assets/images/smart-room.svg';
import DirectionsIcon from '../../../../../assets/svgs/directions';
import { useTranslation } from 'react-i18next';
import { getContext, getLocation, selectRoomById, } from '../../../redux/spaces-slice';
import natsclient from '../../../../../store/natsclient';
import { useLocation } from 'react-router-dom';
const RoomDetailsPopupContainer = styled(Flex) `
  height: fit-content;
  width: 99%;
  z-index: 1;
  right: 0;
  width: fit-content;
  margin: auto;
  position: fixed;
  bottom: 0;
  margin-bottom: 4.2vw;
  margin-right: 2vw;
`;
const RoomDetailsPopupWidget = styled(Flex) `
  background: #1d2023;
  width: 22vw;
  padding: 1vw;
  border-radius: 0.6vw;
`;
const Button = styled(Flex) `
  color: white;
  cursor: pointer;
  padding: 0.6vw;
  border-radius: 0.4vw;
  height: ${(props) => (props.showQR ? '2.2vw' : '2.4vw')};
  width: 9.4vw;
  background: ${(props) => props.background};
  text-align: center;
  border: ${(props) => (props.showQR ? '0.1vw solid #00bceb' : 'none')};
  margin: 0.4vw 0vw 0.2vw 0vw;
`;
const RoomTitle = styled.div `
  margin: 1vw 0vw;
  font-size: 1.6vw;
`;
const QRContainer = styled.div `
  margin: 0.6vw 0vw;
`;
const QRImage = styled.img `
  width: 22vw;
`;
const Text = styled(Flex) `
  font-size: ${(props) => (props.fontSize ? props.fontSize : '1vw')};
  margin: ${(props) => props.margin};
  color: ${(props) => (props.color ? props.color : 'white')};
`;
const LetterM = styled.div `
  font-size: 0.6875vw;
  padding-top: 0.58vw;
`;
const CalendarContainer = styled.div `
  height: fit-content;
  width: 48%;
  margin-right: 0.4vw;
`;
const CollaborationDeviceContainer = styled(Flex) `
  width: fit-content;
  height: 4vh;
  margin-bottom: 1vw;
`;
const RoomOccupancyContainer = styled(Flex) `
  width: ${(props) => props.widgetCount === 0 || !props.showTimeLine ? '98%' : '94%'};
  height: fit-content;
  background: #2a2f33;
  border-radius: 0.4vw;
  padding: 0.4vw;
  margin: 0.4vw 0vw;
`;
const RoomAnalyticsContainer = styled(Flex) `
  margin: 1vw 0vw;
`;
const RoomAnalyticsRow = styled(Flex) `
  margin-bottom: 0.6vw;
  height: fit-content;
  width: 104%;
`;
const WebexLogo = styled.img `
  height: 1.6vw;
  margin-right: 0.2vw;
`;
const CheckMark = styled.img `
  height: 1vw;
  margin-left: -1vw;
`;
const SmartRoomImg = styled.img `
  min-width: 48%;
  max-width: 48%;
  margin-bottom: 0.2vw;
`;
const AvailabilityStatusContainer = styled(Flex) `
  height: fit-content;
  width: ${(props) => props.widgetCount === 0 || !props.showTimeLine ? '190%' : '80%'};
  padding: 0.6vw 1vw;
  border-radius: 0.6vw 0.6vw 0vw 0vw;
  background: ${(props) => props.background};
  border: 2px solid ${(props) => props.borderColor};
`;
const QRView = ({ QRCode }) => {
    return (_jsxs(QRContainer, { children: [_jsx(QRImage, { src: require('../../../../../assets/images/get-directions-on-your-phone.png') }), _jsx(Flex, Object.assign({ style: { margin: '-18vw 0vw 0vw 4.6vw', position: 'fixed' } }, { children: _jsx(QRCodeSVG, { value: QRCode, size: vwToPixels(12), bgColor: "#ffffff", fgColor: "#000000", level: "L" }) }))] }));
};
export const closeRoomDetailsPopup = () => {
    var _a, _b;
    setTimeout(() => {
        window.rMap.exitNavigation();
        window.rMap.reset();
    }, 200);
    store.dispatch(setRoomDetailsPopUp({ payload: null }));
    if (document.getElementById('get_directions_btn') &&
        ((_a = document
            .getElementById('get_directions_btn')) === null || _a === void 0 ? void 0 : _a.getAttribute('data-showQR')) === 'true') {
        (_b = document.getElementById('get_directions_btn')) === null || _b === void 0 ? void 0 : _b.click();
    }
};
const getPoiID = (POISNameMap, name) => {
    for (const POI in POISNameMap) {
        if (POISNameMap[POI].name === name) {
            return POISNameMap[POI].poiId;
        }
    }
};
const Calendar = ({ t, widgetCount, meetings, timeZoneId, status, showTimeLine, isPOI, }) => {
    const formattedMeetingList = getFormattedMeetingList(meetings, timeZoneId);
    return (_jsxs(CalendarContainer, { children: [!isPOI && (_jsx(AvailabilityStatus, { t: t, widgetCount: widgetCount, status: status, showTimeLine: showTimeLine })), showTimeLine && (_jsx(TimeLine, { date: moment().tz(timeZoneId).clone(), timeZoneId: timeZoneId, meetings: formattedMeetingList, status: status.toLowerCase(), timeLineLimit: 8, widgetCount: widgetCount }))] }));
};
const CollaborationDevice = ({ t, telepresence }) => {
    const checkMarkImage = telepresence
        ? require('../../../../../assets/images/checkmark.png')
        : require('../../../../../assets/images/closemark.png');
    return (_jsxs(CollaborationDeviceContainer, { children: [_jsx(WebexLogo, { src: require('../../../../../assets/images/webex-collaboration.png') }), _jsx(CheckMark, { src: checkMarkImage }), _jsx(Text, Object.assign({ fontSize: "0.64vw", style: { paddingTop: '0.4vw' } }, { children: telepresence
                    ? t('floor.room-details-popup.collab-device')
                    : t('floor.room-details-popup.no-collab-device') }))] }));
};
const RoomOccupancy = ({ t, widgetCount, showTimeLine, showPeopleCount, peopleCount, capacity, }) => {
    if (capacity === -1)
        capacity = 0;
    if (!showPeopleCount || (!peopleCount && !capacity)) {
        return _jsx(_Fragment, {});
    }
    let roomOccupancyLabel = t('floor.room-details-popup.room-occupancy');
    if (!peopleCount) {
        roomOccupancyLabel = t('floor.room-details-popup.room-capacity');
    }
    return (_jsxs(RoomOccupancyContainer, Object.assign({ direction: widgetCount === 0 || !showTimeLine ? 'horizontal' : 'vertical', widgetCount: widgetCount, showTimeLine: showTimeLine, justify: "space-between" }, { children: [_jsx(Text, Object.assign({ fontSize: "0.8vw", style: { paddingTop: (widgetCount === 0 || !showTimeLine) && '1vw' } }, { children: roomOccupancyLabel })), _jsxs(Flex, Object.assign({ justify: "space-between", style: { width: widgetCount === 0 || !showTimeLine ? '30%' : '64%' } }, { children: [_jsx(Text, Object.assign({ fontSize: "2.2vw" }, { children: peopleCount })), capacity && (_jsx(Text, Object.assign({ margin: "1.2vw 0vw 0vw 0vw", style: { color: '#9E9EA2' } }, { children: t('floor.room-details-popup.of') }))), capacity && (_jsx(Text, Object.assign({ fontSize: "2.2vw", style: { color: '#00BCEB' } }, { children: capacity })))] }))] })));
};
const AvailabilityStatus = ({ t, widgetCount, status, showTimeLine }) => {
    return (_jsxs(AvailabilityStatusContainer, Object.assign({ direction: "vertical", background: availabilityStatusColorMap[status], borderColor: availabilityStatusBorderMap[status], widgetCount: widgetCount, showTimeLine: showTimeLine }, { children: [_jsxs(Text, Object.assign({ fontSize: "0.8vw" }, { children: [t('floor.room-details-popup.this-meeting-room-is'), ' '] })), _jsx(Text, Object.assign({ fontSize: "1.8vw" }, { children: status }))] })));
};
const RoomAnalytics = ({ iaq, co2, temperature, humidity, ambientNoise, showTimeLine, }) => {
    const widgets = [];
    if (hasValue(iaq)) {
        widgets.push(_jsx(TelemetryWidget, { size: "xs", type: 'IAQ', status: iaq }));
    }
    if (hasValue(co2)) {
        widgets.push(_jsx(TelemetryWidget, { size: "xs", type: 'CO2', status: co2 }));
    }
    if (hasValue(temperature)) {
        widgets.push(_jsx(TelemetryWidget, { size: "xs", type: 'TEMPERATURE', status: temperature }));
    }
    if (hasValue(humidity)) {
        widgets.push(_jsx(TelemetryWidget, { size: "xs", type: 'HUMIDITY', status: humidity }));
    }
    if (hasValue(ambientNoise)) {
        widgets.push(_jsx(TelemetryWidget, { size: "xs", type: 'AMBIENT_NOISE', status: 'noise_' + ambientNoise }));
    }
    return showTimeLine ? (_jsxs(RoomAnalyticsContainer, Object.assign({ direction: "vertical" }, { children: [_jsxs(RoomAnalyticsRow, Object.assign({ justify: "space-between" }, { children: [widgets.length > 0 && widgets[0], widgets.length > 1 && widgets[1]] })), _jsxs(RoomAnalyticsRow, Object.assign({ justify: "space-between" }, { children: [widgets.length > 2 && widgets[2], widgets.length > 3 && widgets[3]] })), _jsx(RoomAnalyticsRow, { children: widgets.length > 4 && widgets[4] })] }))) : (_jsxs(RoomAnalyticsContainer, Object.assign({ direction: "vertical" }, { children: [_jsxs(RoomAnalyticsRow, Object.assign({ justify: "space-between" }, { children: [widgets.length > 0 && widgets[0], widgets.length > 1 && widgets[1], widgets.length > 2 && widgets[2], widgets.length > 3 && widgets[3]] })), _jsx(RoomAnalyticsRow, { justify: "space-between" }), _jsx(RoomAnalyticsRow, { children: widgets.length > 4 && widgets[4] })] })));
};
const getFormattedMeetingList = (meetings, timeZoneId) => {
    return meetings.map((meet) => {
        const { startTime, endTime } = meet;
        return {
            start: moment.unix(startTime).tz(timeZoneId),
            end: moment.unix(endTime).tz(timeZoneId),
        };
    });
};
const SmartRoom = () => {
    return _jsx(SmartRoomImg, { src: SmartRoomSVG });
};
const RoomDetailsPopup = ({ floorId }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    const location = useLocation();
    const { id: floorLocationId } = location.state || floorId;
    const { t } = useTranslation();
    const locationData = useSelector(getLocation);
    const contextInfo = useSelector(getContext);
    const UIState = useSelector(getUIState);
    const allRooms = UIState === null || UIState === void 0 ? void 0 : UIState.allRooms;
    const richMapId = UIState.popupRichMapID;
    const isPOI = !allRooms.find((room) => room.key === richMapId);
    const roomAnalytics = useSelector((state) => selectRoomById(state, richMapId));
    const availability = (_b = STATUSES[(_a = roomAnalytics === null || roomAnalytics === void 0 ? void 0 : roomAnalytics.availability) === null || _a === void 0 ? void 0 : _a.toUpperCase()]) !== null && _b !== void 0 ? _b : 'Unknown';
    const iaq = (_c = roomAnalytics === null || roomAnalytics === void 0 ? void 0 : roomAnalytics.airQuality) !== null && _c !== void 0 ? _c : null;
    const temperature = (_d = roomAnalytics === null || roomAnalytics === void 0 ? void 0 : roomAnalytics.ambientTemp) !== null && _d !== void 0 ? _d : null;
    const co2 = (_e = roomAnalytics === null || roomAnalytics === void 0 ? void 0 : roomAnalytics.co2) !== null && _e !== void 0 ? _e : null;
    const humidity = (_f = roomAnalytics === null || roomAnalytics === void 0 ? void 0 : roomAnalytics.relativeHumidity) !== null && _f !== void 0 ? _f : null;
    const ambientNoise = (_g = roomAnalytics === null || roomAnalytics === void 0 ? void 0 : roomAnalytics.ambientNoise) !== null && _g !== void 0 ? _g : null;
    const peopleCount = (_h = roomAnalytics === null || roomAnalytics === void 0 ? void 0 : roomAnalytics.peopleCount) !== null && _h !== void 0 ? _h : 0;
    const capacity = (_j = roomAnalytics === null || roomAnalytics === void 0 ? void 0 : roomAnalytics.capacity) !== null && _j !== void 0 ? _j : 0;
    // const holdable = roomAnalytics?.holdable ?? true;
    const holdable = true;
    const telepresence = (_k = roomAnalytics === null || roomAnalytics === void 0 ? void 0 : roomAnalytics.telepresence) !== null && _k !== void 0 ? _k : false;
    const roomId = (_l = roomAnalytics === null || roomAnalytics === void 0 ? void 0 : roomAnalytics.id) !== null && _l !== void 0 ? _l : '';
    const POISNameMap = useSelector(getPOIS).POISNameMap;
    const [QRCode, setQRCode] = useState('');
    const [QRCodeSize, setQRCodeSize] = useState(vwToPixels(12));
    const [showQR, setShowQR] = useState(false);
    const tenantId = locationData === null || locationData === void 0 ? void 0 : locationData.tenant;
    const locationId = locationData === null || locationData === void 0 ? void 0 : locationData.id;
    const smartRooms = useSelector(getSmartRooms);
    const isSmartRoom = smartRooms.includes(richMapId);
    // const meetings = [
    //   {
    //     meetingType: 'WEBEX',
    //     meetingId: '9a5a1ce9-a302-6b34-b2f1-1e6c85f55ad1',
    //     title: '',
    //     host: '',
    //     bookingStatus: 'OK',
    //     meetingInProgress: true,
    //     startTime: Math.floor(Date.now() / 1000) + 7200,
    //     endTime: Math.floor(Date.now() / 1000) + 10800 - 900,
    //     timeZone: 'Asia/Kolkata',
    //     updateType: 'SCHEDULED',
    //   },
    //   {
    //     meetingType: 'WEBEX',
    //     meetingId: '9a5a1ce9-a302-6b34-b2f1-1e6c85f55ad2',
    //     title: '',
    //     host: '',
    //     bookingStatus: 'OK',
    //     meetingInProgress: true,
    //     startTime: Math.floor(Date.now() / 1000) + 10800,
    //     endTime: Math.floor(Date.now() / 1000) + 10800 * 6,
    //     timeZone: 'Asia/Kolkata',
    //     updateType: 'SCHEDULED',
    //   },
    // ];
    const meetings = (_m = roomAnalytics === null || roomAnalytics === void 0 ? void 0 : roomAnalytics.meetings) !== null && _m !== void 0 ? _m : [];
    const timeZoneId = (_o = contextInfo === null || contextInfo === void 0 ? void 0 : contextInfo.timeZoneId) !== null && _o !== void 0 ? _o : 'Etc/UTC';
    const showTimeLine = !isPOI;
    const showPeopleCount = true;
    const richMapIdWithoutFloorNumber = richMapId === null || richMapId === void 0 ? void 0 : richMapId.split('~')[1];
    const widgetCount = getWidgetCount(iaq, temperature, co2, humidity, ambientNoise);
    useEffect(() => {
        const handleResize = () => {
            setQRCodeSize(vwToPixels(12));
        };
        window.addEventListener('resize', handleResize);
        getQRCode({
            tenantId: tenantId,
            locationId: locationId,
            poiId: getPoiID(POISNameMap, richMapIdWithoutFloorNumber),
            setter: setQRCode,
        });
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        getQRCode({
            tenantId: tenantId,
            locationId: locationId,
            poiId: getPoiID(POISNameMap, richMapIdWithoutFloorNumber),
            setter: setQRCode,
        });
    }, [richMapIdWithoutFloorNumber]);
    const getDirectionsHandler = () => {
        if (richMapId && !showQR) {
            window.rMap.reset();
            setTimeout(() => {
                window.rMap.navigate(richMapId);
            }, 200);
        }
        setShowQR(!showQR);
    };
    const holdHandler = () => {
        const subject = `hold.${locationId}.${floorLocationId}.${roomId}`;
        const message = {
            roomId: roomId,
            roomName: richMapIdWithoutFloorNumber,
            tenantId: tenantId,
            startTime: Math.ceil(Date.now() / 1000),
        };
        natsclient.publishToStream(subject, message);
    };
    const isWayFindingEnabled = contextInfo === null || contextInfo === void 0 ? void 0 : contextInfo.isWayFindingEnabled;
    return (_jsx(_Fragment, { children: richMapId && (_jsx(RoomDetailsPopupContainer, Object.assign({ justify: "end" }, { children: _jsxs(RoomDetailsPopupWidget, Object.assign({ direction: "vertical" }, { children: [_jsxs(Flex, Object.assign({ justify: "space-between" }, { children: [_jsx(RoomTitle, { children: ((_p = POISNameMap[richMapIdWithoutFloorNumber]) === null || _p === void 0 ? void 0 : _p.name)
                                    ? (_q = POISNameMap[richMapIdWithoutFloorNumber]) === null || _q === void 0 ? void 0 : _q.name
                                    : (_r = POISNameMap[richMapIdWithoutFloorNumber]) === null || _r === void 0 ? void 0 : _r.sType }), _jsx(Close, { onClose: closeRoomDetailsPopup })] })), showQR ? (_jsx(QRView, { QRCode: QRCode })) : (_jsxs(_Fragment, { children: [_jsxs(Flex, { children: [!isPOI && (_jsx(CollaborationDevice, { t: t, telepresence: telepresence })), isSmartRoom && _jsx(SmartRoom, {})] }), _jsxs(Flex, Object.assign({ direction: widgetCount === 0 || !showTimeLine
                                    ? 'vertical'
                                    : 'horizontal' }, { children: [_jsx(Calendar, { t: t, showTimeLine: showTimeLine, widgetCount: widgetCount, meetings: meetings, timeZoneId: timeZoneId, status: availability, isPOI: isPOI }), widgetCount !== 0 && showTimeLine && (_jsxs(Flex, Object.assign({ direction: "vertical", style: { width: '48%', marginLeft: '0.4vw' } }, { children: [_jsx(RoomOccupancy, { t: t, widgetCount: widgetCount, showTimeLine: showTimeLine, showPeopleCount: showPeopleCount, peopleCount: peopleCount, capacity: capacity }), _jsx(RoomAnalytics, { iaq: iaq, humidity: humidity, temperature: temperature, ambientNoise: ambientNoise, co2: co2, showTimeLine: showTimeLine })] }))), (widgetCount === 0 || !showTimeLine) && (_jsx(RoomOccupancy, { t: t, widgetCount: widgetCount, showTimeLine: showTimeLine, showPeopleCount: showPeopleCount, peopleCount: peopleCount, capacity: capacity })), widgetCount !== 0 && !showTimeLine && (_jsx(RoomAnalytics, { iaq: iaq, humidity: humidity, temperature: temperature, ambientNoise: ambientNoise, co2: co2, showTimeLine: showTimeLine }))] }))] })), _jsxs(Flex, Object.assign({ justify: "space-between", style: { width: '22.4vw' } }, { children: [isWayFindingEnabled ? (_jsx(Button, Object.assign({ onClick: getDirectionsHandler, justify: "center", direction: "vertical", showQR: showQR, id: "get_directions_btn", "data-showQR": showQR, background: showQR
                                    ? 'black'
                                    : 'linear-gradient(180deg, #1d7ff3 0%, #113d7d 100%)' }, { children: _jsxs(Flex, Object.assign({ justify: "center" }, { children: [_jsx(Text, { children: showQR
                                                ? t('floor.room-details-popup.go-back')
                                                : t('floor.room-details-popup.get-directions') }), _jsx(DirectionsIcon, {})] })) }))) : (_jsxs(Flex, Object.assign({ direction: "vertical", style: { marginTop: '0.6vw' } }, { children: [_jsx(Text, Object.assign({ fontSize: "0.9vw" }, { children: t('floor.room-details-popup.label-1') })), _jsx(Text, Object.assign({ color: "#9E9EA2", fontSize: "0.6vw" }, { children: t('floor.room-details-popup.label-2') })), _jsx(Text, Object.assign({ color: "#9E9EA2", fontSize: "0.6vw" }, { children: t('floor.room-details-popup.label-3') }))] }))), !isPOI && (_jsx(Button, Object.assign({ justify: "center", direction: "vertical", background: availability === STATUSES.AVAILABLE && holdable
                                    ? 'linear-gradient(180deg, #1d7ff3 0%, #113d7d 100%)'
                                    : 'rgba(49, 54, 62, 0.5)', onClick: availability === STATUSES.AVAILABLE && holdHandler }, { children: _jsxs(Flex, Object.assign({ justify: "center" }, { children: [_jsx(Text, Object.assign({ margin: "0vw 0.2vw" }, { children: t('floor.room-details-popup.hold-room') })), _jsx(StopWatch, {}), _jsx(LetterM, { children: t('floor.room-details-popup.m') })] })) })))] }))] })) }))) }));
};
export default RoomDetailsPopup;
