var _a;
export class Constants {
}
_a = Constants;
Constants.SLOT_WIDTH = 6.4; // In vw units
Constants.AVAILABLE = 'Available';
Constants.OCCUPIED = 'Occupied';
Constants.ON_HOLD = 'On Hold';
Constants.BOOKED = 'Booked';
Constants.UNKNOWN = 'Unknown';
Constants.availabilityMapLocalizedNames = {
    Hold: 'navigator.on-hold',
    Occupied: 'navigator.occupied',
    Available: 'navigator.available',
    Occupied_Booked: 'navigator.occupied',
    Booked: 'navigator.booked',
    Unknown: 'navigator.unknown',
    'No-Data': 'navigator.unknown',
};
Constants.availabilityMap = {
    Hold: _a.ON_HOLD,
    Occupied: _a.OCCUPIED,
    Available: _a.AVAILABLE,
    Occupied_Booked: _a.OCCUPIED,
    Booked: _a.BOOKED,
    Unknown: _a.UNKNOWN,
    'No-Data': _a.UNKNOWN,
};
Constants.occupancyColorMap = {
    Available: '#3e6d2d',
    Occupied_Booked: '#b81434',
    Occupied: '#b81434',
    Booked: '#c86424',
    Hold: '#20649c',
};
Constants.availabilityColorMap = {
    Available: '#5b9d45',
    Occupied: '#FF1A4C',
    Occupied_Booked: '#FF1A4C',
    Booked: '#8B390E',
    Hold: '#2381CD',
    Unknown: '#31363E',
};
Constants.availabilityColorMapBorder = {
    Available: '#71ff32',
    Occupied: '#FF8CA5',
    Occupied_Booked: '#FF8CA5',
    Booked: '#ff8f54',
    Hold: '#00bceb',
    Unknown: '#ffffff',
};
