import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Flex } from '@magnetic/flex';
import { Header } from '../common/header';
import Footer from '../common/footer';
import { WidgetContainer } from '../common/styled-widget';
import { Banner } from '../common/banner';
import Occupancy from '../floor/components/occupancy';
import FloorSelector from '../floor/components/floor-selector';
import { BuildingViewAnalytics } from './analytics-widget';
import SingleFloor from './single-floor';
import { getContext, getLocation } from '../redux/spaces-slice';
import { CustomeMask } from '../common/styled-widget';
const BuildingContainer = styled(Flex) `
  width: 95vw;
  height: 95vh;
  padding: 1.5vw;
  overflow: hidden;
`;
const GraphWidget = ({ isSingleFloor, occupancy }) => (_jsxs(WidgetContainer, Object.assign({ width: isSingleFloor && occupancy ? 1780 : 1225, height: occupancy ? 495 : 267, color: '#1D2023', align: "flex-end", justify: "space-between", style: { padding: '20px' } }, { children: [occupancy && (_jsx(Flex, Object.assign({ basis: isSingleFloor ? 1130 : 780 }, { children: _jsx(Occupancy, {}) }))), _jsx(BuildingViewAnalytics, { occupancy: occupancy })] })));
const getFloorSelector = (maskRequired) => {
    return (_jsxs(WidgetContainer, Object.assign({ direction: "vertical", height: '790', overflowy: true, color: '#1D2023', align: "flex-start", justify: "space-between", padding: "0px", width: 530 }, { children: [_jsx(FloorSelector, {}), maskRequired && _jsx(CustomeMask, { className: "mask" })] })));
};
const BannerWidget = ({ bannerText, occupancy }) => (_jsx(WidgetContainer, Object.assign({ width: 1260, height: occupancy ? 223 : 446, align: "center", justify: "center" }, { children: _jsx(Banner, { title: bannerText }) })));
const SingleFloorWidget = ({ fullHeight }) => (_jsx(WidgetContainer, Object.assign({ width: 530, height: fullHeight ? 780 : 223, align: "center" }, { children: _jsx(SingleFloor, { fullHeight: fullHeight }) })));
const FloorContent = ({ isSingleFloor, bannerText, occupancy, maskRequired, }) => (_jsxs(Flex, Object.assign({ gap: 24 }, { children: [_jsxs(Flex, Object.assign({ gap: 24, grow: true, basis: 1260, direction: "verticle" }, { children: [_jsx(GraphWidget, { isSingleFloor: isSingleFloor, occupancy: occupancy }), _jsxs(Flex, Object.assign({ justify: "space-between" }, { children: [_jsx(BannerWidget, { bannerText: bannerText, occupancy: occupancy }), isSingleFloor && occupancy && (_jsx(SingleFloorWidget, { fullHeight: !occupancy }))] }))] })), !isSingleFloor && getFloorSelector(maskRequired), isSingleFloor && !occupancy && _jsx(SingleFloorWidget, { fullHeight: true })] })));
export function BuildingView() {
    const locationData = useSelector(getLocation);
    const context = useSelector(getContext);
    const buildingConfig = context.appConfig.building;
    const buildingOccupancy = buildingConfig.showBuildingOccupancy;
    const text = 'Enabling a safe, smart, and collaborative experience';
    const [isSingleFloor, setIsSingleFloor] = useState(false);
    const [name, setName] = useState('');
    const [maskRequired, setMaskRequired] = useState(false);
    useEffect(() => {
        var _a, _b;
        if (locationData === null || locationData === void 0 ? void 0 : locationData.name) {
            setName(locationData === null || locationData === void 0 ? void 0 : locationData.name);
            setMaskRequired(((_a = locationData === null || locationData === void 0 ? void 0 : locationData.children) === null || _a === void 0 ? void 0 : _a.length) > 3);
            setIsSingleFloor(((_b = locationData === null || locationData === void 0 ? void 0 : locationData.children) === null || _b === void 0 ? void 0 : _b.length) === 1);
        }
    }, [locationData]);
    return (_jsxs(BuildingContainer, Object.assign({ gap: 24, direction: "verticle", justify: "space-between" }, { children: [_jsx(Header, { title: name }), _jsx(FloorContent, { isSingleFloor: isSingleFloor, bannerText: text, occupancy: buildingOccupancy, maskRequired: maskRequired }), _jsx(Footer, {})] })));
}
export default BuildingView;
