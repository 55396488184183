import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { STATUSES } from '../../../floor/util';
const CancelButtonContainer = styled.div `
  padding-top: 1.6vw;
  margin-top: ${(props) => (props.widgetCount === 0 ? '5.2vw' : '0vw')};
`;
const Button = styled.div `
  background: linear-gradient(180deg, #ffffff 0%, #c9d6f0 100%);
  color: black;
  font-size: 1.6vw;
  font-family: 'CiscoSansRegular';
  text-align: center;
  width: 11.4vw;
  height: 1.4vw;
  padding: 1vw 0vw;
  border-radius: 0.4vw;
  cursor: pointer;
`;
const cancelButtonHandler = (setShow, setBookingSlotWidth, setBookingSlotEndTime) => {
    console.log('Cancel button clicked !');
    setShow(false);
    setBookingSlotWidth(0);
    setBookingSlotEndTime(Date.now() / 1000);
};
const CancelButton = memo(({ show, setShow, setBookingSlotWidth, setBookingSlotEndTime, widgetCount, availabilityStatus, }) => {
    console.log('Cancel button rendered...');
    const { t } = useTranslation();
    useEffect(() => {
        if (availabilityStatus !== STATUSES.HOLD) {
            cancelButtonHandler(setShow, setBookingSlotWidth, setBookingSlotEndTime);
        }
    }, [availabilityStatus]);
    return (_jsx(CancelButtonContainer, Object.assign({ widgetCount: widgetCount }, { children: show && (_jsx(Button, Object.assign({ onClick: () => cancelButtonHandler(setShow, setBookingSlotWidth, setBookingSlotEndTime) }, { children: t('navigator.cancel') }))) })));
});
export default CancelButton;
