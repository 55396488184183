import { createSlice } from '@reduxjs/toolkit';
// Initial state
const initialState = {
    popupRichMapID: null,
    allRooms: [],
    allDesks: [],
    POIs: {},
    POISNameMap: {},
    showOnMap: 'All',
    isRoomFinderOpen: false,
    smartRooms: [],
    searchFilters: {
        all: false,
        meetingRooms: false,
        hotDesks: false,
        accessibleSpaces: false,
        smartRoom: false,
    },
};
// Slice to manage UI State
export const UISlice = createSlice({
    name: 'UI',
    initialState,
    reducers: {
        // Will set room details popup to show the given richMapID, (if null, popup will close)
        setRoomDetailsPopUp: (state, action) => {
            const { payload } = action.payload;
            state.popupRichMapID = payload;
        },
        setRoomDataFromMap: (state, action) => {
            const { payload } = action.payload;
            state.allRooms = payload;
        },
        setDeskDataFromMap: (state, action) => {
            const { payload } = action.payload;
            state.allDesks = payload;
        },
        setPOIDataFromMap: (state, action) => {
            const { payload } = action.payload;
            state.POIs = payload.POIs;
            state.POISNameMap = payload.POISNameMap;
        },
        setShowOnMap: (state, action) => {
            const { payload } = action.payload;
            state.showOnMap = payload;
        },
        setRoomFinderOpen: (state, action) => {
            const { payload } = action.payload;
            state.isRoomFinderOpen = payload;
        },
        setSmartRooms: (state, action) => {
            const { payload } = action.payload;
            state.smartRooms = payload;
        },
        setSearchFilters: (state, action) => {
            const { payload } = action.payload;
            console.log('payload :: ', payload);
            state.searchFilters = payload;
        },
    },
    selectors: {
        getUIState: (state) => state,
        getAllRooms: (state) => state.allRooms,
        getAllDesks: (state) => state.allDesks,
        getPOIS: (state) => {
            return { POIS: state.POIs, POISNameMap: state.POISNameMap };
        },
        getShowOnMap: (state) => {
            return state.showOnMap;
        },
        getRoomFinderStatus: (state) => state.isRoomFinderOpen,
        getSmartRooms: (state) => state.smartRooms,
        getSearchFilters: (state) => state.searchFilters,
    },
});
// Export actions
export const { setRoomDetailsPopUp, setRoomDataFromMap, setDeskDataFromMap, setPOIDataFromMap, setShowOnMap, setRoomFinderOpen, setSmartRooms, setSearchFilters, } = UISlice.actions;
// Export selectors
export const { getUIState, getAllRooms, getAllDesks, getPOIS, getShowOnMap, getRoomFinderStatus, getSmartRooms, getSearchFilters, } = UISlice.selectors;
