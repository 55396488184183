import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import styled from 'styled-components';
import { isStatusAvailable } from '../../util';
import { useTranslation } from 'react-i18next';
const InstructionsContainer = styled.div `
  width: 26.6vw;
  margin-top: ${(props) => (props.widgetCount === 0 ? '2vw' : '0vw')};
`;
const Heading = styled.div `
  font-size: 2vw;
  font-family: 'CiscoSansBold';
  padding: 0vw 1.4vw;
`;
const Subheading = styled.div `
  font-size: 1vw;
  font-family: 'CiscoSansRegular';
  color: #9e9ea1;
  padding: 0.4vw 1.4vw;
`;
const Instructions = memo(({ widgetCount, availabilityStatus }) => {
    const { t } = useTranslation();
    return (_jsxs(InstructionsContainer, Object.assign({ widgetCount: widgetCount }, { children: [_jsx(Heading, { children: t('navigator.instructions-heading') }), _jsx(Subheading, { children: isStatusAvailable(availabilityStatus)
                    ? t('navigator.instructions-subheading-when-available')
                    : t('navigator.instructions-subheading-when-not-available') })] })));
});
export default Instructions;
