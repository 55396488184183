import styled from 'styled-components';
import FloorCircle from './index';
import * as C from '../../../../../../constants';
const StyledFloorCircle = styled(FloorCircle) `

  && {
    cursor: pointer;
    .circle {
      width: 100%;
      height: 100%;

      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      box-shadow: ${(props) => props.isSelected ? '0vw 0.25vw 0.75vw rgba(0, 188, 235, 0.24)' : 0};
    }
    .circle.one {
      --color: ${(props) => ((props === null || props === void 0 ? void 0 : props.percentage) > 0 ? '#00bceb' : '#26282b')}
      --p: ${(props) => ((props === null || props === void 0 ? void 0 : props.percentage) > 0 ? props.percentage || 0 : 0)};
      --v: calc(((18 / 5) * var(--p) - 90) * 1deg);
      width: 7.125vw;
      height: 7.125vw;
      background: linear-gradient(#26282b, #26282b) content-box,
        linear-gradient(var(--v), #26282b 50%, transparent 0) 0 /
          min(100%, (50 - var(--p)) * 100%),
        linear-gradient(var(--v), transparent 50%, var(--color) 0) 0 /
          min(100%, (var(--p) - 50) * 100%),
        linear-gradient(to right, #26282b 50%, var(--color) 0);
      padding: 0.3125vw;
      border: 1px solid #383b3d;
    }
    .circle.two {
      background: ${(props) => (props.isSelected ? '#000000' : '#2d3134')};
      border: 1px solid #383b3d;
      text-align: center;
    }
    .floor-level {
      line-height: 2.4375vw;
      font-size: 2.5vw;
      font-weight: 400;
      padding-top: calc((7.75vw / 2) - (1.875vw));
    }
    .floor-status {
      font-size: 0.875vw;
      line-height: 1.25vw;
      font-weight: 700;
      color: ${(props) => { var _a; return C.FLOOR_STATUS_COLOR[(_a = props === null || props === void 0 ? void 0 : props.floorStatus) === null || _a === void 0 ? void 0 : _a.toUpperCase()]; }};
    }
    .you-are-here {
      margin-top: ${(props) => (props.isDetailed ? '0.875vw' : '0vw')};
      position: relative;
      right: 0.35vw;
      display: flex;
      font-family: CiscoSansTT Regular;
      justify-content: center;
      align-items: center;
      min-height: 2vw;
      min-width: 7.125vw;
      border: 0.125vw solid #FFFFFF
      background: #000000;
      padding-left: 0.5625vw;
      padding-right: 0.5625vw;
      border-radius: 1vw;
      color: #ffffff;
      font-weight: 400;
      font-size: 0.9375vw;
      line-height: 1.375vw;
    }
  }
`;
StyledFloorCircle.defaultProps = FloorCircle.defaultProps;
export default StyledFloorCircle;
