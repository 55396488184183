import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import './app.scss';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Page404 from './common/error/page404';
import WelcomeLayout from './common/layouts/welcome-layout';
import { mainRoutes } from './routes/routes';
import './i18n';
const router = createBrowserRouter([
    {
        path: '/',
        element: _jsx(WelcomeLayout, {}),
        errorElement: _jsx(Page404, {}),
        children: [...mainRoutes],
    },
]);
function App() {
    useEffect(() => {
        console.log('App mounted');
    }, []);
    return _jsx(RouterProvider, { router: router });
}
export default App;
